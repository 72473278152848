import React, { Component } from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import DeviceProblemsList from "../components/DeviceProblemsList";
import HouseUserBox from "../components/HouseUserBox";
import TestStepsHeader from "../components/TestStepsHeader";
import Images from "../config/images";
import TestingActions from "../redux/actions/testing";
import { showErrorMessage } from "../utils/helpers";

class DiscardDevice extends Component {
  state = {
    deviceId: "",
    device: {},
    houseDetails: {
      house: {},
      room: {},
      isInAnyHouse: {},
      user: {},
      message: "",
    },
    message: "",
  };

  fetching = false;

  render() {
    const { deviceId, device, houseDetails, message } = this.state;
    return (
      <Row className={"g-0"}>
        <Col lg={3} className="section_container">
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Enter device Id</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  value={deviceId}
                  onChange={this.handleDeviceIdChanged}
                  placeholder={"Device Id"}
                />
              </Col>
            </Row>
            <br />
          </Form.Group>
          <Button onClick={this.handleGetDetails}>Get details</Button>
        </Col>
        <Col lg={5}>
          <div
            className="section_container"
            style={{
              borderLeft: "0.5px solid black",
              borderRight: "0.5px solid black",
            }}
          >
            <TestStepsHeader
              icon={Images.MenuImages.DiscardDevice}
              title={"Discard Device"}
            />
            <br />
            {houseDetails?.house?._id === undefined && <div></div>}
            {device && device.discarded === true && (
              <center>
                <h4>
                  <Badge bg={"danger"}>
                    Device has been marked as discarded
                  </Badge>
                </h4>
              </center>
            )}
            {houseDetails?.house?._id !== undefined && (
              <div>
                <center>
                  <h4>
                    <Badge bg={"danger"}>Device is added in a house</Badge>
                  </h4>
                </center>
                <br />
                <HouseUserBox
                  house={houseDetails.house}
                  user={houseDetails.user}
                  room={houseDetails.room}
                  deviceId={this.state.deviceId}
                />
              </div>
            )}
            {houseDetails.house?._id === undefined &&
              device?.discarded === false && (
                <div>
                  <Form.Group>
                    <Form.Label>Add a message for logs:</Form.Label>
                    <Form.Control
                      as={"textarea"}
                      rows={5}
                      value={message}
                      placeholder={"Tester message"}
                      onChange={this.handleMessageChanged}
                    />
                  </Form.Group>
                  <br />
                  <Button variant={"danger"} onClick={this.handleDiscardDevice}>
                    Discard Device
                  </Button>
                </div>
              )}
          </div>
        </Col>
        <Col lg={4}>
          <div className="section_container">
            <DeviceProblemsList device={device} />
          </div>
        </Col>
      </Row>
    );
  }

  handleDiscardDevice = async () => {
    if (this.fetching) return;
    this.fetching = true;
    const { deviceId, message, reason } = this.state;
    const response = await TestingActions.discarded({
      discarding_message: message,
      deviceId,
      discarding_reason: reason,
      testing_station: "",
    });
    if (response.success) {
      this.setState({
        deviceId: "",
        device: {},
        houseDetails: {
          house: {},
          room: {},
          isInAnyHouse: {},
          user: {},
          message: "",
        },
        message: "",
      });
    }
    this.fetching = false;
  };

  handleMessageChanged = (e) => {
    this.setState({ message: e.target.value });
  };

  handleGetDetails = async () => {
    if (this.fetching) return;
    this.fetching = true;
    this.setState({
      device: {},
      houseDetails: { house: {}, user: {}, room: {}, isInAnyHouse: {} },
    });
    const [detailsResponse, houseDetailsResponse] = await Promise.all([
      TestingActions.details(this.state.deviceId),
      TestingActions.deviceHouseDetails(this.state.deviceId),
    ]);
    if (!detailsResponse.success) {
      this.fetching = false;
      return showErrorMessage("Testing Details", detailsResponse.message);
    }
    if (!houseDetailsResponse.success) {
      this.fetching = false;
      return showErrorMessage("House Details", houseDetailsResponse.message);
    }
    this.fetching = false;
    this.setState({
      device: detailsResponse.data.device,
      houseDetails: houseDetailsResponse.data,
    });
  };

  handleDeviceIdChanged = (e) => {
    this.setState({ deviceId: e.target.value.toUpperCase() });
  };
}

export default DiscardDevice;
