import axios from "axios"
import { socketurl } from "../../config/constants"
import { OnlineCountActions } from "../actionTypes";


export const getOnlineDetails = () => dispatch => {
	axios.get(`${socketurl}/v2/admin/misc/count`)
	.then(response => {
		const {success, data, message} = response.data;
		if (success) {
			dispatch({
				type: OnlineCountActions.UPDATE,
				payload: data,
			})
		}
		return {success, message}
	}, error => {
		const message = error?.response?.data?.message || String(error);
		return {success: false, message};  
	})
	.catch(error => {
		return {success: false, message: String(error)}
	})
}