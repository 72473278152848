import React, { Component } from 'react';
import { Badge, Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import DeviceProblemsList from '../components/DeviceProblemsList';
import TestStepsHeader from '../components/TestStepsHeader';
import { DeviceTypes, TestingType } from '../config/constants';
import Images from '../config/images';
import { getDeviceType, showErrorMessage, showInfoMessage } from '../utils/helpers';
import TestingActions from '../redux/actions/testing';
class HardwareTest extends Component {
	state = {
		deviceId: '',
		message: '',
		device: {},
	}
	processing = false;

	render() {
		const { deviceId, message, device } = this.state;
		return (
			<Row className={'g-0'}>
				<Col lg={4}>
					<div className={'rounded elevated'}>
						<TestStepsHeader
							icon={Images.HardwareTesting.Test}
							title={"Hardware Test"}
						/>
						<Form.Group>
							<Form.Label>
								Enter Device ID
							</Form.Label>
							<Form.Control
								value={deviceId}
								onChange={this.handleDeviceIDChanged}
								placeholder={'Device ID'}
							/>
						</Form.Group>
						<br />
						<Button onClick={this.handleGetDeviceDetails}>
							Get Details
						</Button>
						<br />
						<br />
						{device && device.discarded === true && (
							<center>
								<h4>
									<Badge bg={"danger"}>Has been marked as discarded</Badge>
								</h4>
							</center>
						)}
						{device && device.hardware_tested === true && (
							<center>
								<h4>
									<Badge bg={"success"}>Has already passed hardware test</Badge>
								</h4>
							</center>
						)}
						<Form.Group>
							<Form.Label>Add a message for logs:</Form.Label>
							<Form.Control
								as={"textarea"}
								rows={5}
								value={message}
								placeholder={"Tester message"}
								onChange={this.handleMessageChanged}
							/>
						</Form.Group>

						<div style={{ marginTop: 10 }}>
							<Row>
								<Col lg={3} />
								<Col>
									<Row style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
										<Col />
										<Col>
											<Button
												variant={"danger"}
												onClick={this.onFailed}
												disabled={String(device.deviceId) !== String(deviceId)}
												active={String(device.deviceId) === String(deviceId)}
											>
												Failed
											</Button>
										</Col>
										<Col>
											<Button
												variant={"primary"}
												onClick={this.onPassed}
												disabled={String(device.deviceId) !== String(deviceId)}
												active={String(device.deviceId) === String(deviceId)}
											>
												Passed
											</Button>
										</Col>
									</Row>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
				{device.deviceId && (
					<Col lg={4}>
						<div className={"rounded elevated"}>
							<DeviceProblemsList
								device={device}
								onResolve={this.onResolveProblem}
								type={TestingType.Basic}
							/>
						</div>
					</Col>
				)}

			</Row>
		)
	}

	handleGetDeviceDetails = async () => {
		const { deviceId } = this.state;
		let deviceType = getDeviceType(deviceId);

		const allowedTypes = [DeviceTypes.Curtain, DeviceTypes.EnergISync, DeviceTypes.Nova, DeviceTypes.EnvironSync, DeviceTypes.ThermoSmart, DeviceTypes.WeatherSensor];
		if (!allowedTypes.includes(deviceType) && !deviceId.startsWith('TW3')) {
			return showErrorMessage('Can not test device', `${getDeviceType(deviceId)} type device can not be tested here`);
		}
		const testingHistory = await TestingActions.details(deviceId);
		const { data, message } = testingHistory;
		const { device } = data;
		if (!testingHistory.success) {
			// if testing history fetch fails
			return showErrorMessage('Can not add', `Could not get testing history\n${testingHistory.message}`);
		}
		if (!device || device.basic_tested !== true) {
			// device does not exist or the device basic testing is not done
			return showErrorMessage('Can not add', 'Device should pass basic testing first');
		}
		if (device.testing_history.some(log => log.resolved !== true)) {
			return showErrorMessage('Can not add', 'All problems should be resolved first');
		}
		const houseDetails = await TestingActions.deviceHouseDetails(deviceId);
		if (!houseDetails.success) {
			// house details fetching failed
			return showErrorMessage('Can not add', `Could not get house details\n${houseDetails.message}`);
		}
		if (houseDetails.data.house !== null) {
			// device is added in house, can not be tested
			return showErrorMessage('Can not add', `Device is added in house`);
		}
		showInfoMessage("Got device details", message);
		this.setState({ device });
	}

	onResolveProblem = async (log, index) => {
		if (this.processing) return;
		this.processing = true;
		if (log.passed || log.resolved) {
			// already a resolved issue; return;
		}
		const { device } = this.state;
		const { success, message, data } = await this.props.resolve({
			deviceId: device.deviceId,
			index,
			message: "",
		});
		if (!success) {
			showErrorMessage("Could not reolve problem", message)
		} else {
			this.setState({ device: data.device });
		}
		this.processing = false;
	};

	onPassed = async () => {
		if (this.processing) return;
		this.processing = true;
		const { device } = this.state;
		if (device.discarded) {
			this.processing = false;
			return showErrorMessage("Can not process device", "A device marked as discarded can not be processed further")
		}
		if (!device.basic_tested) {
			this.processing = false;
			return showErrorMessage("Can not pass device", "Device has not passed basic testing");
		}
		const payload = {
			deviceId: device.deviceId,
			testing_station: '',
			testing_type: '',
			type: TestingType.Hardware,
			message: this.state.message.trim(),
		};

		const result = await this.props.passed(payload);
		if (!result.success) {
			showErrorMessage("Could not pass device", result.message);
		} else {
			this.setState({ deviceId: '', device: {}, message: '' });
		}
		this.processing = false;
	};

	onFailed = async () => {
		if (this.processing) return;
		this.processing = true;
		const { device } = this.state;
		if (device.discarded) {
			this.processing = false;
			return showErrorMessage("Can not process device", "A device marked as discarded can not be processed further")
		}
		let reason = "Custom Reason";
		const payload = {
			deviceId: device.deviceId,
			testing_station: '',
			switch_pin_tester: '',
			testing_type: '',
			type: TestingType.Hardware,
			reason,
			message: this.state.message,
		};
		await this.props.failed(payload);
		this.setState({ deviceId: '', device: {}, message: '' })
		this.processing = false;
	};

	handleMessageChanged = (e) => {
		this.setState({ message: e.target.value });
	}

	handleDeviceIDChanged = (e) => {
		this.setState({ deviceId: e.target.value.toUpperCase().replace(/[^0-9a-z]/gi, '') });
	}

}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
	resolve: TestingActions.resolveFailure,
	passed: TestingActions.hardwareTestPassed,
	failed: TestingActions.failed
})(HardwareTest);