import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";
import {
  setTestingSync,
  setSyncTestingStation,
} from "../../redux/actions/currentSyncTest";
import TestingActions from "../../redux/actions/testing";
import { showErrorMessage } from "../../utils/helpers";
import { updateStationUsed } from "../../redux/actions/testingStations";

const RegisterDevice = (props) => {
  const [deviceId, setDeviceId] = useState("");
  const lastUsedStation = useSelector(state => state?.currentSyncTest?.testingStation?._id);
  const testingStations = useSelector((state) => state.testingStations.list);
  const lastUsedIndex = testingStations.findIndex(ts => String(ts._id) === String(lastUsedStation));
  const [testingStationIndex, setTestingStationIndex] = useState(lastUsedIndex === -1 ? "0" : String(lastUsedIndex));
  return (
    <div>
      <TestStepsHeader
        icon={Images.HardwareTesting.Register}
        title={"Register Devices"}
      />
      <Form.Group>
        <Form.Label>Enter Device ID</Form.Label>
        <Form.Control
          placeholder="Device ID"
          aria-label="Device ID"
          value={deviceId}
          onChange={(e) => {
            setDeviceId(
              e.target.value.toUpperCase().replace(/[^0-9a-z]/gi, "")
            );
          }}
        />
      </Form.Group>
      <br />
      <Form.Group>
        <Form.Label>Select Testing Station</Form.Label>
        <Form.Select
          value={testingStationIndex}
          onChange={(e) => {
            setTestingStationIndex(e.target.value);
          }}
        >
          {testingStations.filter(ts => ts.active).map((ts, index) => (
            <option key={ts._id} value={index}>
              {ts.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <br />
      <Button
        title="Register"
        onClick={async () => {
          const { success, data, message } = await TestingActions.details(deviceId);
          if (success) {
            props.setTestingSync(data.device);
            props.setSyncTestingStation(
              testingStations[Number(testingStationIndex)]
            );
            props.updateStationUsed(testingStations[Number(testingStationIndex)]._id)
            setTimeout(() => {
              // sometimes the function was called before the props update is complete
              // delay of 200ms to wait for prop update
              props.onStepComplete();
            }, 200);
          } else {
            props.setTestingSync(undefined);
            showErrorMessage("Registration Failed", message);
          }
        }}
      >
        Register
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  setSyncTestingStation,
  setTestingSync,
  updateStationUsed,
})(RegisterDevice);
