import { UserAllList } from "../actionTypes";

export const userListSave =(state={},action)=>{
    switch (action.type) {
        case UserAllList.USER_ALL_LIST:
            return action.payload  
        
    
        default:
            return state
    }
}