import { HistoryActions } from "../actionTypes";

const histories = (state = {}, action) => {
	const {type, payload} = action;
	switch (type) {
		case HistoryActions.LOAD:
			return {
				...state,
				[payload.deviceId]: payload.history,
			}
		default:
			return state;
	}
}

export default histories;
