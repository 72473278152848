import { AffilatedProductAction } from "../actionTypes"

export const affliatedProductReducer = (state=[],action)=>{
   const {type,payload} = action
   switch(type){
     case AffilatedProductAction.LOAD:
        return payload
     default :
        return state
   }
}

export const selectedAffliatedProductReducer = (state=[],action)=>{
    const {type,payload} = action
    switch(type){
      case AffilatedProductAction.LOAD_SELECTED_PRODUCT:
         return payload
      case AffilatedProductAction.REMOVE_SELECTED_PRODUCT:
         return payload
      default :
         return state
    }
 }

 export const selectedAffliatedProductHistoryReducer = (state=[],action)=>{
   const {type,payload} = action
   switch(type){
     case AffilatedProductAction.HISTORY_SELECTED_PRODUCT:
        return payload
     default :
        return state
   }
}