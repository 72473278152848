import React from "react";
import { Image } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RoutesConfiguration } from "../config/constants";
import { userAllowedMenu } from "../utils/helpers";
import ReactTooltip from 'react-tooltip';


const SideBar = (props) => {
  return (
    <div style={{ borderRight: "0.5px solid black", maxHeight: "100vh", overflowY: 'scroll' }} className={'invisibleScrollbar'}>
      <center>
        {RoutesConfiguration.filter((item) => item.hide !== true && userAllowedMenu(item.availableFor, props.user.profile.roles)).map((item) => {
          return (
            <React.Fragment key={item.linkTo}>
              <Link to={item.linkTo} data-tip={item.toolTip}>
                <Image
                  src={item.image}
                  style={{ width: "40px", margin: "15px 5px" }}
                />
              </Link>
              <br />
            </React.Fragment>
          )
        })}
      </center>
      <ReactTooltip offset={{ right: 20 }} place={'right'} effect={'solid'} />
    </div>
  );
};
const mapStateToProps = state => ({ user: state.user });
export default connect(mapStateToProps)(SideBar);
