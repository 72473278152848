import { UserList } from "../actionTypes";

const initialState = { roles: [], installationUsers: [] };
const userListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UserList.USER_ROLE_LIST:
      return { ...state, roles: payload };

    case UserList.INSTALLATION_USERS_LOAD:
      return { ...state, installationUsers: payload };
    default:
      return state;
  }
};

export default userListReducer;
