import { Badge, Table } from "react-bootstrap";
import { EnergISyncParameters, EnergISyncTestingConfig } from "../config/constants";

const checkLogPassed = (log) => {
	if (!log.device_readings) {
		return false;
	}
	if (!log.tester_readings) {
		return false;
	}
	let range = EnergISyncTestingConfig.Range[log.command];
	const { device_readings, tester_readings } = log;
	if (!(device_readings.p >= range.minimumWattage && device_readings.p <= range.maximumWattage)) {
		return false;
	}
	if (!(tester_readings.p >= range.minimumWattage && tester_readings.p <= range.maximumWattage)) {
		return false;
	}
	for (const param of Object.values(EnergISyncParameters)) {
		let reading = device_readings[param];
		let expected = tester_readings[param];
		let diff = parseInt(Math.abs(reading - expected))
		if (param !== EnergISyncParameters.POWER_FACTOR) {
			if (diff < 1) {
				diff = 0;
			}
		}
		let error = diff * 100 / expected;
		if (error > EnergISyncTestingConfig.Tolerance[param]) {
			return false;
		}
	}
	return true;
}

export const EnergISyncTestLogs = ({ logs, deviceId }) => (
	<>
		<br /> <br />
		<h5> Tested Device : {deviceId}</h5>
		<Table>
			<thead>
				<tr>
					<th>#</th>
					<th>Phase ID</th>
					<th>Request ID</th>
					<th>Command</th>
					<th>Device Reading (v, c, pf, p)</th>
					<th>Master Reading (v, c, pf, p)</th>
					<th>Replied</th>
					<th>Response Time</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				{logs.map((log, index) => (
					<>
						<tr key={log.commandId}>
							<td>{index + 1}</td>
							<td>{log.switchId}</td>
							<td>{log.requestId}</td>
							<td>{log.command}</td>
							<td>{log?.device_readings?.v?.toFixed(2)},{log?.device_readings?.c?.toFixed(2)},{log?.device_readings?.pf?.toFixed(2)},{log?.device_readings?.p?.toFixed(2)}</td>
							<td>{log?.tester_readings?.v?.toFixed(2)},{log?.tester_readings?.c?.toFixed(2)},{log?.tester_readings?.pf?.toFixed(2)},{log?.tester_readings?.p?.toFixed(2)}</td>
							<td>
								<h5>
									<Badge bg={log.replied ? "success" : "danger"}>
										{log.replied ? "Yes" : "No"}
									</Badge>
								</h5>
							</td>
							<td>
								{log.replied ? log.repliedTimestamp - log.commandTimestamp : 'NA'}
							</td>
							<td>
								<h5>
									<Badge bg={checkLogPassed(log) ? "success" : "danger"}>
										{checkLogPassed(log) ? "Passed" : "Failed"}
									</Badge>
								</h5>
							</td>
						</tr>
					</>
				))}

			</tbody>
		</Table>
	</>

)