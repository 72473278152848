import React, { Component } from "react";
import { Button, Col, Form, Row } from 'react-bootstrap';
import { WiFiOptions } from '../config/constants';
import { updateDeviceWiFi } from '../redux/actions/testing';
import { showErrorMessage } from '../utils/helpers';

class UpdateWifi extends Component {
	state = {
		deviceIds: '',
		ws: WiFiOptions[0].ws,
		wp: WiFiOptions[0].wp,
	}
	render() {
		const { deviceIds, ws, wp } = this.state;
		return (
			<Row className={'g-0'}>
				<Col lg={4}>
					<div className={'rounded elevated'}>
						<Form.Group>
							<Form.Label>
								Enter Device IDS
							</Form.Label>
							<Form.Control
								value={deviceIds}
								onChange={this.handleDeviceIDsChanged}
								placeholder={'Comma Separated Device IDs'}
							/>
						</Form.Group>
						<br />
						<Form.Group>
							<Form.Label>
								Select WiFi
							</Form.Label>
							<Form.Select aria-label="Select Wifi" onChange={this.handleWifiSelect}>
								{WiFiOptions.map((wifi) => 
									<option value={JSON.stringify(wifi)}>{wifi.ws}</option>
								)}
							</Form.Select>
						</Form.Group>
						<br />
						<Form.Group>
							<Form.Label>
								Enter WiFi SSID
							</Form.Label>
							<Form.Control
								value={ws}
								onChange={this.handleWSChanged}
								placeholder={'WiFi SSID'}
							/>
						</Form.Group>
						<br />
						<Form.Group>
							<Form.Label>
								Enter WiFi Password
							</Form.Label>
							<Form.Control
								value={wp}
								onChange={this.handleWPChanged}
								placeholder={'Device ID'}
							/>
						</Form.Group>
						<br />
						<Button onClick={this.handleUpdateWiFi}>
							Update
						</Button>
						<br />
						<br />
					</div>
				</Col>
			</Row>
		)
	}

	handleWifiSelect = (e) => {
		let {ws, wp} = JSON.parse(e.target.value);
		this.setState({ws, wp});
	}

	handleUpdateWiFi = async () => {
		const {deviceIds, ws, wp} = this.state;
		const { success, message } = await updateDeviceWiFi(deviceIds.split(','), ws, wp);
		if (!success) {
			showErrorMessage("Failed to update wifi", message)
			return;
		}
		this.setState({deviceId: ''});
	}

	handleDeviceIDsChanged = (e) => {
		this.setState({ deviceIds: e.target.value.toUpperCase().trim() });
	}

	handleWSChanged = (e) => {
		this.setState({ ws: e.target.value });
	}

	handleWPChanged = (e) => {
		this.setState({ wp: e.target.value });
	}

}

export default UpdateWifi;