import axios from "axios";
import { baseurl } from "../../config/constants";
import { accessTokenHeader } from "../../utils/helpers";
import { UserList } from "../actionTypes";

const loadInstallationUsers = (installationUsers = []) => ({
  type: UserList.INSTALLATION_USERS_LOAD,
  payload: installationUsers,
});

export const createUser = (
  details = {
    first_name: "",
    last_name: "",
    phoneNumbers: "",
    emails: "",
    profile_pic: "",
    gender: "",
    dob: "",
    doj: "",
    roles: "",
    employeeId: "",
    password: "",
    username: "",
    pancardNumber:"",
    aadharNumber:'',
  }
) => {
  const payload = new FormData();
  payload.append("first_name", details.first_name);
  payload.append("last_name", details.last_name);
  payload.append("phoneNumbers", details.phoneNumbers);
  payload.append("emails", details.emails);
  payload.append("gender", details.gender);
  payload.append("profile_pic", details.profile_pic);
  payload.append("dob", new Date(details.dob));
  payload.append("joiningDate", new Date(details.doj));
  payload.append("roles", details.roles);
  payload.append("employeeId", details.employeeId);
  payload.append("password", details.password);
  payload.append("username", details.username);
  payload.append("aadharNumber", details.aadharNumber);
  payload.append("pancardNumber", details.pancardNumber);
  return new Promise((resolve) => {
    axios
      .post(`${baseurl}/users/create`, payload, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, data, message } = result.data;
          resolve({ success, data, message });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({ success: false, message });
        }
      )
      .catch((error) => {
        resolve({ success: false, message: String(error) });
      });
  });
};

export const getInstallationUsers = () => (dispatch) => {
  return new Promise((resolve) => {
    axios
      .get(`${baseurl}/users/installationUsers`, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, data, message } = result.data;
         dispatch(loadInstallationUsers(data?.users || []));
          resolve({ success, data, message });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({ success: false, message });
        }
      )
      .catch((error) => {
        resolve({ success: false, message: String(error) });
      });
  });
};

export const lastEmployeeId = async () => {
  return await axios
    .get(`${baseurl}/users/lastAssignId`, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      const { data, success } = res.data;
      if (success === true) {
        return data.biggestId;
      }
    })
    .catch(function (err) {
      return "not found";
    });
};
