import React, { Component } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import History from "../../components/History";
import TestStepsHeader from "../../components/TestStepsHeader";
import { MovementsType } from "../../config/constants";
import Images from "../../config/images";
import { loadHistory } from "../../redux/actions/history";
import { getCurrentState } from "../../redux/actions/movement";
import { showErrorMessage, showSuccessMessage } from "../../utils/helpers";

class CurrentMovementDevices extends Component {
  componentDidMount() {
    this.handleGetDetails();
  }

  initialState = {
    type: `${Object.values(MovementsType)[0]}`,
    selectedDeviceId: "",
  };

  state = { ...this.initialState };

  processing = false;

  render() {
    const { type, selectedDeviceId } = this.state;
    const devices = this.props.currentMovement[type] || [];
    return (
      <Row className={"g-0"}>
        <Col lg={3} className="section_container">
          <Form.Group>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Select Movement Type</Form.Label>
                  <Form.Select
                    aria-label="Select movement type"
                    onChange={this.handleTransactionTypeChange}
                  >
                    {Object.keys(MovementsType).map((key, index) => (
                      <option value={`${MovementsType[key]}`}>{key}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <br />
          </Form.Group>
          <Button onClick={this.handleGetDetails}>Get details</Button>
        </Col>
        <Col lg={5}>
          <div
            className="section_container workAreaDiv invisibleScrollbar"
            style={{
              borderLeft: "0.5px solid black",
              borderRight: "0.5px solid black",
            }}
          >
            <TestStepsHeader
              icon={Images.MenuImages.Movement}
              title={"Devices"}
            />
            <br />
            <Table bordered hover responsive size={"sm"}>
              <thead>
                <tr>
                  <th>Device Id</th>
                  <th>Given To</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {devices
                  .sort((d1, d2) =>
                    String(d1.last_transaction.givenTo?.name).localeCompare(
                      d2.last_transaction.givenTo?.name
                    )
                  )
                  .map((device) => (
                    <tr
                      style={
                        selectedDeviceId === device.deviceId
                          ? { border: "2px solid blue", cursor: 'pointer' }
                          : {cursor: 'pointer'}
                      }
                      onClick={this.handleDeviceSelected.bind(
                        this,
                        device.deviceId
                      )}
                    >
                      <td>{device.deviceId}</td>
                      <td>{device.last_transaction.givenTo.name}</td>
                      <td>
                        {new Date(
                          device.last_transaction.date
                        ).toLocaleDateString("en-IN", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                        })}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Col>
        <Col lg={4}>
          <History deviceId={selectedDeviceId} />
        </Col>
      </Row>
    );
  }

  handleDeviceSelected = (deviceId) => {
    if (deviceId === this.state.selectedDeviceId) return;
    this.props.loadHistory(deviceId);
    this.setState({ selectedDeviceId: deviceId });
  };

  handleTransactionTypeChange = (e) => {
    this.setState(
      {
        type: e.target.value,
      },
      () => {
        this.handleGetDetails();
      }
    );
  };

  handleGetDetails = async () => {
    if (this.processing) {
      showErrorMessage(
        "Already Loading",
        "Wait for previous operation to complete"
      );
      return;
    }
    this.processing = true;
    const { success, data, message } = await this.props.getCurrentState(
      this.state.type
    );
    if (success) {
      showSuccessMessage("Details loaded", message);
      this.setState({ movements: data.movements });
    } else {
      showErrorMessage("Request failed", message);
    }
    this.processing = false;
  };
}

const mapStateToProps = (state) => ({
  currentMovement: state.currentMovement,
});

export default connect(mapStateToProps, { getCurrentState, loadHistory })(
  CurrentMovementDevices
);
