import { UserActions } from "../actionTypes";


const initialState = {
	loggedIn: false,
	chartsToken: '',
	accessToken: '',
	profile: {
		first_name: '',
		last_name: '',
		profile_pic: '',
		roles: [],
	},
};
const user = (
	state = initialState,
	action
) => {
	const {type, payload} = action;
	switch (type) {
		case UserActions.LOGIN:
			return {
				...state,
				loggedIn: true,
				accessToken: payload.accessToken,
			}

		case UserActions.LOAD_PROFILE:
			return {
				...state,
				profile: payload,
			}
		
		case UserActions.LOGOUT:
			return initialState;

		case UserActions.LOAD_CHARTS_TOKEN:
			return {
				...state,
				chartsToken: payload,
			}

		default:
			return state;
	}
}

export default user;