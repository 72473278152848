import React from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import TestStepsHeader from '../../components/TestStepsHeader';
import Images from '../../config/images';
const AppTest = ({appScriptProgress}) => {
	return (
		<div>
			<TestStepsHeader
        icon={Images.HardwareTesting.App}
        title={"App Testing"}
      />
			<br />
			<ProgressBar animated striped now={appScriptProgress.progress} />
			<br />
			<Form.Label>
				Automatic app test in progress, sit back, relax and enjoy.
				<br />
				But keep an open eye.
			</Form.Label>
		</div>
	)
}

export default AppTest;