import React, { useEffect } from "react";
import SideBar from "../components/SideBar";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";
import { getAllTestingStations } from "../redux/actions/testingStations";
import Header from "../components/Header";
import { getPcb } from "../redux/actions/pcbTypes";
import { getPcbVersion } from "../redux/actions/pcbVersion";
import { getAssembler } from "../redux/actions/assembler";
import DashboardRoutes from "../components/DashboardRoutes";
const Dashboard = (props) => {
  const { getAllTestingStations } = props;
  let Notification = false
  useEffect(() => {
    // check the user roles and call apis accordingly
    getAllTestingStations();
    getPcb(Notification)
    getPcbVersion(Notification)
    getAssembler(Notification)
  });
  return (
    <div>
      <Row className="g-0">
        <div style={{ flex: 1 }}>
          <SideBar />
        </div>
        <div style={{ flex: 16, maxHeight: '100vh', overflow: 'hidden' }}>
          <Header />
          <DashboardRoutes />
        </div>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { getAllTestingStations })(Dashboard);
