import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import io from "socket.io-client";
import DeviceProblemsList from "../components/DeviceProblemsList";
import StepCard from "../components/StepCard";
import {
  baseurl,
  SwitchPinTestingConfig,
  AppTestConfig,
  SyncTestingSteps,
  TestingStationEvents,
  WifiHealthStatus,
  TestingType,
  FanModuleSwitchState,
  AmplifyConfig,
  PubSubConfig,
  MQTTEvents,
} from "../config/constants";
import Images from "../config/images";
import {
  setSyncTestingStation,
  setTestingSync,
} from "../redux/actions/currentSyncTest";
import TestingActions from "../redux/actions/testing";
import {
  getNumberOfSwitches,
  isESP8266Device,
  showErrorMessage,
  showSuccessMessage,
} from "../utils/helpers";
import AppTest from "./TestDeviceScreens/AppTest";
import Details from "./TestDeviceScreens/Details";
import RegisterDevice from "./TestDeviceScreens/Register";
import Regulator from "./TestDeviceScreens/Regulator";
import Summary from "./TestDeviceScreens/Summary";
import SwitchPin from "./TestDeviceScreens/SwitchPin";
import { Amplify } from "aws-amplify";
import { Hub } from 'aws-amplify/utils'
import { PubSub } from "@aws-amplify/pubsub";
import APIs from "../utils/apis";

class TestDevice extends Component {
  state = {
    step: SyncTestingSteps.Register,
    deviceConnectionStatus: {
      deviceId: "",
      connectionId: "",
      connected: false,
      numOfConnections: 0,
      strength: 0,
      ws: "",
      wp: "",
      healthy: true,
      wifiHealth: WifiHealthStatus.Unknown,
    },
    switchpinConnectionStatus: {
      deviceId: "",
      connected: false,
      strength: 0,
      ws: "",
      wp: "",
      wifiHealth: WifiHealthStatus.Unknown,
    },
    switchPinScriptProgress: {
      commandsExecuted: 0,
      progress: 0,
      numberOfCommands: 0,
    },
    appScriptProgress: {
      progress: 0,
    },
    regulatorTimerPlaying: false,
    devicePassed: true,
    failedStep: SyncTestingSteps.Register,
  };

  iotProvider = null;
  appTestStarted = false;
  switchMessages = [];
  socketClient;
  sentMessages = [];
  testStartTime = new Date();
  fanTestingValues = [[], [], [], []];
  subscriptions = [];

  componentDidMount() {
    Hub.listen("pubsub", (data) => {
      const { payload } = data;
      if (payload.event === "ConnectionStateChange") {
        if (payload.data.connectionState === "Connected") {
          this.onTestingStationConnected(null);
        }
      }
    });
  }
  render() {
    const {
      step,
      deviceConnectionStatus,
      switchpinConnectionStatus,
      switchPinScriptProgress,
      appScriptProgress,
      devicePassed,
      failedStep,
    } = this.state;
    return (
      <div>
        <Row className="g-0">
          <Col lg={3}>
            <div className="section_container">
              <StepCard
                label={"Register Device"}
                step={SyncTestingSteps.Register}
                setStep={this.setStep}
                icon={Images.HardwareTesting.Register}
                selected={step === SyncTestingSteps.Register}
              />
              <StepCard
                label={"Device Details"}
                step={SyncTestingSteps.Details}
                setStep={this.setStep}
                icon={Images.HardwareTesting.Details}
                selected={step === SyncTestingSteps.Details}
              />
              <StepCard
                label={"Regulator Testing"}
                step={SyncTestingSteps.Regulator}
                setStep={this.setStep}
                icon={Images.HardwareTesting.Regulator}
                selected={step === SyncTestingSteps.Regulator}
              />
              <StepCard
                label={"Switch Pin Testing"}
                step={SyncTestingSteps.SwitchTest}
                setStep={this.setStep}
                icon={Images.HardwareTesting.Switch}
                selected={step === SyncTestingSteps.SwitchTest}
              />
              <StepCard
                label={"App Testing"}
                step={SyncTestingSteps.AppTest}
                setStep={this.setStep}
                icon={Images.HardwareTesting.App}
                selected={step === SyncTestingSteps.AppTest}
              />
              <StepCard
                label={"Summary"}
                step={SyncTestingSteps.Summary}
                setStep={this.setStep}
                icon={Images.HardwareTesting.Summary}
                selected={step === SyncTestingSteps.Summary}
              />
            </div>
          </Col>
          <Col
            lg={5}
            style={{
              borderLeft: "0.5px solid black",
              borderRight: "0.5px solid black",
            }}
          >
            <div className="section_container workAreaDiv invisibleScrollbar">
              {step === SyncTestingSteps.Register && (
                <RegisterDevice
                  onStepComplete={this.onRegistrationSuccessful}
                />
              )}
              {step === SyncTestingSteps.Details && (
                <Details
                  onStepComplete={this.onDetailsNext}
                  deviceConnectionStatus={deviceConnectionStatus}
                  switchpinConnectionStatus={switchpinConnectionStatus}
                />
              )}
              {step === SyncTestingSteps.Regulator && (
                <Regulator
                  sendFanCommand={this.sendFanCommand}
                  onStepComplete={this.onRegulatorTestingComplete}
                  setValues={this.updateFanTestingValues}
                  sendSwitchCommand={this.sendSwitchCommand}
                />
              )}
              {step === SyncTestingSteps.SwitchTest && (
                <SwitchPin switchPinScriptProgress={switchPinScriptProgress} />
              )}
              {step === SyncTestingSteps.AppTest && (
                <AppTest appScriptProgress={appScriptProgress} />
              )}
              {step === SyncTestingSteps.Summary && (
                <Summary
                  devicePassed={devicePassed}
                  failedStep={failedStep}
                  onFailed={this.onDeviceFailed}
                  onPassed={this.onDevicePassed}
                />
              )}
            </div>
          </Col>
          <Col lg={4}>
            <div className="section_container">
              <DeviceProblemsList
                device={this.props.currentSyncTest.device}
                onResolve={this.onResolveProblem}
                type={TestingType.Hardware}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  updateFanTestingValues = (values) => {
    this.fanTestingValues = values;
  };

  sendFanCommand = (switchState, command) => {
    const message = {
      switchId: 0,
      command,
      id:
        switchState === FanModuleSwitchState.MANUAL
          ? "switch"
          : `${new Date().getTime()}`.slice(5, 13),
    };
    if (switchState === FanModuleSwitchState.MANUAL) {
      this.sendSwitchCommand(0, 0);
      this.sendSwitchCommand(0, command);
    } else {
      this.sendSocketMessage(TestingStationEvents.EVENT, {
        event: "chat message",
        deviceId: this.props.currentSyncTest.testingStation.deviceId,
        payload: `${message.switchId},${message.command},${message.id}`,
      });
      this.mqttPublish(`${MQTTEvents.DEVICE_CONTROL}/${this.props.currentSyncTest.device.deviceId}`, `${message.switchId},${message.command},${message.id}`);
    }
  };

  onResolveProblem = async (log, index) => {
    if (log.passed || log.resolved) {
      // already a resolved issue; return;
    }
    const { device } = this.props.currentSyncTest;
    this.props.resolveFailure({
      deviceId: device.deviceId,
      index,
      message: "",
    });
  };

  onDevicePassed = async (message) => {
    const { deviceConnectionStatus } = this.state;
    const { device, testingStation } = this.props.currentSyncTest;
    const meta_data = {
      test_start_time: this.testStartTime,
      test_end_time: new Date(),
      deviceConnectionStatus,
      fanTestingValues: this.fanTestingValues,
    };
    const payload = {
      deviceId: device.deviceId,
      testing_station: testingStation.deviceId,
      switch_pin_tester: testingStation.switch_pin_tester,
      testing_type: SyncTestingSteps.Summary,
      type: TestingType.Hardware,
      message,
      meta_data,
    };
    const result = await this.props.hardwarePassed(payload);
    if (!result.success) {
      showErrorMessage("Could not pass device", result.message);
    } else {
      this.setStep(SyncTestingSteps.Register);
    }
    this.resetSocketClient();
  };

  onDeviceFailed = async (message) => {
    const {
      failedStep,
      deviceConnectionStatus,
      switchpinConnectionStatus,
      switchPinScriptProgress,
      appScriptProgress,
    } = this.state;
    const { device, testingStation } = this.props.currentSyncTest;
    const meta_data = {
      test_start_time: this.testStartTime,
      test_end_time: new Date(),
      switchMessages: this.switchMessages,
      sentMessages: this.sentMessages,
      fanTestingValues: this.fanTestingValues,
      deviceConnectionStatus,
    };
    let reason = "";
    switch (failedStep) {
      case SyncTestingSteps.Regulator:
        reason = "Regulator Testing";
        break;
      case SyncTestingSteps.SwitchTest:
        reason = "Switch Testing";
        meta_data.switchPinConnectionStatus = switchpinConnectionStatus;
        meta_data.switchPinScriptProgress = switchPinScriptProgress;
        break;
      case SyncTestingSteps.AppTest:
        reason = "App Testing";
        meta_data.appScriptProgress = appScriptProgress;
        break;
      default:
        reason = "Custom Reason";
    }
    const payload = {
      deviceId: device.deviceId,
      testing_station: testingStation.deviceId,
      switch_pin_tester: testingStation.switch_pin_tester,
      testing_type: failedStep,
      type: TestingType.Hardware,
      reason,
      message,
      meta_data,
    };
    await this.props.failed(payload);
    this.setStep(SyncTestingSteps.Register);
    this.resetSocketClient();
  };

  setStep = (step) => {
    this.setState({ step });
  };

  onDetailsNext = () => {
    if (this.props.currentSyncTest.device.basic_tested === false) {
      return showErrorMessage(
        "Devices not passed basic test",
        "Hardware testing can not be started till basic testing is passed",
        5000
      );
    }
    if (this.props.currentSyncTest.device.discarded === true) {
      return showErrorMessage(
        "Devices has been marked as discarded",
        "A discarded device can not be tested any further",
        5000
      );
    }
    if (
      this.state.deviceConnectionStatus.connected &&
      this.state.switchpinConnectionStatus.connected
      // &&
      // this.state.deviceConnectionStatus.ssid !== "aliste" &&
      // this.state.switchpinConnectionStatus.ssid !== "aliste"
    ) {
      this.setStep(SyncTestingSteps.Regulator);
      this.testStartTime = new Date();
    } else {
      showErrorMessage(
        "Devices not online",
        "Make sure that the device and switch pin tester are both online and not on aliste hotspot",
        10000
      );
    }
  };

  onRegulatorTestingComplete = () => {
    this.setStep(SyncTestingSteps.SwitchTest);
    this.switchMessages = [];
    this.setState(
      {
        switchPinScriptProgress: {
          numberOfCommands: 0,
          commandsExecuted: 0,
          progress: 0,
        },
      },
      () => {
        if (this.socketClient && this.socketClient.connected) {
          this?.socketClient?.emit(TestingStationEvents.START_SWITCH_PIN_TEST, {
            deviceId: this.props.currentSyncTest.testingStation.deviceId,
            numberOfCommands: SwitchPinTestingConfig.numberOfCommands,
            numberOfSwitches: getNumberOfSwitches(
              this.props.currentSyncTest.device.deviceId
            ),
            switchDelay: SwitchPinTestingConfig.switchDelay,
          });
        }
        if (this.iotProvider) {
          this.mqttPublish(
            `${MQTTEvents.SWITCHPIN_TESTER_START_TEST}/${this.props.currentSyncTest.testingStation.switch_pin_tester}`,
            `${getNumberOfSwitches(
              this.props.currentSyncTest.device.deviceId
            )},${SwitchPinTestingConfig.numberOfCommands},${SwitchPinTestingConfig.switchDelay
            }`
          );
        }
      }
    );
  };

  onRegistrationSuccessful = () => {
    const { user, currentSyncTest } = this.props;
    const { device, testingStation } = currentSyncTest;
    if (!device || !testingStation) {
      return;
    }
    this.resetSocketConnections();
    if (testingStation.isMQTT) {
      // form an mqtt connection if the selected testing station is of type mqtt
      this.resetMQTTConnection();
      Amplify.configure(AmplifyConfig);
      const pubsub = new PubSub({
        endpoint: PubSubConfig.aws_pubsub_endpoint,
        region: PubSubConfig.aws_pubsub_region,
        clientId: `${testingStation.deviceId}_${new Date().getTime().toString(32)}`,
      })
      this.iotProvider = pubsub;
      let s;
      s = pubsub
        .subscribe({ topics: [`${MQTTEvents.CONNECTED}/${testingStation.switch_pin_tester}`] })
        .subscribe({
          next: () => {
            this.onSwitchPinConnectionUpdate({ connected: true });
            this.mqttPublish(`${MQTTEvents.SWITCHPIN_TESTER_WIFI_DETAILS}/${testingStation.switch_pin_tester}`, {});
          },
        });
      this.subscriptions.push(s);
      s = pubsub
        .subscribe({ topics: [`${MQTTEvents.DISCONNECTED}/${testingStation.switch_pin_tester}`] })
        .subscribe({
          next: () => {
            this.onSwitchPinConnectionUpdate({ connected: false });
          },
        });
      this.subscriptions.push(s);
      s = pubsub
        .subscribe({ topics: [`${MQTTEvents.SWITCHPIN_TESTER_SYNC}/${testingStation.switch_pin_tester}`] })
        .subscribe({
          next: (data) => {
            this.onSwitchPinTesterSync(data);
          },
        });
      this.subscriptions.push(s);
      s = pubsub
        .subscribe({ topics: [`${MQTTEvents.SWITCHPIN_TESTER_CURRENT_WIFI}/${testingStation.switch_pin_tester}`] })
        .subscribe({
          next: (data) => {
            this.onSwitchPinTesterEvent({
              event: "current_wifi",
              payload: data,
            });
          },
        });
      this.subscriptions.push(s);
      s = pubsub
        .subscribe({ topics: [`${MQTTEvents.CONNECTED}/${device.deviceId}`] })
        .subscribe({
          next: (data) => {
            this.onSyncConnected(null, {
              socketId: data.sessionIdentifier,
            });
          },
        });
      this.subscriptions.push(s);
      s = pubsub
        .subscribe({ topics: [`${MQTTEvents.DISCONNECTED}/${device.deviceId}`] })
        .subscribe({
          next: (data) => {
            this.onSyncDisconnected();
          },
        });
      this.subscriptions.push(s);
      s = pubsub
        .subscribe({ topics: [`${MQTTEvents.DEVICE_MESSAGE}/${device.deviceId}`] })
        .subscribe({
          next: (data) => {
            this.onSyncEvent({
              event: "message",
              payload: {
                switchId: data.sid,
                state: data.s,
                id: data.id,
                changed: data.c,
              },
            });
          },
        });
      this.subscriptions.push(s);
      s = pubsub
        .subscribe({ topics: [`${MQTTEvents.DEVICE_CURRENT_WIFI}/${device.deviceId}`] })
        .subscribe({
          next: (data) => {
            this.onSyncEvent({ event: "current_wifi", payload: data });
          },
        });
      this.subscriptions.push(s);
      s = pubsub
        .subscribe({ topics: [`${MQTTEvents.STATUS_SYNC}/${testingStation.deviceId}`] })
        .subscribe({
          next: (data) => {
            this.onStatusUpdate(null, {
              online: data.devices.map((d) => ({
                deviceId: d.deviceId,
                socketId: d.sessionId,
              })),
            });
            data.devices.forEach((device) => {
              this.mqttPublish(`${MQTTEvents.DEVICE_WIFI_DETAILS}/${device.deviceId}`, {});
            })
            if (data.switchPinTester) {
              this.onSwitchPinConnectionUpdate({ connected: true });
              this.mqttPublish(`${MQTTEvents.SWITCHPIN_TESTER_WIFI_DETAILS}/${device.deviceId}`, {});
            }
          },
        });
    } else {
      const socketClient = io(`${baseurl}`, {
        query: {
          deviceId: testingStation.deviceId,
          device_type: "testingStation",
          test_type: TestingType.Hardware,
          deviceIds: [device.deviceId],
          name: `${user.first_name} ${user.last_name}`,
          employeeId: user.employeeId,
        },
      });

      socketClient.on("connect", () => {
        this.onTestingStationConnected(socketClient);
      });

      socketClient.on(TestingStationEvents.CONNECTED, (message) => {
        this.onSyncConnected(socketClient, message);
      });

      socketClient.on(TestingStationEvents.DISCONNECTED, (message) => {
        this.onSyncDisconnected(message);
      });

      socketClient.on(TestingStationEvents.EVENT, (message) => {
        this.onSyncEvent(message);
      });

      socketClient.on(TestingStationEvents.STATUS, (message) => {
        this.onStatusUpdate(socketClient, message);
      });

      socketClient.on(TestingStationEvents.SWITCH_PIN_SYNC, (message) => {
        this.onSwitchPinTesterSync(message);
      });

      socketClient.on(TestingStationEvents.SWITCH_PIN_EVENT, (message) => {
        this.onSwitchPinTesterEvent(message);
      });

      socketClient.on(TestingStationEvents.SWITCH_PIN_CONNECTION, (message) => {
        this.onSwitchPinConnectionUpdate(message);
      });

      socketClient.connect();
      this.socketClient = socketClient;
    }
  };

  onSwitchPinTestComplete = (message) => {
    const { commandsExecuted, numOfSwitches } = message;
    let passed = true;
    for (let i = 0; i < numOfSwitches; i++) {
      let numOfMessages = this.switchMessages.filter(
        (sm) => Number(sm.switchId) === Number(i)
      ).length;
      if (numOfMessages !== commandsExecuted * 2) {
        showErrorMessage(
          "Switch Test Failed",
          `Switch ${i} has failed switch message test`
        );
        passed = false;
      }
    }
    if (passed) {
      showSuccessMessage(
        "Switch Test Passed",
        "Device has passed switch pin test"
      );
      this.setStep(SyncTestingSteps.AppTest);
      this.setState(
        {
          appScriptProgress: {
            progress: 0,
          },
        },
        () => {
          setTimeout(() => {
            if (this.appTestStarted) {
              return;
            }
            this.appTestStarted = true;
            this.startAppTesting();
          }, 2000);
        }
      );
    } else {
      showErrorMessage(
        "Switch Test Failed",
        "Device has failed switch pin test"
      );
      this.registerDeviceFailed();
    }
  };

  sendSocketMessage = (event, payload) => {
    if (this.socketClient && this.socketClient.connected) {
      this?.socketClient?.emit(event, payload);
    }
  };

  canSendSocketMessage = () => {
    return this.socketClient && this.socketClient.connected;
  };

  sendSwitchCommand = (switchId, command) => {
    const { testingStation } = this.props.currentSyncTest;
    if (this.canSendSocketMessage()) {
      this?.socketClient?.emit(TestingStationEvents.SEND_SWITCH_PIN_COMMAND, {
        deviceId: testingStation.deviceId,
        switchId,
        command,
      });
    }
    this.mqttPublish(`${MQTTEvents.SWITCHPIN_TESTER_CONTROL}/${testingStation.switch_pin_tester}`, `${switchId},${Number(command) === 0 ? 0 : 1}`);
  };

  onSwitchPinTesterEvent = (message) => {
    const { testingStation } = this.props.currentSyncTest;
    const { event, payload } = message;
    if (event === "current_wifi") {
      const wifiIndex = testingStation.wifi_configs.findIndex(
        (wifi) => wifi.ws === payload.cws && wifi.wp === payload.cwp
      );
      let wifiHealth = WifiHealthStatus.Unknown;
      if (wifiIndex !== -1) {
        const wifi = testingStation.wifi_configs[wifiIndex];
        let expectedStrength = wifi.expected_esp32_strength;
        if (Math.abs(payload.strength - expectedStrength) < 5) {
          wifiHealth = WifiHealthStatus.Good;
        } else {
          wifiHealth = WifiHealthStatus.Bad;
        }
      }
      this.setState({
        switchpinConnectionStatus: {
          ...this.state.switchpinConnectionStatus,
          ws: payload.cws,
          wp: payload.cwp,
          strength: payload.s,
          ssid: payload.ws,
          wifiHealth,
        },
      });
    }
  };

  onSwitchPinConnectionUpdate = (message) => {
    this.setState({
      switchpinConnectionStatus: {
        ...this.state.switchpinConnectionStatus,
        connected: message.connected,
      },
    });
    if (message.connected === false && this.state.step === SyncTestingSteps.SwitchTest) {
      showErrorMessage("Please restart testing", "Switch pin tester diconnected");
      this.setStep(SyncTestingSteps.Register);
      this.resetSocketClient();
    }
  };

  onSyncConnected = (socketClient, message) => {
    const { testingStation } = this.props.currentSyncTest;
    // ask the device for its wifi details
    if (socketClient) {
      socketClient?.emit(TestingStationEvents.EVENT, {
        deviceId: testingStation.deviceId,
        event: "current_wifi",
        payload: "",
      });
    }
    const { deviceConnectionStatus } = this.state;
    this.setState({
      deviceConnectionStatus: {
        ...deviceConnectionStatus,
        connected: true,
        connectionId: message.socketId,
        numOfConnections: deviceConnectionStatus.numOfConnections + 1,
      },
    });
  };

  onSwitchPinTesterSync = (message) => {
    this.setState({ switchPinScriptProgress: message });
    if (Number(message.progress) === 100) {
      setTimeout(() => {
        this.onSwitchPinTestComplete(message);
      }, 1000); // waiting for 1 second to receive any remaining switch messages
    }
  };

  startAppTesting = async () => {
    this.sentMessages = [];
    for (let j = 1; j <= AppTestConfig.numberOfCommands; j += 1) {
      for (
        let i = 0;
        i < getNumberOfSwitches(this.props.currentSyncTest.device.deviceId);
        i += 1
      ) {
        if (!this.state.devicePassed) return;
        let message = {
          switchId: i,
          command: 100,
          id: String(new Date().getTime()).slice(5, 13),
          replied: false,
        };
        this.sentMessages = this.sentMessages.concat(message);
        this.mqttPublish(
          `${MQTTEvents.DEVICE_CONTROL}/${this.props.currentSyncTest.device.deviceId}`,
          `${message.switchId},${message.command},${message.id}`
        );
        this.sendSocketMessage(TestingStationEvents.EVENT, {
          event: "chat message",
          deviceId: this.props.currentSyncTest.testingStation.deviceId,
          payload: `${message.switchId},${message.command},${message.id}`,
        });
        await sleep(AppTestConfig.commandDelay);
      }
      await sleep(AppTestConfig.commandDelay);
      for (
        let i = 0;
        i < getNumberOfSwitches(this.props.currentSyncTest.device.deviceId);
        i++
      ) {
        if (!this.state.devicePassed) return;
        let message = {
          switchId: i,
          command: 0,
          id: String(new Date().getTime()).slice(5, 13),
          replied: false,
        };
        this.sentMessages = this.sentMessages.concat(message);
        this.mqttPublish(
          `${MQTTEvents.DEVICE_CONTROL}/${this.props.currentSyncTest.device.deviceId}`,
          `${message.switchId},${message.command},${message.id}`
        );
        this.sendSocketMessage(TestingStationEvents.EVENT, {
          event: "chat message",
          deviceId: this.props.currentSyncTest.testingStation.deviceId,
          payload: `${message.switchId},${message.command},${message.id}`,
        });
        await sleep(AppTestConfig.commandDelay);
      }
      this.setState({
        appScriptProgress: {
          progress: (j * 100) / AppTestConfig.numberOfCommands,
        },
      });
    }
    // the script has finised running wait a couple of seconds and then check if all messages were replied to
    setTimeout(this.verifyAppTestReplied, 2000);
  };

  mqttPublish = (event, payload) => {
    const lastIndex = event.lastIndexOf('/');
    const eventName = event.substring(0, lastIndex);
    const deviceId = event.substring(lastIndex + 1)
    if (typeof payload === typeof {}) {
      // if payload if of type object, we send via the pubsub connection
      if (this.iotProvider) {
        return this.iotProvider?.publish({ topics: [event], message: payload })
      } else {
        return APIs.publishMQTTMessage({ deviceId, event: eventName, payload: JSON.stringify(payload) });
      }
    } else {
      return APIs.publishMQTTMessage({ deviceId, event: eventName, payload: String(payload) });
    }
  };

  registerDeviceFailed = () => {
    this.setState({
      failedStep: this.state.step,
      devicePassed: false,
      step: SyncTestingSteps.Summary,
    });
  };

  verifyAppTestReplied = () => {
    let failed = this.sentMessages.some((message) => message.replied === false);
    this.appTestStarted = false;
    if (failed) {
      showErrorMessage(
        "App Test Failed",
        "The device failed app test, missed some commands or didnt reply to them"
      );
      this.registerDeviceFailed();
    } else {
      showSuccessMessage(
        "App Test Passed",
        "The device replied to all sent messages, ready to go..."
      );
      this.onAppTestSuccessful();
    }
  };

  onAppTestSuccessful = () => {
    this.setState({ devicePassed: true });
    this.setStep(SyncTestingSteps.Summary);
  };

  resetSocketConnections = () => {
    if (
      (this.socketClient && this.socketClient.connected) ||
      this.iotProvider
    ) {
      this?.socketClient?.disconnect();
      this.resetMQTTConnection();
      this.socketClient = undefined;
      this.setState({
        devicePassed: true,
        deviceConnectionStatus: {
          deviceId: "",
          connectionId: "",
          connected: false,
          numOfConnections: 0,
          strength: 0,
          ws: "",
          wp: "",
          healthy: true,
          wifiHealth: WifiHealthStatus.Unknown,
        },
        switchpinConnectionStatus: {
          deviceId: "",
          connected: false,
          strength: 0,
          ws: "",
          wp: "",
          wifiHealth: WifiHealthStatus.Unknown,
        },
      });
    }
  };

  resetMQTTConnection = () => {
    this.iotProvider = null;
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.subscriptions = [];
  }

  resetSocketClient = () => {
    if ((this.socketClient && this.socketClient.connected) || this.iotProvider) {
      this?.socketClient?.disconnect();
      this.socketClient = undefined;
      this.resetMQTTConnection();
      this.props.setTestingSync(undefined);
      this.props.setSyncTestingStation(undefined);
      this.setState({
        deviceConnectionStatus: {
          deviceId: "",
          connectionId: "",
          connected: false,
          numOfConnections: 0,
          strength: 0,
          ws: "",
          wp: "",
          healthy: true,
          wifiHealth: WifiHealthStatus.Unknown,
        },
        switchpinConnectionStatus: {
          deviceId: "",
          connected: false,
          strength: 0,
          ws: "",
          wp: "",
          wifiHealth: WifiHealthStatus.Unknown,
        },
        switchPinScriptProgress: {
          commandsExecuted: 0,
          progress: 0,
          numberOfCommands: 0,
        },
        appScriptProgress: {
          progress: 0,
        },
        regulatorTimerPlaying: false,
        devicePassed: true,
        failedStep: SyncTestingSteps.Register,
      });
    }
  };

  onSyncDisconnected = (message) => {
    // show an error message and quit cause the device should not be disconnecting at all
    this.setState({
      deviceConnectionStatus: {
        ...this.state.deviceConnectionStatus,
        connected: false,
        connectionId: "",
      },
    });
    if (
      ![
        SyncTestingSteps.Details,
        SyncTestingSteps.Summary,
        SyncTestingSteps.Register,
      ].includes(this.state.step)
    ) {
      // don't consider a failue on details page, as it could restart because of cred exchange or when connecting switch pin tester etc.
      showErrorMessage(
        "Device Restarted",
        "The device restarted/disconnected while testing. Failed",
        10000
      );
      this.setState({
        failedStep: this.state.step,
        devicePassed: false,
        step: SyncTestingSteps.Summary,
      });
    }
  };

  onStatusUpdate = (socketClient, message) => {
    const { device, testingStation } = this.props.currentSyncTest;
    const { deviceConnectionStatus } = this.state;
    const onlineIndex = message.online.findIndex(
      (a) => a.deviceId === device.deviceId
    );
    if (onlineIndex !== -1) {
      let isNewConnection =
        deviceConnectionStatus.connectionId !==
        message.online[onlineIndex].socketId;
      this.setState({
        deviceConnectionStatus: {
          ...deviceConnectionStatus,
          connected: onlineIndex !== -1,
          numOfConnections: isNewConnection
            ? deviceConnectionStatus.numOfConnections + 1
            : deviceConnectionStatus.numOfConnections,
          connectionId: message.online[onlineIndex].socketId,
        },
      });
      socketClient?.emit(TestingStationEvents.EVENT, {
        deviceId: testingStation.deviceId,
        event: "current_wifi",
        payload: "",
      });
      if (this.iotProvider) {
        this.mqttPublish(`${MQTTEvents.DEVICE_WIFI_DETAILS}/${device.deviceId}`, {});
      }
    } else {
      if (deviceConnectionStatus.connected) {
        this.setState({
          deviceConnectionStatus: {
            ...deviceConnectionStatus,
            connected: false,
            connectionId: "",
          },
        });
      }
    }
  };

  onSyncEvent = (message) => {
    const { device, testingStation } = this.props.currentSyncTest;
    const { event, payload } = message;
    if (event === "current_wifi") {
      const wifiIndex = testingStation.wifi_configs.findIndex(
        (wifi) => wifi.ws === payload.cws && wifi.wp === payload.cwp
      );
      let wifiHealth = WifiHealthStatus.Unknown;
      if (wifiIndex !== -1 && payload.cws === payload.ws) {
        const wifi = testingStation.wifi_configs[wifiIndex];
        let expectedStrength = -1;
        if (isESP8266Device(device.deviceId)) {
          expectedStrength = wifi.expected_8266_strength;
        } else {
          expectedStrength = wifi.expected_esp32_strength;
        }
        if (Math.abs(payload.strength - expectedStrength) < 5) {
          wifiHealth = WifiHealthStatus.Good;
        } else {
          wifiHealth = WifiHealthStatus.Bad;
        }
      }

      this.setState({
        deviceConnectionStatus: {
          ...this.state.deviceConnectionStatus,
          ws: payload.cws,
          wp: payload.cwp,
          ssid: payload.ws,
          strength: payload.s,
          wifiHealth,
        },
      });
    } else if (event === "message") {
      if (payload.id === "switch") {
        this.switchMessages = this.switchMessages.concat(payload);
      } else {
        this.sentMessages = this.sentMessages.map((m) =>
          String(m.id) === String(payload.id) ? { ...m, replied: true } : m
        );
      }
    }
  };

  onTestingStationConnected = (socketClient) => {
    const { device, testingStation } = this.props.currentSyncTest;
    this.setStep(SyncTestingSteps.Details);
    this.setState({
      deviceConnectionStatus: {
        ...this.state.deviceConnectionStatus,
        deviceId: device.deviceId,
      },
      switchpinConnectionStatus: {
        deviceId: testingStation.switch_pin_tester,
      },
    });
    setTimeout(() => {
      if (socketClient) {
        socketClient?.emit(TestingStationEvents.SYNC, {
          deviceId: testingStation.deviceId,
        });
      }
      if (this.iotProvider) {
        this.mqttPublish(
          `${MQTTEvents.STATUS_UPDATE}/${testingStation.deviceId}`,
          {
            deviceIds: [device.deviceId],
            switchPinTester: testingStation.switch_pin_tester,
            fanModuleTester: testingStation.fan_module_tester,
          }
        );
      }
    }, 1000);
  };
}

const sleep = (ms = 500) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};

const mapStateToProps = (state) => ({
  currentSyncTest: state.currentSyncTest,
  user: state.user.profile,
});

export default connect(mapStateToProps, {
  resolveFailure: TestingActions.resolveFailure,
  failed:  TestingActions.failed,
  hardwarePassed: TestingActions.hardwareTestPassed,
  setTestingSync,
  setSyncTestingStation,
})(TestDevice);
