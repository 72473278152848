import { CurrentMovementActions } from "../actionTypes";

const currentMovement = (state = {}, action) => {
	const {type, payload} = action;
	switch (type) {
		case CurrentMovementActions.LOAD:
			return {
				...state,
				[payload.type]: payload.devices,
			}
		default:
			return state;
	}
}

export default currentMovement;