import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import MovementsList from "../../components/MovementsList";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";
import {
  getAffilatedProducts,
  getDetailsOfSelectedProduct,
  getSelectedProductHistory,
  removeSelectedProduct,
} from "../../redux/actions/affilatedProduct";
import { getInstallationUsers } from "../../redux/actions/users";
import AddStock from "./AddStock";
import TransferStock from "./TransferStock";
import HistoryAffilatedProduct from "./HistoryAffilatedProduct";

export default function AffilatedProductMovement() {
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const installationUsers = useSelector(
    (state) => state.usersLists.installationUsers
  );
  const selectedProductDetails = useSelector(
    (state) => state.selectedProductDetails
  );
  const [selectedProductId, setSelectedProductId] = useState("");

  useEffect(() => {
    dispatch(getInstallationUsers());
    dispatch(removeSelectedProduct());
    getAffilatedProducts();
  }, [dispatch]);

  return (
    <Row className={"g-0"}>
      <Col lg={3} className="section_container">
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>Select Product</Form.Label>
            </Col>
          </Row>
          <Row className="p-3 overflow-y-scroll" style={{ maxHeight: "80vh" }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product Name</th>
                </tr>
              </thead>
              <tbody>
                {productList &&
                  productList.map((p, i) => {
                    return (
                      <tr
                        // style={{ color: `${selectedProductId === p._id ? "red !important" : ""}` }}
                        onClick={() => {
                          dispatch(removeSelectedProduct());
                          setSelectedProductId(p._id);
                          getDetailsOfSelectedProduct(p._id);
                          getSelectedProductHistory(p._id);
                        }}
                      >
                        <td>
                          {i + 1}
                        </td>
                        <td>
                          {p.name}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Row>
          <br />
        </Form.Group>
      </Col>
      <Col lg={5}>
        <div
          className="section_container workAreaDiv invisibleScrollbar"
          style={{
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
          }}
        >
          <TestStepsHeader
            icon={Images.MenuImages.Movement}
            title={"Log Movement"}
          />
          <br />
          <Row
            className="mt-6 ml-2 mr-2 p-4"
            style={{
              maxHeight: "85vh",
              overflowY: "scroll",
            }}
          >
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {selectedProductDetails &&
                  selectedProductDetails.map((d, i) => {
                    let key = Object.keys(d);
                    let val = Object.values(d);
                    if (isNaN(key[0]) === false) {
                      let find = installationUsers.filter((e) => e.employeeId === key[0]);
                      if (find.length !== 0) {
                        key = [find[0].first_name];
                      }
                    }
                    return (
                      <tr>
                        <th>{i + 1}</th>
                        <th>{key[0]}</th>
                        <th>{val[0]}</th>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Row>
          <br />
          <Row>
            <Col md={"3"}>
              <AddStock selectedProductId={selectedProductId} />
            </Col>
            <Col>
              <TransferStock selectedProductId={selectedProductId} />
            </Col>
          </Row>
          <br />
        </div>
      </Col>
      <Col lg={4}>
        <div className="section_container">
          <MovementsList movements={[]} />
          <HistoryAffilatedProduct installationUsers={installationUsers} />
        </div>
      </Col>
    </Row>
  );
}
