const SMPSTesting = {
	Register: require('../assets/register.png'),
	Details: require('../assets/details.png'),
	Test: require('../assets/smps.png'),
	Summary: require('../assets/summary.png'),
}

const TestingStatus = {
	EnterDeviceId: require('../assets/register.png'),
}

const RepairLog = {
	EnterDeviceId: require('../assets/register.png'),
}

const ControlSync = {
	Select: require('../assets/register.png'),
	Control: require('../assets/hardware.png'),
}

const BasicTesting = {
	Test: require('../assets/basic.png'),
}

const BypassTesting = {
	Bypass: require('../assets/basic.png'),
};

const HardwareTesting = {
	Register: require('../assets/register.png'),
	Details: require('../assets/details.png'),
	Regulator: require('../assets/regulator.png'),
	Switch: require('../assets/switch_test.png'),
	App: require('../assets/app_test.png'),	
	Summary: require('../assets/summary.png'),
	Problems: require('../assets/problems.png'),
	Test: require('../assets/hardware.png'),
}

const EnergISyncHardwareTesting = {
	Setup: require('../assets/details.png'),
	Meter: require('../assets/meter.png'),
	Summary: require('../assets/summary.png'),
}

const FanModuleImages = {
	Select: require('../assets/register.png'),
	Details: require('../assets/details.png'),
	Regulator: require('../assets/regulator_module.png'),
	Voltmeter: require('../assets/voltmeter.png'),
	App: require('../assets/app_test.png'),	
	Summary: require('../assets/summary.png'),
};

const MenuImages = {
	SMPSTesting: require('../assets/smps.png'),
	BasicTesting: require('../assets/basic.png'),
	HardwareTesting: require('../assets/hardware.png'),
	EnergISyncHardwareTesting: require('../assets/meter.png'),
	OnlineOfflineTesting: require('../assets/online.png'),
	FanModuleTesting: require('../assets/regulator.png'),
	UserManagement: require('../assets/user.png'),
	Users: require('../assets/user.png'),
	ControlSync: require('../assets/controlsync.png'),
	TestingStatus: require('../assets/testingStatus.png'),
	SwapDeviceIds: require('../assets/swapdeviceids.png'),
	UpdateWiFi: require('../assets/updatewifi.png'),
	RepairLog: require('../assets/repairlog.png'),
	BulkOTA: require('../assets/bulkOTA.png'),
	DiscardDevice: require('../assets/discardDevice.png'),
	Movement: require('../assets/movement.png'),
	ReturnedDevices: require('../assets/returnedDevice.png'),
	CurrentMovement: require('../assets/currentMovementDevice.png'),
	PcbType: require('../assets/pcbType.png'),
	PcbVersion: require('../assets/pcbVersion.png'),
	Assembler: require('../assets/assemblerIcon.png'),
	DeviceRegister: require('../assets/hardware.png'),
	SearchHouse:require('../assets/searchHouse.png'),
	SearchDispatch:require('../assets/searchDispatch.png'),
	DeviceIdsPdf:require('../assets/deviceIdsPdfIcon.png')
}

const Logo = require('../assets/logo.png');
const Images = {
	SMPSTesting,
	BasicTesting,
	HardwareTesting,
	FanModuleImages,
	MenuImages,
	Logo,
	ControlSync,
	RepairLog,
	EnergISyncHardwareTesting,
	TestingStatus,
	BypassTesting,
};

export default Images;
