import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { changePassword } from '../../redux/actions/UserList';
import { Form } from 'react-bootstrap';

export default function ChangePassword({ userDetail }) {
  const [modelShow, setModelShow] = useState(false)
  let user = useSelector(state => state.user)
  let [newPassword, setNewPassword] = useState("")

  const changePasswordAdmin = async () => {
    await changePassword(user, userDetail, newPassword, setModelShow)
  }
  return (
    <>
      <Button variant="primary" onClick={() => setModelShow(true)}>Change Password</Button>
      <Modal show={modelShow} onHide={() => setModelShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label htmlFor="inputPassword5">New Password</Form.Label>
          <Form.Control
            type="text"
            id="inputPassword5"
            aria-describedby="passwordHelpBlock"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModelShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => changePasswordAdmin()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
