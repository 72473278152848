import React from "react";
import { Col, Row, Stack, Table } from "react-bootstrap";
import { useEffect } from "react";
import {
  add,
  getAssembler,
  remove,
  update,
} from "../../redux/actions/assembler";
import DeletePcb from "../../components/DeletePcb";
import { useSelector } from "react-redux";
import AssemblerForm from "../../components/AssemblerForm";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";

function Assembler() {
  const assembler = useSelector((state) => state.assembler);
  useEffect(() => {
    getAssembler();
  }, []);
  return (
    <Row>
      <Col md={5} className="rounded elevated ">
        <Row className="mt-2 mb-2">
          <Col md={9}>
            <TestStepsHeader
              icon={Images.MenuImages.Assembler}
              title={"Assembler"}
            />
          </Col>
          <Col
            md={3}
            className="d-flex justify-content-end align-items-center"
            style={{ borderBottom: "0.2px solid black", marginBottom: "15px" }}
          >
            <AssemblerForm data={{ name: "" }} text="Add New" fun={add} />
          </Col>
        </Row>
        <Row className="m-0">
          <Col
            md={12}
            className="customScrollbar"
            style={{ height: "70vh", overflow: "scroll" }}
          >
            <Table striped bordered hover variant="light">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {assembler &&
                  assembler[0] !== null &&
                  assembler.map((pcb, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{pcb?.name}</td>
                        <td>
                          <Stack gap={3} direction="horizontal">
                            <AssemblerForm
                              data={pcb}
                              text="Update"
                              fun={update}
                            />
                            <DeletePcb
                              data={pcb}
                              fun={remove}
                              text={"Delete Assembler"}
                            />
                          </Stack>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Assembler;
