import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { showErrorMessage } from "../utils/helpers";
import {
  registerNewDevice,
  updateDevice,
} from "../redux/actions/deviceTypesTrack";

function AddNewDeviceType({ data={}, text, updateDevicesList, refreshData, setRefereshData }) {
  const [show, setShow] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  let [initialValues, setInitialValues] = useState(data);
  useEffect(() => {
    setInitialValues(data);
  }, [data]);


  const handleSubmit = async () => {
    const { name, deviceCode, description } = initialValues;

    if (!name || !deviceCode || !description) {
      showErrorMessage("", "Fields are required", 2000);
    } else {
      setSubmitting(true);
      // fun(initialValues, setSubmitting, setShow);

      if (text === "Update") {
        await updateDevice(initialValues, updateDevicesList);
        setShow(false);
        setSubmitting(false)
        setRefereshData(!refreshData)
      } else {
        await registerNewDevice(initialValues, updateDevicesList);
        setShow(false);
        setSubmitting(false);
        setRefereshData(!refreshData)

      }
    }
  };

  const handleChange = (event) => {
    if (event?.target?.name === 'active') {
      setInitialValues({
        ...initialValues,
        [event.target.name]: event.target.checked,
      });
    } else {
      setInitialValues({
        ...initialValues,
        [event.target.name]: event.target.value,
      });
    }
  };
  return (
    <>
      <>
        <Button
          variant="primary"
          size="sm"
          // style={{ marginRight: "33px" }}
          onClick={() => setShow(true)}
        >
          {text === "Add New" ? <Plus style={{ fontSize: "16px" }} /> : null}
          {text}
        </Button>
      </>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setInitialValues(data);
          setSubmitting(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {text}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Form.Group
                as={Col}
                md="6"
                controlId="validationFormik01"
                className="mb-3"
              >
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={initialValues.name}
                  onChange={handleChange}
                  placeholder="Device Name"
                />
              </Form.Group>
              <Form.Group
                as={Col}
                md="6"
                controlId="validationFormik02"
                className="mb-3"
              >
                <Form.Label>Code</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="deviceCode"
                    value={initialValues.deviceCode}
                    onChange={handleChange}
                    placeholder="Code"
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>BinLoc</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    name="binLoc"
                    value={initialValues.binLoc}
                    onChange={handleChange}
                    placeholder="BinLoc"
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>Status</Form.Label>
                <InputGroup>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={initialValues.active ? "active" : "inactive"}
                    name="active"
                    onChange={handleChange}
                    // defaultValue={initialValues.active}
                    defaultChecked={initialValues.active}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-3">
                <Form.Label>Description</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    aria-describedby="inputGroupPrepend"
                    name="description"
                    value={initialValues.description}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="6" className="mb-3">
                {text === "Update" && (
                  <>
                    <Form.Label>Last Assigned Id</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        placeholder="Assigned Id"
                        aria-describedby="inputGroupPrepend"
                        name="lastAssignedId"
                        value={initialValues.lastAssignedId}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </>
                )}
              </Form.Group>
              <Form.Group as={Col} className="mb-3">
                <Form.Label>OTA EndPoint</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="OTA Endpoint"
                    aria-describedby="inputGroupPrepend"
                    name="ota_endpoint"
                    value={initialValues.ota_endpoint}
                    onChange={handleChange}
                  />
                </InputGroup>
              </Form.Group>
            </Row>

            <div className="d-flex flex-row-reverse gap-3">
              <Button
                onClick={handleSubmit}
                disabled={isSubmitting}
                variant="success"
              >
                Submit
              </Button>
              <Button onClick={() => setShow(false)} variant="danger">
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddNewDeviceType;
