import { ReturnedDevicesActions } from "../actionTypes";

const returnedDevices = (state = {
	list: [],
}, action) => {
	const {type, payload} = action;
	switch (type) {
		case ReturnedDevicesActions.LOAD:
			return {
				...state,
				list: payload,
			}
		default:
			return state;
	}
}

export default returnedDevices;