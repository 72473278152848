import axios from "axios";
import { baseurl } from "../../config/constants";
import { accessTokenHeader } from "../../utils/helpers";
import { CurrentMovementActions } from "../actionTypes";

const loadCurrentMovements = (payload = { type: 0, devices: [] }) => ({
  type: CurrentMovementActions.LOAD,
  payload,
});

export const logMovement = async (
  details = {
    deviceId: "S050000",
    transaction: {
      type: 0,
      transactionDate: "2023-04-15T06:55:32.078Z",
      givenTo: {
        name: "Stock Entry",
      },
      message: "Hello World! First Stock Entry",
      transactedBy: "62fb55568dafe52f2700005f",
    },
  }
) => {
  return axios
    .post(
      `${baseurl}/movements/log?deviceId=${details.deviceId}&userId=${
        details.transaction.transactedBy
      }&transactionType=${
        details.transaction.type
      }&timestamp=${new Date().getTime()}`,
      details,
      {
        headers: {
          ...accessTokenHeader(),
        },
      }
    )
    .then(
      (result) => {
        const { success, message, data } = result.data;
        return {
          success,
          message,
          data,
        };
      },
      (error) => {
        const message = error.response.data
          ? error.response.data.message
          : String(error);
        return {
          success: false,
          message,
        };
      }
    )
    .catch((error) => {
      return {
        success: false,
        message: String(error),
      };
    });
};

export const getMovementDetail = async (deviceId) => {
 
  return axios
    .post(
      `${baseurl}/movements/details?deviceId=${
        deviceId
      }&timestamp=${new Date().getTime()}`,
      {
        deviceId,
      },
      {
        headers: {
          ...accessTokenHeader(),
        },
      }
    )
    .then(
      (result) => {
        const { success, message, data } = result.data;
        return {
          success,
          message,
          data,
        };
      },
      (error) => {
        const message = error.response.data
          ? error.response.data.message
          : String(error);
        return {
          success: false,
          message,
        };
      }
    )
    .catch((error) => {
      return {
        success: false,
        message: String(error),
      };
    });
};

export const getCurrentState = (movementType) => async (dispatch) => {
  return axios
    .get(`${baseurl}/movements/withState/${movementType}`, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(
      (result) => {
        const { success, message, data } = result.data;
        dispatch(
          loadCurrentMovements({
            type: movementType,
            devices: data.devices,
          })
        );
        return {
          success,
          message,
          data,
        };
      },
      (error) => {
        const message = error.response.data
          ? error.response.data.message
          : String(error);
        return {
          success: false,
          message,
        };
      }
    )
    .catch((error) => {
      return {
        success: false,
        message: String(error),
      };
    });
};
