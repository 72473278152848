import axios from "axios";
import { baseurl } from "../../config/constants";
import { accessTokenHeader } from "../../utils/helpers";
import { showErrorMessage, showSuccessMessage } from "../../utils/helpers";
import { DeviceRegisterAction } from "../actionTypes";
import store from "../source";

const loadDeviceData = (data) => {
  return {
    type: DeviceRegisterAction.LOAD,
    payload: data,
  };
};
const updateDeviceData = (data) => {
  return {
    type: DeviceRegisterAction.UPDATE,
    payload: data,
  };
};
const removeDeviceData = (data) => {
  return {
    type: DeviceRegisterAction.REMOVE,
    payload: data,
  };
};

export const add = async (details, setSubmitting) => {
  await axios
    .post(`${baseurl}/deviceRegister/create`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const update = async (details, setSubmitting, setShow) => {
  await axios
    .post(`${baseurl}/deviceRegister/update`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        store.dispatch(updateDeviceData(res.data.data.deviceRegister));
        setShow(false);
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const remove = async (details, setSubmitting, setShow) => {
  await axios
    .post(`${baseurl}/deviceRegister/delete`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        setShow(false);
        store.dispatch(removeDeviceData(res.data.data.deviceRegister));
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const getDevice = () => {
  axios
    .get(`${baseurl}/deviceRegister/get`, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        store.dispatch(loadDeviceData(res.data.data.deviceRegister));
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
    });
};

export const checkDevice = async (detail) => {
  return await axios
    .post(`${baseurl}/deviceRegister/check`, detail, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        return true;
      } else {
        showErrorMessage("", res.data.message, 2000);
        return false;
      }
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      return false;
    });
};
