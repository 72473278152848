import React, { Component } from "react";
import { Badge, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { connect } from "react-redux";
import { io } from "socket.io-client";
import {
  AmplifyConfig,
  baseurl,
  MQTTEvents,
  OnlineOfflineTestingTime,
  OnlineTestingStatus,
  PubSubConfig,
  TestingStationEvents,
  TestingType,
  WifiHealthStatus,
} from "../config/constants";
import TestingActions from "../redux/actions/testing";
import { isESP8266Device, showErrorMessage, showInfoMessage } from "../utils/helpers";
import { Amplify } from 'aws-amplify'
import { Hub } from 'aws-amplify/utils';
import { PubSub } from '@aws-amplify/pubsub';
import APIs from "../utils/apis";
class OnlineOfflineTest extends Component {
  state = {
    devices: [],
    deviceConnectionData: {},
    deviceInput: "",
    testingStationIndex: 0,
    testingStarted: false,
    timerKey: 0,
    modalOpen: false,
    message: "",
    selectedDeviceId: "",
  };
  processing = false;
  socketClient;
  iotProvider;
  subscriptions = [];

  render() {
    const { testingStations } = this.props;
    const {
      deviceInput,
      devices,
      deviceConnectionData,
      testingStationIndex,
      testingStarted,
      timerKey,
      modalOpen,
      message,
      selectedDeviceId,
    } = this.state;
    let anyDeviceFailed = devices.some(deviceId => {
      const deviceData = deviceConnectionData[deviceId] || {
        connected: false,
        connectivity: WifiHealthStatus.Unknown,
        status: OnlineTestingStatus.Testing,
      }
      if (deviceData.status === OnlineTestingStatus.Passed) {
        return false;
      } else {
        return true;
      }
    });
    return (
      <div className={'workAreaDiv invisibleScrollbar'}>
        <Row className={"g-0"}>
          <Col lg={9}>
            <div className="rounded elevated" style={{ padding: "22px 15px" }}>
              <Form.Group>
                <Form.Label>Select Testing Station</Form.Label>
                <Form.Select
                  value={testingStationIndex}
                  onChange={(e) => {
                    this.setTestingStationIndex(e.target.value);
                  }}
                >
                  {testingStations.filter(ts => ts.active).map((ts, index) => (
                    <option value={index} key={ts._id}>{ts.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <br />
              <Form.Label>Enter Device IDs to Add</Form.Label>
              <Row>
                <Col lg={8}>
                  <Form.Group>
                    <Form.Control
                      placeholder="Comma Separated device IDs"
                      aria-label="Device ID"
                      value={deviceInput}
                      onChange={(e) => {
                        this.setDeviceInput(e.target.value.toUpperCase());
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col col={4}>
                  <Row className={"g-0"}>
                    <Col>
                      <Button variant="primary" onClick={this.handleAddDevices}>
                        Add
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        variant="success"
                        onClick={this.handleStartTesting}
                      >
                        Start
                      </Button>
                    </Col>
                    <Col>
                      <Button variant="danger" onClick={this.onTestStopped}>
                        Stop
                      </Button>
                    </Col>
                    <Col>
                      <Button variant="primary" onClick={this.syncStatus}>
                        Sync
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={3}>
            <div className="rounded elevated">
              <center>
                <CountdownCircleTimer
                  isPlaying={testingStarted}
                  onComplete={this.onTestComplete}
                  duration={OnlineOfflineTestingTime}
                  key={timerKey}
                  colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                  colorsTime={[
                    OnlineOfflineTestingTime,
                    (OnlineOfflineTestingTime * 2) / 3,
                    (OnlineOfflineTestingTime * 1) / 3,
                    0,
                  ]}
                >
                  {({ remainingTime }) => (
                    <div className="timer">
                      <div className="text">Remaining</div>
                      <div className="value">{remainingTime}</div>
                      <div className="text">seconds</div>
                    </div>
                  )}
                </CountdownCircleTimer>
              </center>
            </div>
          </Col>
        </Row>
        <div className="rounded elevated">
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Device ID</th>
                <th>Connected</th>
                <th>Connectivity</th>
                <th>Actions</th>
                <th>Result</th>
                <th>Already Tested</th>
              </tr>
            </thead>
            <tbody>
              {devices.map((deviceId, index) => {
                const deviceData = deviceConnectionData[deviceId] || {
                  connected: false,
                  connectivity: WifiHealthStatus.Unknown,
                  status: OnlineTestingStatus.Testing,
                };
                return (
                  <tr key={deviceId}>
                    <td>{index + 1}</td>
                    <td>{deviceId}</td>
                    <td>
                      <h5>
                        <Badge bg={deviceData.connected ? "success" : "danger"}>
                          {deviceData.connected ? "Online" : "Offline"}
                        </Badge>
                      </h5>
                    </td>
                    <td>
                      <h5>
                        <Badge
                          bg={
                            deviceData.connectivity === WifiHealthStatus.Unknown
                              ? "secondary"
                              : deviceData.connectivity === WifiHealthStatus.Bad
                                ? "danger"
                                : "success"
                          }
                        >
                          {deviceData.connectivity} {deviceData.strength}
                        </Badge>
                      </h5>
                    </td>
                    <td>
                      <Row>
                        <Col lg={2}>
                          <Button
                            size={"sm"}
                            variant={"primary"}
                            onClick={() => {
                              this.handleRemoveDevice(deviceId);
                            }}
                          >
                            &nbsp;Remove&nbsp;
                          </Button>
                        </Col>

                        <Col lg={2}>
                          <Button
                            size={"sm"}
                            variant={"success"}
                            onClick={() => {
                              this.setState({
                                modalOpen: true,
                                selectedDeviceId: deviceId,
                              });
                            }}
                            active={
                              deviceData.status === OnlineTestingStatus.Passed
                            }
                            disabled={
                              deviceData.status !== OnlineTestingStatus.Passed
                            }
                          >
                            &nbsp;Passed&nbsp;
                          </Button>
                        </Col>

                        <Col lg={2}>
                          <Button
                            size={"sm"}
                            variant={"danger"}
                            onClick={() => {
                              this.setState({
                                modalOpen: true,
                                selectedDeviceId: deviceId,
                              });
                            }}
                          >
                            &nbsp;Failed&nbsp;
                          </Button>
                        </Col>
                      </Row>
                    </td>
                    <td>
                      <h5>
                        <Badge
                          bg={
                            deviceData.status === OnlineTestingStatus.Passed
                              ? "success"
                              : deviceData.status === OnlineTestingStatus.Failed
                                ? "danger"
                                : "secondary"
                          }
                        >
                          {deviceData.status === OnlineTestingStatus.Passed
                            ? "Passed"
                            : deviceData.status === OnlineTestingStatus.Failed
                              ? "Failed"
                              : "Testing"}
                        </Badge>
                      </h5>
                    </td>
                    <td>
                      <h5>
                        <Badge
                          bg={deviceData.alreadyTested ? "success" : "danger"}
                        >
                          {deviceData.alreadyTested ? "Yes" : "No"}
                        </Badge>
                      </h5>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="rounded elevated">
          <Row>
            <Col lg={8} />
            <Col lg={2}>
              <Button
                size={"sm"}
                variant={"success"}
                onClick={() => {
                  this.setState({
                    modalOpen: true,
                    selectedDeviceId: "all",
                  });
                }}
                active={!anyDeviceFailed}
                disabled={anyDeviceFailed}
              >
                &nbsp;Pass All Left&nbsp;
              </Button>
            </Col>

            <Col lg={2}>
              <Button
                size={"sm"}
                variant={"danger"}
                onClick={() => {
                  this.setState({
                    modalOpen: true,
                    selectedDeviceId: "all",
                  });
                }}
              >
                &nbsp;Fail All Left&nbsp;
              </Button>
            </Col>
          </Row>
        </div>
        <Modal show={modalOpen} onHide={this.hideModal}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Online Offline Test Result</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Message reason for success/failed</Form.Label>
              <Form.Control
                as={"textarea"}
                rows={5}
                value={message}
                onChange={(e) => {
                  this.handleMessageChanged(e.target.value);
                }}
                placeholder={"Message for test logs"}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size={"sm"}
              variant={"success"}
              onClick={this.handlePassedOnlineOfflineTesting}
              active={selectedDeviceId === "all" || (
                deviceConnectionData[selectedDeviceId] &&
                deviceConnectionData[selectedDeviceId].status ===
                OnlineTestingStatus.Passed)
              }
              disabled={selectedDeviceId !== "all" &&
                deviceConnectionData[selectedDeviceId] &&
                deviceConnectionData[selectedDeviceId].status !==
                OnlineTestingStatus.Passed
              }
            >
              &nbsp;Passed&nbsp;
            </Button>

            <Button
              size={"sm"}
              variant={"danger"}
              onClick={this.handleFailTesting}
            >
              &nbsp;Failed&nbsp;
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

  hideModal = () => {
    this.setState({ modalOpen: false });
  };

  getStatusUpdate = () => {
    const { testingStations } = this.props;
    const testingStation = testingStations[this.state.testingStationIndex];
    this.mqttPublish(`${MQTTEvents.STATUS_UPDATE}/${testingStation.deviceId}`, { deviceIds: this.state.devices });
  }

  componentDidMount() {
    Hub.listen("pubsub", (data) => {
      const { payload } = data;
      if (payload.event === "ConnectionStateChange") {
        if (payload.data.connectionState === "Connected") {
          this.getStatusUpdate();
        }
      }
    });
  }

  handleFailTesting = async () => {
    if (this.processing === true) return;
    this.processing = true;
    const { devices, selectedDeviceId, message, testingStationIndex, deviceConnectionData } = this.state;
    const testingStation = this.props.testingStations[testingStationIndex];
    if (selectedDeviceId === "all") {
      devices.forEach(async (deviceId) => {
        const payload = {
          deviceId,
          testing_station: testingStation.deviceId,
          type: TestingType.OnlineOffline,
          reason: "Failed Online Offline",
          message,
          meta_data: {
            strength: deviceConnectionData[deviceId].strength,
          }
        };
        await this.props.failed(payload);
      })
      this.setState({ devices: [] });
    } else {
      const payload = {
        deviceId: selectedDeviceId,
        testing_station: testingStation.deviceId,
        type: TestingType.OnlineOffline,
        reason: "Failed Online Offline",
        message,
        meta_data: {
          strength: deviceConnectionData[selectedDeviceId].strength,
        }
      };
      await this.props.failed(payload);
      this.setState({ devices: devices.filter(d => d !== selectedDeviceId) });
    }
    this.setState({ modalOpen: false, selectedDeviceId: "", message: "" });
    this.processing = false;
  };

  handlePassedOnlineOfflineTesting = async () => {
    if (this.processing === true)
      this.processing = true;
    const {
      selectedDeviceId,
      message,
      testingStationIndex,
      deviceConnectionData,
      devices,
    } = this.state;
    const testingStation = this.props.testingStations[testingStationIndex];
    if (selectedDeviceId === "all") {
      let finalDevices = [...devices];
      for (const deviceId of devices) {
        if (
          deviceConnectionData[deviceId].status !==
          OnlineTestingStatus.Passed
        ) {
          showErrorMessage(`Device ${deviceId} Failed`, "Cant pass a failed device");
          return;
        }
        const payload = {
          deviceId: deviceId,
          testing_station: testingStation.deviceId,
          message,
          type: TestingType.OnlineOffline,
          meta_data: {
            strength: deviceConnectionData[deviceId].strength,
          }
        };
        const response = await this.props.onlinePassed(payload);
        if (response.success) {
          finalDevices = finalDevices.filter(did => did !== deviceId);
        }
      }
      this.setState({ devices: finalDevices });
    } else {
      if (
        deviceConnectionData[selectedDeviceId].status !==
        OnlineTestingStatus.Passed
      ) {
        showErrorMessage("Device Failed", "Cant pass a failed device");
        return;
      }
      const payload = {
        deviceId: selectedDeviceId,
        testing_station: testingStation.deviceId,
        message,
        type: TestingType.OnlineOffline,
        meta_data: {
          strength: deviceConnectionData[selectedDeviceId].strength,
        }
      };
      const response = await this.props.onlinePassed(payload);
      if (response.success) {
        this.setState({ devices: this.state.devices.filter(d => d !== selectedDeviceId) });
      }     
    }
    this.setState({ modalOpen: false, selectedDeviceId: "", message: "" });
    this.processing = false;
  };

  handleSetModal = (modalOpen) => {
    this.setState({ modalOpen });
  };

  handleMessageChanged = (message) => {
    this.setState({ message });
  };

  setTestingStationIndex = (testingStationIndex) => {
    if (this.state.testingStarted) {
      showErrorMessage("Invalid Operation", "Can't change testing station while testing");
      return;
    }
    this.setState({ testingStationIndex });
  };

  setDeviceInput = (deviceInput) => {
    this.setState({ deviceInput });
  };

  setDevices = (devices) => {
    this.setState({ devices });
  };

  handleRemoveDevice = (deviceId) => {
    const { deviceConnectionData, devices } = this.state;
    const newDeviceConnectionData = { ...deviceConnectionData };
    delete newDeviceConnectionData[deviceId];
    const newDevices = devices.filter((d) => d !== deviceId);
    this.setState(
      { devices: newDevices, deviceConnectionData: newDeviceConnectionData },
      () => {
        this.handleConnectAndAddDevice();
      }
    );
  };

  onTestComplete = () => {
    const { deviceConnectionData, devices } = this.state;
    const newDeviceConnectionData = { ...deviceConnectionData };
    let successCounts = 0;
    devices.forEach((deviceId) => {
      if (newDeviceConnectionData[deviceId]) {
        if (
          newDeviceConnectionData[deviceId].status ===
          OnlineTestingStatus.Testing
        ) {
          newDeviceConnectionData[deviceId].status = OnlineTestingStatus.Passed;
          successCounts += 1;
        }
      }
    });
    this.setState({ deviceConnectionData: newDeviceConnectionData });
    this.resetSocketClient()
    showInfoMessage(
      "Test Complete",
      `The test countdown has completed ${successCounts} of ${devices.length} have passed the automated testing.`
    );
  };

  onTestStopped = () => {
    this.setState({ testingStarted: false, timerKey: this.state.timerKey + 1 });
    this.resetSocketClient()
  };

  handleDeviceConnected = (deviceId, connectionId) => {
    const { deviceConnectionData } = this.state;

    const currentDeviceConnectionData = deviceConnectionData[deviceId] || {
      numOfConnections: 0,
    };
    this.setState({
      deviceConnectionData: {
        ...deviceConnectionData,
        [deviceId]: {
          ...currentDeviceConnectionData,
          connected: true,
          connectionId,
          numOfConnections:
            currentDeviceConnectionData.numOfConnections + 1,
        },
      },
    });
  }

  handleCurrentWifiDetails = (deviceId, cws, cwp, ws, strength) => {
    const { testingStations } = this.props;
    const testingStation = testingStations[this.state.testingStationIndex];
    const wifiIndex = testingStation.wifi_configs.findIndex(
      (wifi) => wifi.ws === cws && wifi.wp === cwp
    );
    let wifiHealth = WifiHealthStatus.Unknown;
    if (wifiIndex !== -1 && ws === cws) {
      const wifi = testingStation.wifi_configs[wifiIndex];
      let expectedStrength = -1;
      if (isESP8266Device(deviceId)) {
        expectedStrength = wifi.expected_8266_strength;
      } else {
        expectedStrength = wifi.expected_esp32_strength;
      }
      if (Math.abs(strength - expectedStrength) < 5) {
        wifiHealth = WifiHealthStatus.Good;
      } else {
        wifiHealth = WifiHealthStatus.Bad;
      }
    }
    const { deviceConnectionData } = this.state;
    const currentDeviceConnectionData = deviceConnectionData[
      deviceId
    ] || { numOfConnections: 0 };
    this.setState({
      deviceConnectionData: {
        ...deviceConnectionData,
        [deviceId]: {
          ...currentDeviceConnectionData,
          ws: cws,
          wp: cwp,
          ssid: ws,
          strength: strength,
          connectivity: wifiHealth,
        },
      },
    });
  }

  handleTestingStationSync = (message) => {
    const { deviceConnectionData } = this.state;
    let newDeviceConnectionData = { ...deviceConnectionData };
    message.online.forEach((device) => {
      const { deviceId, socketId } = device;
      const currentDeviceConnectionData =
        deviceConnectionData[deviceId] || {};
      let isNewConnection =
        currentDeviceConnectionData.connectionId !== socketId;
      newDeviceConnectionData = {
        ...newDeviceConnectionData,
        [deviceId]: {
          ...currentDeviceConnectionData,
          connected: true,
          numOfConnections: isNewConnection
            ? currentDeviceConnectionData.numOfConnections + 1
            : currentDeviceConnectionData.numOfConnections,
          connectionId: socketId,
        }
      }
    });
    const { testingStarted } = this.state;
    message.offline.forEach((deviceId) => {
      const currentDeviceConnectionData =
        deviceConnectionData[deviceId] || {};
      newDeviceConnectionData = {
        ...newDeviceConnectionData,
        [deviceId]: {
          ...currentDeviceConnectionData,
          connected: false,
          connectionId: "",
          connectivity: WifiHealthStatus.Unknown,
          status: testingStarted
            ? OnlineTestingStatus.Failed
            : OnlineTestingStatus.Testing,
        },
      };
    });
    this.setState({ deviceConnectionData: newDeviceConnectionData });
  }

  handleDeviceDisconnected = (deviceId) => {
    const { deviceConnectionData, testingStarted } = this.state;
    const currentDeviceConnectionData = deviceConnectionData[deviceId] || {};
    this.setState(
      {
        deviceConnectionData: {
          ...deviceConnectionData,
          [deviceId]: {
            ...currentDeviceConnectionData,
            connected: false,
            connectionId: "",
            connectivity: WifiHealthStatus.Unknown,
            status: testingStarted
              ? OnlineTestingStatus.Failed
              : OnlineTestingStatus.Testing,
          },
        },
      }
    );
  }

  mqttPublish = (event, payload) => {
    const lastIndex = event.lastIndexOf('/');
    const eventName = event.substring(0, lastIndex);
    const deviceId = event.substring(lastIndex + 1)
    if (typeof payload === typeof {}) {
      // if payload if of type object, we send via the pubsub connection
      if (this.iotProvider) {
        return this.iotProvider?.publish({ topics: [event], message: payload })
      } else {
        return APIs.publishMQTTMessage({ deviceId, event: eventName, payload: JSON.stringify(payload) });
      }
    } else {
      return APIs.publishMQTTMessage({ deviceId, event: eventName, payload: String(payload) });
    }
  };

  resetMQTTConnection = () => {
    this.iotProvider = null;
    for (let s of this.subscriptions) {
      s.unsubscribe();
    }
    this.subscriptions = [];
  }

  handleConnectAndAddDevice = () => {
    const { user, testingStations } = this.props;
    const testingStation = testingStations[this.state.testingStationIndex];
    if (testingStation.isMQTT) {
      if (this.iotProvider) {
        this.mqttPublish(`${MQTTEvents.STATUS_UPDATE}/${testingStation.deviceId}`, { deviceIds: this.state.devices });
        return;
      }
      Amplify.configure(AmplifyConfig);
      this.resetMQTTConnection();
      const pubsub = new PubSub({
        endpoint: PubSubConfig.aws_pubsub_endpoint,
        region: PubSubConfig.aws_pubsub_region,
        clientId: `${testingStation.deviceId}`,
      })
      this.iotProvider = pubsub;
      let s;
      for (const deviceId of this.state.devices) {
        s = pubsub
          .subscribe({ topics: [`${MQTTEvents.CONNECTED}/${deviceId}`] })
          .subscribe({
            next: (data) => {
              this.handleDeviceConnected(deviceId, data.sessionIdentifier);
              this.mqttPublish(`${MQTTEvents.DEVICE_WIFI_DETAILS}/${deviceId}`, {});
            },
          });
        this.subscriptions.push(s);
        s = pubsub
          .subscribe({ topics: [`${MQTTEvents.DISCONNECTED}/${deviceId}`] })
          .subscribe({
            next: (data) => {
              this.handleDeviceDisconnected(deviceId);
            },
          });
        this.subscriptions.push(s);
        s = pubsub
          .subscribe({ topics: [`${MQTTEvents.DEVICE_CURRENT_WIFI}/${deviceId}`] })
          .subscribe({
            next: (data) => {
              this.handleCurrentWifiDetails(deviceId, data.cws, data.cwp, data.ws, data.s);
            },
          });
        this.subscriptions.push(s);
      }
      s = pubsub
        .subscribe({ topics: [`${MQTTEvents.STATUS_SYNC}/${testingStation.deviceId}`] })
        .subscribe({
          next: data => {
            const payload = data;
            const message = {};
            message.online = payload.devices.map(d => ({ deviceId: d.deviceId, socketId: d.sessionId }));
            message.offline = this.state.devices.filter(d => !message.online.some(od => od.deviceId === d));
            this.handleTestingStationSync(message);
            for (const device of payload.devices) {
              this.mqttPublish(`${MQTTEvents.DEVICE_WIFI_DETAILS}/${device.deviceId}`, {});
            }
          }
        })
      this.subscriptions.push(s);

    } else {
      if (this.socketClient && this.socketClient.connected) {
        this.socketClient.emit(TestingStationEvents.UPDATE_DEVICES, {
          deviceId: testingStation.deviceId,
          deviceIds: this.state.devices,
        });
        setTimeout(() => {
          this.socketClient.emit(TestingStationEvents.SYNC, {
            deviceId: testingStation.deviceId,
          });
        }, 1000);
      } else {
        const socketClient = io(`${baseurl}`, {
          query: {
            deviceId: testingStation.deviceId,
            device_type: "testingStation",
            test_type: TestingType.OnlineOffline,
            deviceIds: this.state.devices,
            name: `${user.first_name} ${user.last_name}`,
            employeeId: user.employeeId,
          },
        });

        socketClient.on("connect", () => {
          setTimeout(() => {
            socketClient.emit(TestingStationEvents.SYNC, {
              deviceId: testingStation.deviceId,
            });
          }, 1000);
        });

        socketClient.on(TestingStationEvents.CONNECTED, (message) => {
          // ask the device for its wifi details
          socketClient.emit(TestingStationEvents.EVENT, {
            deviceId: testingStation.deviceId,
            event: "current_wifi",
            payload: "",
          });
          const { deviceId } = message.query;
          this.handleDeviceConnected(deviceId, message.socketId);
        });

        socketClient.on(TestingStationEvents.EVENT, (message) => {
          const { event, payload } = message;
          const { deviceId } = payload;
          if (event === "current_wifi") {
            // check if the ssid and ws are equal and same ws is there in testing station wifi configs
            // compare strength
            this.handleCurrentWifiDetails(deviceId, payload.cws || payload.ws || payload.ssid, payload.cwp || payload.wp || payload.pasprinls, payload.ssid || payload.ws, payload.strength || payload.rssi);
          }
        });

        socketClient.on(TestingStationEvents.STATUS, (message) => {
          this.handleTestingStationSync(message);
          socketClient.emit(TestingStationEvents.EVENT, {
            deviceId: testingStation.deviceId,
            event: "current_wifi",
            payload: "",
          });
        });

        socketClient.on(TestingStationEvents.DISCONNECTED, (message) => {
          this.handleDeviceDisconnected(message.query.deviceId);
        });
        socketClient.connect();
        this.socketClient = socketClient;
      }
    }

  };

  syncStatus = () => {
    const { testingStationIndex } = this.state;
    const testingStation = this.props.testingStations[testingStationIndex];
    if (this.socketClient && this.socketClient.connected) {
      this.socketClient.emit(TestingStationEvents.SYNC, {
        deviceId: testingStation.deviceId,
      });
    }
    this.mqttPublish(`${MQTTEvents.STATUS_UPDATE}/${testingStation.deviceId}`, { deviceIds: this.state.devices });
  };

  handleAddDevices = async () => {
    if (this.state.testingStarted) {
      showErrorMessage(
        "Illegal Operation",
        "Can not add devices after test has started"
      );
      return;
    }
    const { deviceInput, devices, deviceConnectionData } = this.state;
    const deviceIds = [...new Set(deviceInput.split(",").map((deviceId) => deviceId.trim().replace(/[^0-9a-z]/gi, '')))];
    let newDeviceIds = deviceIds.filter(
      (deviceId) => deviceId.length > 0 && devices.indexOf(deviceId) < 0
    );
    let removeDeviceIds = [];
    let alreadyTestedDevices = [];
    for (const deviceId of newDeviceIds) {
      let { success, message, data } = await TestingActions.details(deviceId);
      if (success) {
        const { device } = data;
        if (device.discarded) {
          removeDeviceIds.push(deviceId);
          showErrorMessage(`Can't add device ${deviceId}`, `${deviceId} has been marked as discarded.`);
        }
        if (device.hardware_tested && !device.online_tested) continue;
        if (!device.hardware_tested) {
          showErrorMessage(`Can't add device ${deviceId}`, `${deviceId} has not passed hardware test yet`);
          removeDeviceIds.push(deviceId);
        }
        if (device.online_tested) {
          alreadyTestedDevices.push(deviceId);
          showInfoMessage(`Device already tested ${deviceId}`, `${deviceId} has already passed online offline test`);
        }
      } else {
        removeDeviceIds.push(deviceId);
        showErrorMessage(`Could not fetch device details ${deviceId}`, message);
      }
    }
    const newDevices = [...new Set(devices.concat(newDeviceIds.filter(deviceId => removeDeviceIds.indexOf(deviceId) < 0)))];
    const newDeviceConnectionData = { ...deviceConnectionData };
    newDevices.forEach((deviceId) => {
      if (!newDeviceConnectionData[deviceId]) {
        newDeviceConnectionData[deviceId] = {
          connected: false,
          connectivity: WifiHealthStatus.Unknown,
          strength: -50,
          ssid: "aliste",
          ws: "aliste",
          wp: "aliste",
          connectionId: "",
          numOfConnections: 0,
          alreadyTested: alreadyTestedDevices.includes(deviceId),
        };
      }
    });
    this.setState(
      {
        devices: newDevices,
        deviceInput: "",
        deviceConnectionData: newDeviceConnectionData,
      },
      () => {
        this.handleConnectAndAddDevice();
      }
    );
  };

  handleStartTesting = () => {
    const isTestingStationConnected =
      (this.socketClient && this.socketClient.connected) || this.iotProvider;
    const allDeviceData = Object.values(this.state.deviceConnectionData);
    const anyDeviceOffline = allDeviceData.some((d) => d.connected !== true);
    // const anyDeviceOnAliste = allDeviceData.some(
    //   (d) => d.connectivity !== WifiHealthStatus.Good
    // );
    if (
      !isTestingStationConnected ||
      this.state.devices.length !== allDeviceData.length
    ) {
      showErrorMessage(
        "Not Connected",
        "Please add some device, and let us connect to the server"
      );
      return;
    }
    if (anyDeviceOffline) {
      showErrorMessage(
        "Devices Offline",
        "All devices added are not online, either bring them online or remove them from the test"
      );
      return;
    }
    // if (anyDeviceOnAliste) {
    //   showErrorMessage(
    //     "Device on Hotspot",
    //     "Some device(s) are not on a wifi configured with the testing station."
    //   );
    //   return;
    // }
    const { deviceConnectionData } = this.state;
    const newDeviceConnectionData = { ...deviceConnectionData };
    Object.keys(newDeviceConnectionData).forEach((deviceId) => {
      newDeviceConnectionData[deviceId] = {
        ...newDeviceConnectionData[deviceId],
        status: OnlineTestingStatus.Testing,
      };
    });
    this.setState({
      testingStarted: true,
      deviceConnectionData: newDeviceConnectionData,
    });
  };

  resetSocketClient = () => {
    if (this.socketClient && this.socketClient.connected) {
      this.socketClient.disconnect();
      this.socketClient = undefined;
    }
    this.resetMQTTConnection();
  }
}

const mapStateToProps = (state) => ({
  testingStations: state.testingStations.list,
  user: state.user.profile,
});

export default connect(mapStateToProps, {
  failed: TestingActions.failed,
  onlinePassed: TestingActions.onlineTestPassed,
})(OnlineOfflineTest);
