import axios from "axios";
import { affilatedProductUrl, cloudUrl } from "../../config/constants";
import { AffilatedProductAction } from "../actionTypes";
import { accessTokenHeader, showSuccessMessage } from "../../utils/helpers";
import store from "../source";
import { showErrorMessage } from "../../utils/helpers";

const loadAffilatedProduct = (data) => {
  return {
    type: AffilatedProductAction.LOAD,
    payload: data,
  };
};
const loadDetailOfSelectedProduct = (data) => {
  return {
    type: AffilatedProductAction.LOAD_SELECTED_PRODUCT,
    payload: data,
  };
};

export const removeSelectedProduct = () => {
  return {
    type: AffilatedProductAction.REMOVE_SELECTED_PRODUCT,
    payload: [],
  };
};
export const historySelectedProduct = (data) => {
  return {
    type: AffilatedProductAction.HISTORY_SELECTED_PRODUCT,
    payload: data,
  };
};

export const getAffilatedProducts = () => {
  axios
    .post(`${cloudUrl}/v3/affiliateProducts/listAll`)
    .then(function (res) {
      if (res.data.success) {
        store.dispatch(loadAffilatedProduct(res.data.data.products));
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
    })
    .catch(function (error) {
      showErrorMessage("", error.response.data.message, 2000);
    });
};

export const getDetailsOfSelectedProduct = async (id) => {
  await axios
    .post(
      `${affilatedProductUrl}/details`,
      {
        product_id: id,
      },
      {
        headers: {
          ...accessTokenHeader(),
        },
      }
    )
    .then(function (res) {
      if (res.data.success) {
        if (Object.keys(res.data.data).length !== 0) {
          let key = Object.keys(res.data.data.product);
          let product = [];
          for (let k of key) {
            if (k === "product_id") {
              continue;
            }

            product.push({ [k]: res.data.data.product[k] });
          }
          store.dispatch(loadDetailOfSelectedProduct(product));
        } else {
          showErrorMessage("", "Product Details not available", 2000);
        }
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
    });
};

export const addSockOfSelectedProduct = (id, stockCount) => {
  axios
    .post(
      `${affilatedProductUrl}/addStock`,
      {
        product_id: id,
        quantity: stockCount,
        meta_data: {},
      },
      {
        headers: {
          ...accessTokenHeader(),
        },
      }
    )
    .then(function (res) {
      if (res.data.success) {
        showSuccessMessage("", res.data.message, 2000);
        getDetailsOfSelectedProduct(id);
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
    });
};

export const transferfSelectedProduct = async (
  id,
  travelcount,
  to,
  from,
  type
) => {
  return await axios
    .post(
      `${affilatedProductUrl}/transfer`,
      {
        product_id: id,
        quantity: travelcount,
        from: from,
        to: to,
        meta_data: {
          totype: type,
        },
      },
      {
        headers: {
          ...accessTokenHeader(),
        },
      }
    )
    .then(async function (res) {
      if (res.data.success) {
        showSuccessMessage("", res.data.message, 2000);
        await getDetailsOfSelectedProduct(id);
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      return false;
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      return false;
    });
};

export const getSelectedProductHistory = async (id) => {
  await axios
    .post(
      `${affilatedProductUrl}/history`,
      {
        product_id: id,
      },
      {
        headers: {
          ...accessTokenHeader(),
        },
      }
    )
    .then(function (res) {
      if (res.data.success) {
        if (Object.keys(res.data.data).length !== 0) {
          if (res.data.data.logs.length === 0) {
            showErrorMessage("", "History Not Available", 2000);
          }
          store.dispatch(historySelectedProduct(res.data.data.logs));
        } else {
          showErrorMessage("", "History Not Available", 2000);
        }
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
    });
};
