import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";
import { setTestingSync, setSyncTestingStation } from "../../redux/actions/currentSyncTest";
import  TestingActions from "../../redux/actions/testing";

const SelectDevice = (props) => {
	const [deviceId, setDeviceId] = useState("H012537");
	const [testingStationIndex, setTestingStationIndex] = useState(5);
	const testingStations = useSelector((state) => state.testingStations.list);
	return (
		<div>
			<TestStepsHeader
				icon={Images.ControlSync.Select}
				title={"Select Device"}
			/>
			<Form.Group>
				<Form.Label>Enter Device ID</Form.Label>
				<Form.Control
					placeholder="Device ID"
					aria-label="Device ID"
					value={deviceId}
					onChange={(e) => {
						setDeviceId(e.target.value.toUpperCase().replace(/[^0-9a-z]/gi, ''));
					}}
				/>
			</Form.Group>
			<br />
			<Form.Group>
				<Form.Label>Select Testing Station</Form.Label>
				<Form.Select
					value={testingStationIndex}
					onChange={(e) => {
						setTestingStationIndex(e.target.value);
					}}
				>
					{testingStations.filter(ts => ts.active).map((ts, index) => (
						<option key={ts._id} value={index}>{ts.name}</option>
					))}
				</Form.Select>
			</Form.Group>
			<br />
			<Button
				title="Register"
				onClick={async () => {
					const { success, data } = await TestingActions.details(deviceId);
					if (success) {
						props.setTestingSync(data.device);
						props.setSyncTestingStation(testingStations[Number(testingStationIndex)]);
						setTimeout(() => {
							// sometimes the function was called before the props update is complete
							// delay of 200ms to wait for prop update
							props.onStepComplete();

						}, 200);

					} else {
						props.setTestingSync(undefined);
					}
				}}
			>
				Continue
			</Button>
		</div>
	);
};

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, { setSyncTestingStation, setTestingSync })(SelectDevice);
