import {
	Store
} from 'react-notifications-component';
import { baseurl, DeviceCodeByType, DeviceTypes, EnergISyncFailedReasons, EnergISyncParameters, EnergISyncTestingConfig, UserRoles } from '../config/constants';
import store from '../redux/source';

export const showMessage = (title = '', message = '', duration = 2000, type = '') => {
	Store.addNotification({
		title,
		message,
		type,
		insert: "top",
		container: "top-right",
		animationIn: ["animate__animated", "animate__fadeIn"],
		animationOut: ["animate__animated", "animate__fadeOut"],
		dismiss: {
			duration,
			onScreen: true
		}
	});
}
export const showInfoMessage = (title = '', message = '', duration = 2000) => {
	showMessage(title, message, duration, 'info');
}

export const showSuccessMessage = (title = '', message = '', duration = 2000) => {
	showMessage(title, message, duration, 'success');
}

export const showErrorMessage = (title = '', message = '', duration = 2000) => {
	showMessage(title, message, duration, 'danger');
}

export const accessTokenHeader = () => ({
	AccessToken: store.getState().user.accessToken,
});


// const ExpectedVoltagePercent = {
// 	0: 0.01,
// 	25: 53.04,
// 	50: 66.52,
// 	75: 89.56,
// 	100: 100,
// };

const ExpectedVoltagePercent = {
	0: 0.01,
	25: 32.75,
	50: 45.51,
	75: 64.88,
	100: 100,
};

const VoltageTolerance = {
	0: 0.25,
	25: 0.25,
	50: 0.25,
	75: 0.25,
	100: 0.25,
};

export const fanTestingCheck = (inputVoltage = 0, speed = 0, voltageReading = 0) => {
	if (speed === 0) return Number(voltageReading) === Number(0);
	const voltPercent = Number(voltageReading * 100 / inputVoltage).toFixed(2);
	let diff = Math.abs(Number((voltPercent - ExpectedVoltagePercent[speed]) / ExpectedVoltagePercent[speed]).toFixed(2))
	return (diff < VoltageTolerance[speed]);
}

export const getProfilePicUrl = (profile_pic) => {
	return `${baseurl}/images/${profile_pic}`;
}

export const userAllowedMenu = (availableFor = [], userRoles = []) => {
	if (availableFor.includes(UserRoles.Any)) {
		return true;
	}
	let available = userRoles.some((role) => availableFor.includes(role));
	return available;
}

export const isESP8266Device = (deviceId = "") => {
	let deviceCode = deviceId.slice(1, 3);
  switch (deviceCode) {
    case '01': 
    case '02': return true;
    default: return false;
  }
}

export const getNumberOfPhases = deviceId => {
	let deviceCode = deviceId.slice(1, 3);
	let mapping = {
		'24': 1,
		'25': 3,
		'28': 1,
		'31': 1,
		'32': 2,
		'33': 3, 
	}
	let phases = mapping[deviceCode] || 1;
	return phases;
}

export const getNumberOfSwitches = (deviceId = "") => {
  let deviceCode = deviceId.slice(1, 3);
	if (deviceId.slice(0, 3) === 'H03') {
		return 3;
	}
  switch (deviceCode) {
		case '01': return 1;
		case '02': return 2;
		case '03': return 4;
		case '04': return 5;
		case '05': return 5;
		case '12': return 3;
		case '13': return 4;
		case '27': return 3;
		case '29': return 3;
    default: return 4;
  }
}

export const pad0 = (number = 0, totalLength = 4) => {	
	if (isNaN(number)) {
		return number;
	}
	let num = String(Number(number));
	let extra0s = totalLength - num.length;
	return `${new Array(extra0s).fill(0).join('')}${num}`
}

export const getDeviceType = deviceId => {
	let deviceCode = deviceId.slice(1, 3);
	for (const deviceType of Object.values(DeviceTypes)) {
		if (DeviceCodeByType[deviceType].includes(deviceCode)) {
			return deviceType;
		}
	}
	return DeviceTypes.Invalid;
}

export const energiSyncTestingStatus = (deviceId, sentMessages) => {
	let passed = true;
	let failedReason = EnergISyncFailedReasons.NotApplicable;
	let failedReading = {};
	for (const message of sentMessages.filter(d => d.deviceId === deviceId)) {
		if (!message?.device_readings) {
			passed = false;
			failedReason = EnergISyncFailedReasons.DeviceReadingsNotFound
			failedReading = { ...message };
			break;
		} else if (!message?.tester_readings) {
			passed = false;
			failedReason = EnergISyncFailedReasons.TesterReadingsNotFound;
			failedReading = { ...message };
			break;
		} else {
			let range = EnergISyncTestingConfig.Range[message.command];
			const { device_readings, tester_readings } = message;
			if (!(device_readings.p >= range.minimumWattage && device_readings.p <= range.maximumWattage)) {
				passed = false;
				failedReason = EnergISyncFailedReasons.ReadingsOutOfRange;
				failedReading = { ...message };
				break;
			}
			if (!(tester_readings.p >= range.minimumWattage && tester_readings.p <= range.maximumWattage)) {
				passed = false;
				failedReason = EnergISyncFailedReasons.TesterReadingsOutOfRnage;
				failedReading = { ...message };
				break;
			}
			for (const param of Object.values(EnergISyncParameters)) {
				let reading = device_readings[param];
				let expected = tester_readings[param];
				let diff = parseInt(Math.abs(reading - expected))
				if (param !== EnergISyncParameters.POWER_FACTOR) {
					if (diff < 1) {
						diff = 0;
					}
				}
				let error = diff * 100 / expected;
				if (error > EnergISyncTestingConfig.Tolerance[param]) {
					passed = false;
					failedReason = EnergISyncFailedReasons.InaccurateReading[param];
					failedReading = { ...message };
					break;
				}
			}
		}
	}
	return { passed, failedReason, failedReading }
}