import axios from "axios";
import { baseurl } from "../../config/constants";
import { accessTokenHeader } from "../../utils/helpers";
import { TestingStationActions } from "../actionTypes";

const load = (testingStations = []) => ({
	type: TestingStationActions.LOAD,
	payload: testingStations,
});

const loading = (isloading = false) => ({
	type: TestingStationActions.LOADING,
	payload: isloading
})

const testingStationUsed = (index) => ({
	type: TestingStationActions.USED,
	payload: index,
});

export const updateStationUsed = (index) => dispatch => {
	dispatch(testingStationUsed(index))
}

export const getAllTestingStations = () => dispatch => {
	return new Promise(resolve => {
		dispatch(loading(true));
		axios.get(`${baseurl}/testingStation/list`, {
			headers: {
				...accessTokenHeader(),
			}
		})
		.then(result => {
			dispatch(loading(false));
			const {success, data, message} = result.data;
			if (success) {
				dispatch(load(data.testingStations));
			}
			resolve({success, data, message});
		}, error => {
			const message = error.response.data ? error.response.data.message : String(error);
			resolve({success: false, message});
		})
		.catch(error => {
			resolve({success: false, message: String(error)});
		})
	})
}