import axios from "axios";
import { baseurl } from "../../config/constants";
import { accessTokenHeader } from "../../utils/helpers";
import { showErrorMessage, showSuccessMessage } from "../../utils/helpers";
import { PcbVersionAction } from "../actionTypes";
import store from "../source";

const loadPcbData = (data) => {
  return {
    type: PcbVersionAction.LOAD,
    payload: data,
  };
};
const updatePcbData = (data) => {
  return {
    type: PcbVersionAction.UPDATE,
    payload: data,
  };
};
const removePcbData = (data) => {
  return {
    type: PcbVersionAction.REMOVE,
    payload: data,
  };
};

export const add = async (details, setSubmitting, setShow) => {
  await axios
    .post(`${baseurl}/pcbVersion/create`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        getPcbVersion();
        setShow(false);
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const update = async (details, setSubmitting, setShow) => {
  await axios
    .post(
      `${baseurl}/pcbVersion/update`,
      { ...details, active: details.active === "false" ? false : true },
      {
        headers: {
          ...accessTokenHeader(),
        },
      }
    )
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        store.dispatch(updatePcbData(res.data.data.version));
        setShow(false);
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const toggle = async (details) => {
  await axios
    .post(`${baseurl}/pcbVersion/toggle`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        store.dispatch(updatePcbData(res.data.data.version));
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
    });
};
export const remove = async (details, setSubmitting, setShow) => {
  await axios
    .post(`${baseurl}/pcbVersion/delete`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        setShow(false);
        store.dispatch(removePcbData(res.data.data.version));
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const getPcbVersion = (Notification = true) => {
  axios
    .get(`${baseurl}/pcbVersion/get`, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        Notification && showSuccessMessage("", res.data.message, 2000);
        store.dispatch(loadPcbData(res.data.data.version));
      } else {
        Notification && showErrorMessage("", res.data.message, 2000);
      }
    })
    .catch(function (err) {
      Notification && showErrorMessage("", err.response.data.message, 2000);
    });
};
