import React from 'react';
import { Form, ProgressBar } from 'react-bootstrap';
import TestStepsHeader from '../../components/TestStepsHeader';
import Images from '../../config/images';
const SwitchPin = ({switchPinScriptProgress}) => {
	return (
		<div>
			<TestStepsHeader
        icon={Images.HardwareTesting.Switch}
        title={"Switch Testing"}
      />
			<br />
			<ProgressBar animated striped now={switchPinScriptProgress.progress} />
			<br />
			<Form.Label>
				Automatic switch pin test in progress, sit back, relax and enjoy.
				<br />
				But keep an open eye.
			</Form.Label>
		</div>
	)
}

export default SwitchPin;