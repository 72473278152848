import axios from "axios";
import { accessTokenHeader } from "./helpers";
import { mqttDeviceUrl, socketurl } from "../config/constants";

const publishMQTTMessage = async (
  details = {
    deviceId: "S031577",
    event: "wifi_details",
    payload: "",
  }
) => {
  return axios
    .post(
      `${"https://keiozfbox5.execute-api.ap-south-1.amazonaws.com/default"}/publishMessage`,
      details,
      {
        headers: {
          ...accessTokenHeader(),
        },
      }
    )
    .then(
      (response) => {
        return response.data;
      },
      (error) => {
        const message = error.response.data
          ? error.response.data.message
          : String(error);

        return {
          success: false,
          message,
          data: error?.response?.data?.data,
        };
      }
    )
    .catch((error) => {
      return {
        success: false,
        message: String(error),
      };
    });
};

const a2DeviceOnline = deviceId => {
  return new Promise(resolve => {
    axios
      .get(`${socketurl}/v2/admin/deviceOnline/${deviceId}`)
      .then(
        result => {
          resolve({
            success: true,
            message: 'Status',
            data: {isOnline: result.data.status === 'online'},
          });
        },
        error => {
          let message = String(error.message);
          if (error.response && error.response.data) {
            message = error.response.data.message;
          }
          resolve({success: false, message});
        },
      )
      .catch(error => {
        resolve({success: false, message: String(error)});
      });
  });
};

const mqttDeviceOnline = deviceId => {
  return new Promise(resolve => {
    axios
      .post(`${mqttDeviceUrl}/connectionStatus`, {deviceId})
      .then(
        result => {
          const {success, message, data} = result.data;
          resolve({
            success,
            message,
            data: {isOnline: data.connected === true ? true : false},
          });
        },
        error => {
          let message = String(error.message);
          if (error.response && error.response.data) {
            message = error.response.data.message;
          }
          resolve({success: false, message});
        },
      )
      .catch(error => {
        resolve({success: false, message: String(error)});
      });
  });
};

const isDeviceOnline = async deviceId => {
  const a2Response = await a2DeviceOnline(deviceId);
  if (a2Response?.data?.isOnline === true) {
    return a2Response;
  }
  const mqttResponse = await mqttDeviceOnline(deviceId);
  return mqttResponse;
};

const APIs = {
  publishMQTTMessage,
  isDeviceOnline,
};

export default APIs;
