import React, { Component } from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import TestStepsHeader from "../components/TestStepsHeader";
import Images from "../config/images";
import { generateBulkOTA, getDeviceTypesList, getOTAStatus } from "../redux/actions/deviceTypesTrack";
import { pad0, showErrorMessage, showInfoMessage, showSuccessMessage } from "../utils/helpers";

class BulkOTAPage extends Component {
	state = {
		deviceTypes: [],
		start: 0,
		end: 0,
		deviceCode: "",
		check: 0,
		checkMessage: '',
		checkStatus: false,
	};

	componentDidMount() {
		this.loadDeviceTypes();
	}

	loadDeviceTypes = async () => {
		let response = await getDeviceTypesList();
		if (response.success) {
			let sortedDeviceTypes = response.data.deviceTypes.filter(d => d.ota_endpoint !== '' && d.active === true);
			sortedDeviceTypes.sort((d1, d2) => {
				return (new Date(d2.lastAssignedDate).getTime() - new Date(d1.lastAssignedDate).getTime())
			})
			this.setState({ deviceTypes: sortedDeviceTypes, deviceCode: sortedDeviceTypes[0]?.deviceCode });
		}
	};

	render() {
		const { start, end, deviceTypes, check, checkMessage, checkStatus } = this.state;
		return (
			<Row className={"g-0 workAreaDiv invisibleScrollbar"}>
				<Col lg={6}>
					<div className="section_container">
						<TestStepsHeader
							icon={Images.MenuImages.BulkOTA}
							title={"Bulk OTAs"}
						/>
						<Form.Group>
							<Row>
								<Col>
									<Form.Text>Choose device type</Form.Text>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Select aria-label="Choose device type" onChange={this.handleDeviceTypeChanged}>
										{deviceTypes.map((type) => {
											return (<option value={type.deviceCode}>{type.deviceCode} - {type.name} - {type.lastAssignedId} </option>)
										}
										)}
									</Form.Select>
								</Col>
							</Row>
							<br />
							<Row>
								<Col>
									<Form.Text>Start ID </Form.Text>
								</Col>
								<Col>
									<Form.Text>End ID </Form.Text>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Control
										value={start}
										type={'number'}
										onChange={this.handleStartChanged}
										placeholder={"Start ID"}
									/>
								</Col>
								<Col>
									<Form.Control
										value={end}
										type={'number'}
										onChange={this.handleEndChanged}
										placeholder={"Start ID"}
									/>
								</Col>
							</Row>
						</Form.Group>
						<br />
						<Button onClick={this.handleStartGenerateOTAs}>Start OTA generation</Button>
						<br />
						<Row></Row>
					</div>
				</Col>
				<Col lg={6}>
					<div className="section_container">
						<TestStepsHeader
							icon={Images.MenuImages.BulkOTA}
							title={"Check OTA"}
						/>
						<Form.Group>
							<Row>
								<Col>
									<Form.Text>Choose device type</Form.Text>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Select aria-label="Choose device type" onChange={this.handleDeviceTypeChanged}>
										{deviceTypes.map((type) => {
											return (<option value={type.deviceCode}>{type.deviceCode} - {type.name} - {type.lastAssignedId} </option>)
										}
										)}
									</Form.Select>
								</Col>
							</Row>
							<br />
							<Row>
								<Col>
									<Form.Text>Device ID </Form.Text>
								</Col>
							</Row>
							<Row>
								<Col>
									<Form.Control
										value={check}
										type={'number'}
										onChange={this.handleCheckIdChanged}
										placeholder={"Device ID"}
									/>
								</Col>
							</Row>
						</Form.Group>
						<br />
						<Button onClick={this.handleCheckDeviceId}>Check Device Id</Button>
						<br />
						<br />
						<Row>
							{checkMessage.length > 0 && (<h4>
								<Badge bg={checkStatus ? "success" : "danger"}>
									{checkMessage}
								</Badge>
							</h4>)}
						</Row>
					</div>
				</Col>
			</Row>
		);
	}

	handleCheckIdChanged = (e) => {
		this.setState({ check: Number(e.target.value) });
	}

	handleStartChanged = (e) => {
		this.setState({ start: Number(e.target.value) });
	};

	handleEndChanged = (e) => {
		this.setState({ end: Number(e.target.value) });
	};

	handleDeviceTypeChanged = (e) => {
		this.setState({ deviceCode: e.target.value });
	}

	handleCheckDeviceId = async () => {
		if (this.state.fetching) return;
		this.setState({ fetching: true, checkMessage: '' });
		const { deviceCode, check } = this.state;
		const deviceType = this.state.deviceTypes.find(dt => dt.deviceCode === deviceCode);
		let binLoc = deviceType.binLoc;
		const { success, message } = await getOTAStatus(
			{
				binLoc,
				deviceId: `${deviceCode}${pad0(Number(check))}`,
			}
		);
		this.setState({ checkMessage: message, checkStatus: success, fetching: false }, () => {
			showInfoMessage("OTA Status", message);
		});
	};

	handleStartGenerateOTAs = async () => {
		if (this.state.fetching) return;
		this.setState({ fetching: true });
		const { deviceCode, start, end } = this.state;
		const { success, message, data } = await generateBulkOTA(
			{
				deviceCode,
				start: pad0(Number(start)),
				end: pad0(Number(end))
			}
		);
		this.setState({ status: data.device, fetching: false }, () => {
			if (!success) {
				showErrorMessage("Could not get device testing status", message);
			} else {
				showSuccessMessage("Request send successfully", message);
			}
		});	
	};
}

export default BulkOTAPage;
