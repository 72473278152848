import React, { useState } from 'react'
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { searchDispatch } from '../../redux/actions/search';
import History from '../../components/History';
import { loadHistory } from '../../redux/actions/history';
import { useDispatch } from 'react-redux';
import { showErrorMessage } from '../../utils/helpers';
import Images from '../../config/images';
import TestStepsHeader from '../../components/TestStepsHeader';
import { MovementTransactionsType } from '../../config/constants';
import moment from 'moment';

function SearchDispatch() {
  const [searchValue, setSearchValue] = useState("")
  const [devices, setDevices] = useState([])
  const [selectedDeviceId, setSelectedDeviceId] = useState("")
  const [btn, setBtn] = useState("Get Details")

  const dispatch = useDispatch()
  const handleDeviceSelected = (deviceId) => {
    if (deviceId === selectedDeviceId) return;
    dispatch(loadHistory(deviceId))
    setSelectedDeviceId(deviceId)

  }

  return (
    <div>

      <Row style={{ margin: 0 }}>
        <Col lg={3} className="section_container p-4">
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Enter Site Name</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  placeholder={"Enter Site Name"}
                />
              </Col>
            </Row>
            <br />
          </Form.Group>
          <Button onClick={() => {
            if (searchValue.length >= 3) {
              setBtn("Processing...")
              searchDispatch(searchValue).then(function (device) {
                setDevices(device)
                setBtn("Get Details")
              }).catch(function (err) {
                setDevices([])
                setBtn("Get Details")
              })
            } else {
              showErrorMessage("Search", "Search Value must be 3 or more characters")
            }
          }}>{btn}</Button>
        </Col>
        <Col lg={4} className='p-0'>
          <div
            className="section_container workAreaDiv invisibleScrollbar"
            style={{
              borderLeft: "0.5px solid black",
              borderRight: "0.5px solid black",
            }}
          >
            <TestStepsHeader
              icon={Images.MenuImages.SearchDispatch}
              title={"Devices"}
            />
            <div style={{ maxHeight: "90vh", overflowY: "scroll" }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Device Id</th>
                    <th>Type</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    devices.map((d, i) => {

                      let type = Object.keys(MovementTransactionsType).filter((ke) => {
                        if (MovementTransactionsType[ke] === d.last_transaction.type) {
                          return ke
                        }
                        return false;
                      })
                      return <tr
                        onClick={() => handleDeviceSelected(d.deviceId)}
                        style={{ border: d.deviceId === selectedDeviceId ? '2px solid blue' : "" }}
                      >
                        <td>{i + 1}</td>
                        <td>{d.deviceId}</td>
                        <td>{type}</td>
                        <td>{moment(d.last_transaction.date).format('DD-MM-YYYY')}</td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
        <Col lg={5} className='p-0'>
          <div
            className="section_container workAreaDiv invisibleScrollbar"
          >
            <TestStepsHeader
              icon={Images.MenuImages.SearchDispatch}
              title={"History"}
            />
            <History deviceId={selectedDeviceId} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SearchDispatch