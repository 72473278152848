import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Plus } from "react-bootstrap-icons";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { showErrorMessage } from "../utils/helpers";
import { PcbTypes } from "../config/constants";

function AddNewPcbType({ data, text, fun }) {
    const [show, setShow] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);
    let [initialValues, setInitialValues] = useState(data);
    useEffect(() => {
        setInitialValues(data);
    }, [data]);
    const handleSubmit = () => {
        const { name, code, description, type } = initialValues;
        if (!name || !code || !description || !type) {
            showErrorMessage("", "Fields are required", 2000);
        } else {
            setSubmitting(true);
            fun(initialValues, setSubmitting, setShow);
        }
    };
    const handleChange = (event) => {
        setInitialValues({
            ...initialValues,
            [event.target.name]: event.target.value,
        });
    };
    return (
        <>
            <>
                <Button
                    variant="primary"
                    size="sm"
                    // style={{ marginRight: "33px" }}
                    onClick={() => setShow(true)}
                >
                    {text === "Add New" ? (
                        <Plus style={{ fontSize: "16px" }} />
                    ) : null}
                    {text}
                </Button>
            </>
            <Modal
                show={show}
                onHide={() => {
                    setShow(false);
                    setInitialValues(data);
                    setSubmitting(false);
                }}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {text}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationFormik01"
                                className="mb-3"
                            >
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={initialValues.name}
                                    onChange={handleChange}
                                    placeholder="PCB Name"
                                />
                            </Form.Group>
                            <Form.Group
                                as={Col}
                                md="6"
                                controlId="validationFormik02"
                                className="mb-3"
                            >
                                <Form.Label>Code</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        name="code"
                                        value={initialValues.code}
                                        onChange={handleChange}
                                        placeholder="Code"
                                    />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Label>Type</Form.Label>
                                <Form.Select
                                    defaultValue={initialValues.type}
                                    name="type"
                                    onChange={handleChange}
                                >
                                    {PcbTypes &&
                                        PcbTypes.map((type, i) => (
                                            <option value={type.value}>
                                                {type.value}
                                            </option>
                                        ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="6" className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        rows={3}
                                        placeholder="Description"
                                        aria-describedby="inputGroupPrepend"
                                        name="description"
                                        value={initialValues.description}
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </Row>

                        <div className="d-flex flex-row-reverse gap-3">
                            <Button
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                                variant="success"
                            >
                                Submit
                            </Button>
                            <Button
                                onClick={() => setShow(false)}
                                variant="danger"
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddNewPcbType;
