import React, { useRef, useState } from "react";
import { Badge, Button, Col, Form, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";
import { getNumberOfPhases } from "../../utils/helpers";

const SetupTesting = ({ devices, onStepComplete, addDevice, removeDevice, connectionStatus, syncStatus }) => {
  const [deviceId, setDeviceId] = useState('');
  const testingStations = useSelector(state => state.testingStations.list);
  const filteredStations = testingStations.filter(ts => ts.active && ts.energisync_tester && !devices.some(d => String(d.testingStation._id) === String(ts._id)) && getNumberOfPhases(deviceId) <= getNumberOfPhases(ts.energisync_tester));
  const [testingStation, setTestingStation] = useState('');
  const deviceIdRef = useRef(null)
  return (
    <div>
      <TestStepsHeader
        icon={Images.EnergISyncHardwareTesting.Setup}
        title={"Setup Testing"}
      />
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Device ID</th>
            <th>Testing Station</th>
            <th>Control Meter</th>
            <th>Device Status</th>
            <th>Master Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device, index) => {
            const deviceConnected = connectionStatus[device.deviceId]?.connected;
            const testerConnected = connectionStatus[device.testingStation.energisync_tester]?.connected;
            return (
              <tr key={device.deviceId}>
                <td> {index + 1} </td>
                <td>{device.deviceId}</td>
                <td>{device.testingStation.name}</td>
                <td>{device.testingStation.energisync_tester}</td>
                <td>
                  <h5>
                    <Badge bg={deviceConnected ? "success" : "danger"}>
                      {deviceConnected ? "Online" : "Offline"}
                    </Badge>
                  </h5>
                </td>
                <td>
                  <h5>
                    <Badge bg={testerConnected ? "success" : "danger"}>
                      {testerConnected ? "Online" : "Offline"}
                    </Badge>
                  </h5>
                </td>
                <td>
                  <Button
                    variant={'danger'}
                    title='Remove'
                    size={'sm'}
                    onClick={() => {
                      removeDevice({ deviceId: device.deviceId })
                    }}>
                    Remove
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Enter Device ID</Form.Label>
            <Form.Control
              ref={deviceIdRef}
              placeholder="Device ID"
              aria-label="Device ID"
              value={deviceId}
              onChange={(e) => {
                setDeviceId(
                  e.target.value.toUpperCase().replace(/[^0-9a-z]/gi, "")
                );
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Select Testing Station</Form.Label>
            <Form.Select
              value={testingStation}
              onChange={(e) => {
                setTestingStation(e.target.value);
              }}
            >
              <option key={''} value={''}>
                Default
              </option>
              {filteredStations.map((ts, index) => (
                <option key={ts._id} value={ts._id}>
                  {ts.name} - {ts.energisync_tester}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <Button
            title="Add Device"
            onClick={async () => {
              addDevice({
                deviceId,
                testingStation: testingStations.find(ts => ts._id === testingStation),
              })
              setDeviceId('');
              setTestingStation('');
              deviceIdRef?.current?.focus();
            }}
          >
            Add Device
          </Button>
        </Col>
        <Col>
          <Button onClick={onStepComplete}>
            Start Testing
          </Button>
        </Col>
      </Row>



    </div>
  );
};


export default SetupTesting;
