import React, { useState } from "react";
import { Badge, Button, Col, Form, Row, Table } from "react-bootstrap";
import TestStepsHeader from "../../components/TestStepsHeader";
import { FanModuleSwitchState } from "../../config/constants";
import Images from "../../config/images";
import "../../styles/Timer.css";

const RegulatorTestCommands = [
  {
    label: "Module",
    setting: { switchState: FanModuleSwitchState.MODULE, command: 100 },
    expectedData: [5, 0, 0, 5],
    errorRate: [2, 0, 0, 2],
  },
  {
    label: "Manual",
    setting: { switchState: FanModuleSwitchState.MANUAL, command: 100 },
    expectedData: [0, 0, 0, 0],
    errorRate: [0, 0, 0, 2],
  },
  {
    label: "100",
    setting: { switchState: FanModuleSwitchState.MODULE, command: 100 },
    expectedData: [5, 0, 0, 5],
    errorRate: [2, 0, 0, 2],
  },
  {
    label: "75",
    setting: { switchState: FanModuleSwitchState.MODULE, command: 75 },
    expectedData: [5, 5, 5, 0],
    errorRate: [2, 2, 2, 0],
  },
  {
    label: "50",
    setting: { switchState: FanModuleSwitchState.MODULE, command: 50 },
    expectedData: [5, 0, 5, 0],
    errorRate: [2, 0, 2, 0],
  },
  {
    label: "25",
    setting: { switchState: FanModuleSwitchState.MODULE, command: 25 },
    expectedData: [5, 5, 0, 0],
    errorRate: [2, 2, 0, 0],
  },
  {
    label: "0",
    setting: { switchState: FanModuleSwitchState.MODULE, command: 0 },
    expectedData: [5, 0, 0, 0],
    errorRate: [2, 0, 0, 0],
  },
]
const initalData = [
  [5, 0, 0, 5],
  [0, 0, 0, 0],
  [5, 0, 0, 5],
  [5, 5, 5, 0],
  [5, 0, 5, 0],
  [5, 5, 0, 0],
  [5, 0, 0, 0],
]

const BadgeTexts = {
  Check: "Check to get status",
  Passed: "All values are within range",
  Failed: "Some values are not valid"
}

const BadgeStatus = {
  Check: 0,
  Passed: 1,
  Failed: 2,
};

const Regulator = ({ onStepComplete, sendFanCommand, setValues }) => {
  const [data, setData] = useState(initalData);
  const [checkStatus, setCheckStatus] = useState(BadgeStatus.Check);
  const [badgeText, setBadgeText] = useState(BadgeTexts.Check)
  return (
    <div>
      <TestStepsHeader
        icon={Images.HardwareTesting.Regulator}
        title={"Regulator Testing"}
      />
      <br />
      <Form.Label>
        Set the fan regulator module to different settings and observe the readings
      </Form.Label>
      <div className="rounded elevated" style={{ padding: 0, margin: 0 }}>
        <Table striped>
          <thead>
            <tr>
              <td>Command</td>
              <td>D8</td>
              <td>Pin 1</td>
              <td>Pin 2</td>
              <td>Pin 3</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {RegulatorTestCommands.map((command, index) => {
              let isPassed = true;
              for (let i = 0; i < 4; i++) {
                let value = Number(data[index][i]);
                let expected = command.expectedData[i];
                let acceptedError = command.errorRate[i];
                let error = Number(Math.abs(value - expected) * 100.0 / expected)
                if (error > acceptedError) {
                  isPassed = false;
                  break;
                }
              }
              return (
                <tr key={String(index)}>
                  <td>
                    <Button
                      size={'sm'}
                      onClick={() => {
                          sendFanCommand(command.setting.switchState, command.setting.command);
                      }}>
                      {command.label}
                    </Button>
                  </td>
                  {[0, 1, 2, 3].map(value => {
                    return (
                      <td key={String(value)}>
                        <Form.Control
                          value={data[index][value]}
                          onChange={(e) => {
                            let newData = [...data];
                            newData[index] = [...data[index]];
                            newData[index][value] = e.target.value;
                            setData(newData);
                          }}
                        />
                      </td>
                    )
                  })}
                  <td>
                    <h5>
                      <Badge bg={isPassed ? "success" : "danger"}>
                        {isPassed ? "Pass" : "Fail"}
                      </Badge>
                    </h5>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
      <Row>
        <Col>
          <h4>
            <Badge
              bg={
                checkStatus === BadgeStatus.Check
                  ? "secondary"
                  : checkStatus === BadgeStatus.Passed
                    ? "success"
                    : "danger"
              }
            >
              {badgeText}
            </Badge>
          </h4>
        </Col>
      </Row>
      <Row>
        <Col lg={6} />
        <Col lg={3}>
          <Button onClick={() => {
            let passed = true;
            RegulatorTestCommands.forEach((test, index) => {
              let expectedData = test.expectedData;
              let reading = data[index];
              let testFailed = expectedData.some((e, index) => Number(reading[index]) !== Number(e));
              if (testFailed) {
                passed = false;
              }
            })
            setValues(data);
            if (passed) {
              setCheckStatus(BadgeStatus.Passed);
              setBadgeText(BadgeTexts.Passed);
            } else {
              setCheckStatus(BadgeStatus.Failed);
              setBadgeText(BadgeTexts.Failed);
            }
          }}>Check</Button>
        </Col>
        <Col lg={3}>
          <Button onClick={onStepComplete} active={checkStatus} disabled={!checkStatus}>Next</Button>
        </Col>
      </Row>

      <br />
    </div>
  );
};

export default Regulator;
