import axios from "axios";
import {
	baseurl
} from "../../config/constants";
import {
	accessTokenHeader
} from "../../utils/helpers";

export const smpsTestingLog = (details = {
	testing_station: '',
	meta_data: {},
	testing_date: ''
}) => {
	return new Promise((resolve) => {
		axios
			.post(`${baseurl}/smpsTestingLog/log`,
				details, {
					headers: {
						...accessTokenHeader(),
					},
				})
			.then(
				(result) => {
					const {
						success,
						message,
						data
					} = result.data;
					resolve({
						success,
						message,
						data
					});
				},
				(error) => {
					const message = error.response.data ?
						error.response.data.message :
						String(error);
					resolve({
						success: false,
						message
					});
				}
			)
			.catch((error) => {
				resolve({
					success: false,
					message: String(error)
				});
			});
	});
}