import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";
import { useSelector } from "react-redux";
import { add } from "../../redux/actions/deviceRegister";
import { showErrorMessage } from "../../utils/helpers";
import { getPcbVersion } from "../../redux/actions/pcbVersion";
import { getAssembler } from "../../redux/actions/assembler";

function DeviceRegiser() {
  let [deviceDetail, setDeviceDetail] = useState({
    deviceId: "",
    basePcbVersion: "",
    basePcbOrderNumber: "",
    basePcbAssembler: "",
    espPcbVersion: "",
    espPcbOrderNumber: "",
    espPcbAssembler: "",
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const pcbVersion = useSelector((state) => state.pcbVersion);
  const assembler = useSelector((state) => state.assembler);

  useEffect(() => {
    getPcbVersion();
    getAssembler();
  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (
      deviceDetail.deviceId === "" ||
      deviceDetail.basePcbVersion === "" ||
      deviceDetail.basePcbOrderNumber === "" ||
      deviceDetail.basePcbAssembler === "" ||
      deviceDetail.espPcbVersion === "" ||
      deviceDetail.espPcbOrderNumber === "" ||
      deviceDetail.espPcbAssembler === ""
    ) {
      showErrorMessage("Mandary to fill", "Please Fill All Fields ");
      return;
    }

    add(deviceDetail, setSubmitting);
  };
  const handleChange = (event) => {
    setDeviceDetail({
      ...deviceDetail,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Row>
      <Col md={6} className="rounded elevated m-4">
        <Row>
          <Col>
            <TestStepsHeader
              icon={Images.MenuImages.DeviceRegister}
              title={"Device Register"}
            />
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Device ID</Form.Label>
                <Form.Control
                  type={"default"}
                  name="deviceId"
                  defaultValue={deviceDetail.deviceId}
                  onChange={(e) => handleChange(e)}
                  placeholder={"Enter Device Id"}
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Base PCB Version</Form.Label>
                <Form.Select
                  type={"default"}
                  name="basePcbVersion"
                  defaultValue={deviceDetail.basePcbVersion}
                  onChange={(e) => handleChange(e)}
                  placeholder={"Select Base Pcb Version"}
                >
                  <option>Choose One</option>
                  {pcbVersion
                    .filter((v) => v.pcbTypeId.type === "Base")
                    .map((version) => {
                      return (
                        <option key={version._id} value={version._id}>
                          {version.versionName}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Base PCP Order Number</Form.Label>
                <Form.Control
                  type={"default"}
                  name="basePcbOrderNumber"
                  defaultValue={deviceDetail.basePcbOrderNumber}
                  onChange={(e) => handleChange(e)}
                  placeholder={"Enter Base Pcb Order Number"}
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Base PCB Assembler</Form.Label>
                <Form.Select
                  type={"default"}
                  name="basePcbAssembler"
                  defaultValue={deviceDetail.basePcbAssembler}
                  onChange={(e) => handleChange(e)}
                  placeholder={"Select Base Pcb Assembler"}
                >
                  <option>Choose One</option>
                  {assembler.map((assem) => {
                    return (
                      <option key={assem._id} value={assem._id}>
                        {assem.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>ESP PCB Version</Form.Label>
                <Form.Select
                  type={"default"}
                  name="espPcbVersion"
                  defaultValue={deviceDetail.espPcbVersion}
                  onChange={(e) => handleChange(e)}
                  placeholder={"Select ESP Pcb Version"}
                >
                  <option>Choose One</option>
                  {pcbVersion
                    .filter((v) => v.pcbTypeId.type === "ESP")
                    .map((version) => {
                      return (
                        <option key={version._id} value={version._id}>
                          {version.versionName}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>ESP PCB Assembler</Form.Label>
                <Form.Select
                  type={"default"}
                  name="espPcbAssembler"
                  defaultValue={deviceDetail.espPcbAssembler}
                  onChange={(e) => handleChange(e)}
                  placeholder={"Select ESP Pcb Assembler"}
                >
                  <option>Choose One</option>
                  {assembler.map((assem) => {
                    return (
                      <option key={assem._id} value={assem._id}>
                        {assem.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>ESP PCP Order Number</Form.Label>
                <Form.Control
                  type={"default"}
                  name="espPcbOrderNumber"
                  defaultValue={deviceDetail.espPcbOrderNumber}
                  onChange={(e) => handleChange(e)}
                  placeholder={"Enter ESP Pcb Order Number"}
                />
              </Form.Group>
            </Col>
          </Row>
          <br />

          <Row>
            <Col>
              <Button
                type="button"
                className="float-end m-2"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Register
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

export default DeviceRegiser;
