import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { toggle } from '../redux/actions/pcbVersion';
import Toggle from 'react-toggle';
import "react-toggle/style.css"

function PcbVersionToggle({ data }) {
    let [toggleActive, setToggleActive] = useState(data.active)
    useEffect(() => {
        setToggleActive(data.active)
    }, [data])

    const onToggle = () => {
        setToggleActive(!toggleActive);
        toggle(data)
    }
    return (
        <>
            <Toggle
                defaultChecked={toggleActive}
                onChange={onToggle} />
        </>
    )
}

export default PcbVersionToggle