import { baseurl } from "../../config/constants";
import { accessTokenHeader } from "../../utils/helpers";
import { UserActions, UserList } from "../actionTypes";

const axios = require("axios").default;

const loadProfile = (profile) => ({
  type: UserActions.LOAD_PROFILE,
  payload: profile,
});

const loginUser = (accessToken = "") => ({
  type: UserActions.LOGIN,
  payload: { accessToken },
});

const logoutUser = () => ({
  type: UserActions.LOGOUT,
});

const userRoleListAction = (data) => ({
  type: UserList.USER_ROLE_LIST,
  payload: data,
});

const userRoleListRmoveAction = (data) => ({
  type: UserList.USER_ROLE_LIST_REMOVE,
  payload: data,
});

const loadChartsToken = (token) => ({
  type: UserActions.LOAD_CHARTS_TOKEN,
  payload: token,
});

export const getChartsToken = () => (dispatch) => {
  return axios
    .get(`${baseurl}/users/chartsToken`, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(
      (result) => {
        const { success, message, data } = result.data;
        if (success) {
          dispatch(loadChartsToken(data.mongoToken));
        }
        return { success, message, data };
      },
      (error) => {
        const message = error.response.data
          ? error.response.data.message
          : String(error);
        return { success: false, message };
      }
    )
    .catch((error) => {
      return { success: false, message: String(error) };
    });
};

export const login = (username, password) => (dispatch) => {
  return new Promise((resolve) => {
    axios
      .post(`${baseurl}/users/login`, {
        employeeId: username,
        password,
      })
      .then(
        (result) => {
          const { success, message, data } = result.data;
          if (success) {
            dispatch(loginUser(data.token));
            dispatch(loadProfile(data.user));
          }
          resolve({ success, message, data });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({ success: false, message });
        }
      )
      .catch((error) => {
        resolve({ success: false, message: String(error) });
      });
  });
};

export const logout = () => (dispatch) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${baseurl}/users/logout`,
        {},
        {
          headers: {
            ...accessTokenHeader(),
          },
        }
      )
      .then(
        (result) => {
          const { success, message, data } = result.data;
          resolve({ success, message, data });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({ success: false, message });
        }
      )
      .catch((error) => {
        resolve({ success: false, message: String(error) });
      })
      .finally(() => {
        dispatch(logoutUser());
      });
  });
};

export const userRoleList = () => (dispatch) => {
  axios
    .get(`${baseurl}/misc/userRolesList`)
    .then((result) => {
      const { data } = result.data;
      dispatch(userRoleListAction(Object.values(data.roles)));
    })
    .catch(function (err) {});
};

export const userRoleListRemove = (role) => (dispatch) => {
  dispatch(userRoleListRmoveAction(role));
};
