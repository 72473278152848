import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Plus } from "react-bootstrap-icons"
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { showErrorMessage } from '../utils/helpers';

function AssemblerForm({ data, text, fun }) {
    const [show, setShow] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false)
    let [initialValues, setInitialValues] = useState(data)
    useEffect(() => {
        setInitialValues(data)
    }, [data])
    const handleSubmit = () => {
        const { name } = initialValues
        if (!name) {
            showErrorMessage("", "Fields are required", 2000)
        } else {
            setSubmitting(true)
            fun(initialValues, setSubmitting, setShow)
        }

    }


    const handleChange = (event) => {
        setInitialValues({ ...initialValues, [event.target.name]: event.target.value })
    }
    // name: "",
    
    return (
        <>
            <>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setShow(true)}
                >
                    {text === "Add New" ? <Plus style={{ fontSize: "16px" }} /> : null}
                    {text}
                </Button>
            </>
            <Modal
                show={show}
                onHide={() => {
                    setShow(false)
                    setInitialValues(data)
                    setSubmitting(false)
                }}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {text}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" controlId="validationFormik01">
                                <Form.Label>Assembler Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={initialValues.name}
                                    onChange={handleChange}
                                    placeholder='name'
                                />
                            </Form.Group>
                        </Row>
                        <div className='d-flex flex-row-reverse gap-3'>
                        
                        <Button onClick={handleSubmit} disabled={isSubmitting} variant='success'>Submit</Button>
                        <Button variant='danger' onClick={()=>setShow(false)} >Cancel</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}


export default AssemblerForm