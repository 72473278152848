import React from 'react';
import { Col, Row } from "react-bootstrap"

const HouseUserBox = ({ deviceId, house, user, room }) => {
	return (
		<div className={'rounded elevated'} style={{margin: '0px'}}>
			<h6>{deviceId}</h6>
			<hr />
			<Row>
				<Col>
					House:
				</Col>
				<Col>
					{house.houseName}
				</Col>
			</Row>
			<Row>
				<Col>
					User:
				</Col>
				<Col>
					{user.name} {user.email}
				</Col>
			</Row>
		</div>
	)
}

export default HouseUserBox;
