import React from "react"
import { Button, Col, Row } from "react-bootstrap"

const LoadController = ({ isFanLoad, name, on, setLoad, setSwitch }) => {
	return (
		<div>
			<div className="rounded elevated" style={{padding: '5px'}}>
				<Row>
					<Col>
						<div style={{ width: '100px', height: '100px', color: on ? "white" : "black", backgroundColor: on ? "#3F51B5" : "whitesmoke", border: '0.05px solid grey', boxShadow: '1px 1px 1px whitesmoke' }} className={'rounded'} >
							{name}
						</div>
					</Col>
					<Col>
						<Row>
							<Col lg={6}>
								<Button
									variant="outline-primary"
									style={{ margin: '15px' }}
									onClick={() => {
										setLoad(on ? 0 : 100)
									}}>
									App
								</Button>
							</Col>

						</Row>
						<Row>
							<Col lg={6}>
								<Button
									variant="outline-secondary"
									style={{ margin: '15px' }}
									onClick={() => {
										setSwitch(on ? 0 : 100)
									}}>
									Switch
								</Button>
							</Col>
						</Row>
					</Col>
					<Col>
						{isFanLoad === true && <>
							<Col>
								<Row>
									<Col lg={6}>
										<Button
											variant="outline-info"
											style={{ margin: '15px' }}
											onClick={() => {
												setLoad(25)
											}}>
											25
										</Button>
									</Col>
									<Col lg={6}>
										<Button
											variant="outline-info"
											style={{ margin: '15px' }}
											onClick={() => {
												setLoad(50)
											}}>
											50
										</Button>
									</Col>
								</Row>
								<Row>
									<Col lg={6}>
										<Button
											variant="outline-info"
											style={{ margin: '15px' }}
											onClick={() => {
												setLoad(75)
											}}>
											75
										</Button>
									</Col>
									<Col lg={6}>
										<Button
											variant="outline-info"
											style={{ margin: '15px' }}
											onClick={() => {
												setLoad(100)
											}}>
											100
										</Button>
									</Col>
								</Row>
							</Col>
						</>}
					</Col>
				</Row>

			</div>

		</div>
	)
}

export default LoadController;
