import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import StepCard from "../components/StepCard";
import {
  AmplifyConfig,
  baseurl,
  ControlSyncSteps,
  MQTTEvents,
  TestingStationEvents,
  TestingType,
  PubSubConfig,
  WifiHealthStatus,
} from "../config/constants";
import Images from "../config/images";
import { io } from "socket.io-client";
import { connect } from "react-redux";
import DeviceProblemsList from "../components/DeviceProblemsList";
import Select from "./ControlSyncScreens/Select";
import Control from "./ControlSyncScreens/Control";
import { isESP8266Device } from "../utils/helpers";
import { Amplify } from "aws-amplify";
import { Hub } from 'aws-amplify/utils';
import { PubSub } from "@aws-amplify/pubsub";
import APIs from "../utils/apis";

class ControlSyncScreen extends Component {
  state = {
    step: ControlSyncSteps.Select,
    deviceConnectionStatus: {},
    switchPinConnectionStatus: {},
    deviceLoadStates: { 0: 30, 1: 40, 2: 100, 3: 60, 4: 0 },
  };
  subscriptions = [];
  iotProvider = null;

  componentDidMount() {
    Hub.listen("pubsub", (data) => {
      const { payload } = data;
      if (payload.event === "ConnectionStateChange") {
        if (payload.data.connectionState === "Connected") {
          this.onTestingStationConnected(null);
        }
      }
    });
  }
  render() {
    const {
      step,
      deviceConnectionStatus,
      switchPinConnectionStatus,
      deviceLoadStates,
    } = this.state;
    return (
      <div>
        <Row className="g-0">
          <Col lg={3}>
            <div className={"section_container"}>
              <StepCard
                label={"Select Device"}
                step={ControlSyncSteps.Select}
                setStep={this.setStep}
                icon={Images.ControlSync.Select}
                selected={step === ControlSyncSteps.Select}
              />
              <StepCard
                label={"Control"}
                step={ControlSyncSteps.Control}
                setStep={this.setStep}
                icon={Images.ControlSync.Control}
                selected={step === ControlSyncSteps.Control}
              />
            </div>
          </Col>
          <Col
            lg={6}
            style={{
              borderLeft: "0.5px solid black",
              borderRight: "0.5px solid black",
            }}
          >
            <div className="section_container workAreaDiv invisibleScrollbar">
              {step === ControlSyncSteps.Select && (
                <Select onStepComplete={this.onRegistrationSuccessful} />
              )}
              {step === ControlSyncSteps.Control && (
                <Control
                  onStepComplete={this.onDetailsNext}
                  deviceConnectionStatus={deviceConnectionStatus}
                  switchPinConnectionStatus={switchPinConnectionStatus}
                  loadStates={deviceLoadStates}
                  setLoad={this.sendControlCommand}
                  setSwitch={this.sendSwitchCommand}
                  sendSyncMessage={this.sendSyncMessage}
                />
              )}
            </div>
          </Col>
          <Col lg={3}>
            <div className="section_container">
              <DeviceProblemsList device={this.props.currentSyncTest.device} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  onSyncEvent = (message) => {
    const { currentSyncTest } = this.props;
    const { device, testingStation } = currentSyncTest;
    const { event, payload } = message;
    if (event === "current_wifi") {
      // check if the ssid and ws are equal and same ws is there in testing station wifi configs
      // compare strength
      const wifiIndex = testingStation.wifi_configs.findIndex(
        (wifi) => wifi.ws === payload.ws && wifi.wp === payload.wp
      );
      let wifiHealth = WifiHealthStatus.Unknown;
      if (wifiIndex !== -1 && payload.ws === payload.ssid) {
        const wifi = testingStation.wifi_configs[wifiIndex];
        let expectedStrength = -1;
        if (isESP8266Device(device.deviceId)) {
          expectedStrength = wifi.expected_8266_strength;
        } else {
          expectedStrength = wifi.expected_esp32_strength;
        }
        if (Math.abs(payload.strength - expectedStrength) < 5) {
          wifiHealth = WifiHealthStatus.Good;
        } else {
          wifiHealth = WifiHealthStatus.Bad;
        }
      }

      this.setState({
        deviceConnectionStatus: {
          ...this.state.deviceConnectionStatus,
          ws: payload.ws,
          wp: payload.wp,
          ssid: payload.ssid,
          strength: payload.strength,
          wifiHealth,
        },
      });
    } else if (event === "message") {
      payload.state =
        payload.State === undefined ? payload.state : payload.State;
      const deviceLoadStates = {
        ...this.state.deviceLoadStates,
        [payload.switchId]: Number(payload.state),
      };
      this.setState({ deviceLoadStates });
    } else if (event === "sync") {
      let loadStates = {};
      let parts = message.message.states.split(":");
      parts.forEach((state) => {
        if (state) {
          let [switchId, loadState] = state.split(",").map((a) => Number(a));
          loadStates[switchId] = loadState;
        }
      });
      this.setState({ deviceLoadStates: loadStates });
    } else if (event === "mqtt_sync") {
      const ls = payload.ls.split(",").map((s) => Number(s));
      let loadStates = {};
      for (let i = 0; i < ls.length; i += 1) {
        loadStates[i] = ls[i];
      }
      this.setState({ deviceLoadStates: loadStates });
    }
  };

  onSyncConnected = (socketClient, message) => {
    const { testingStation } = this.props.currentSyncTest;
    // ask the device for its wifi details
    if (socketClient) {
      socketClient?.emit(TestingStationEvents.EVENT, {
        deviceId: testingStation.deviceId,
        event: "current_wifi",
        payload: "",
      });
    }
    const { deviceConnectionStatus } = this.state;
    this.setState({
      deviceConnectionStatus: {
        ...deviceConnectionStatus,
        connected: true,
        connectionId: message.socketId,
        numOfConnections: deviceConnectionStatus.numOfConnections + 1,
      },
    });
  };

  onSyncDisconnected = () => {
    this.setState({
      deviceConnectionStatus: {
        ...this.state.deviceConnectionStatus,
        connected: false,
        connectionId: "",
      },
    });
  };

  onStatusUpdate = (socketClient, message) => {
    const { device, testingStation } = this.props.currentSyncTest;
    const { deviceConnectionStatus } = this.state;
    const onlineIndex = message.online.findIndex(
      (a) => a.deviceId === device.deviceId
    );
    if (onlineIndex !== -1) {
      let isNewConnection =
        deviceConnectionStatus.connectionId !==
        message.online[onlineIndex].socketId;
      this.setState({
        deviceConnectionStatus: {
          ...deviceConnectionStatus,
          connected: onlineIndex !== -1,
          numOfConnections: isNewConnection
            ? deviceConnectionStatus.numOfConnections + 1
            : deviceConnectionStatus.numOfConnections,
          connectionId: message.online[onlineIndex].socketId,
        },
      });
      if (socketClient) {
        socketClient.emit(TestingStationEvents.EVENT, {
          deviceId: testingStation.deviceId,
          event: "current_wifi",
          payload: "",
        });
      }
    } else {
      if (deviceConnectionStatus.connected) {
        this.setState({
          deviceConnectionStatus: {
            ...deviceConnectionStatus,
            connected: false,
            connectionId: "",
          },
        });
      }
    }
  };

  resetMQTTConnection = () => {
    this.iotProvider = null;
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.subscriptions = [];
  };

  testingWebSockets = (device, testingStation, user) => {
    const socketClient = io(`${baseurl}`, {
      query: {
        deviceId: testingStation.deviceId,
        device_type: "testingStation",
        test_type: TestingType.Control,
        deviceIds: [device.deviceId],
        name: `${user.first_name} ${user.last_name}`,
        employeeId: user.employeeId,
      },
    });
    socketClient.on("connect", () => {
      this.onTestingStationConnected(socketClient);
    });
    socketClient.on(TestingStationEvents.CONNECTED, (message) => {
      // ask the device for its wifi details
      this.onSyncConnected(socketClient, message);
    });
    socketClient.on(TestingStationEvents.DISCONNECTED, (message) => {
      // show an error message and quit cause the device should not be disconnecting at all
      this.onSyncDisconnected();
    });

    socketClient.on(TestingStationEvents.SWITCH_PIN_EVENT, (message) => {
      this.onSwitchPinTesterEvent(message);
    });

    socketClient.on(TestingStationEvents.SWITCH_PIN_CONNECTION, (message) => {
      this.onSwitchPinConnectionUpdate(message);
    });

    socketClient.on(TestingStationEvents.EVENT, (message) => {
      this.onSyncEvent(message);
    });

    socketClient.on(TestingStationEvents.STATUS, (message) => {
      this.onStatusUpdate(socketClient, message);
    });

    socketClient.connect();
    this.socketClient = socketClient;
  };

  onTestingStationConnected = (socketClient) => {
    const { testingStation, device } = this.props.currentSyncTest;
    this.setStep(ControlSyncSteps.Control);
    this.setState({
      deviceConnectionStatus: {
        ...this.state.deviceConnectionStatus,
        deviceId: device.deviceId,
      },
      switchPinConnectionStatus: {
        ...this.state.switchPinConnectionStatus,
        deviceId: testingStation.switch_pin_tester,
      },
    });

    setTimeout(() => {
      if (socketClient) {
        socketClient.emit(TestingStationEvents.SYNC, {
          deviceId: testingStation.deviceId,
        });
      }
      if (this.iotProvider) {
        this.mqttPublish(
          `${MQTTEvents.STATUS_UPDATE}/${testingStation.deviceId}`,
          {
            deviceIds: [device.deviceId],
            switchPinTester: testingStation.switch_pin_tester,
            fanModuleTester: testingStation.fan_module_tester,
          }
        );
      }
    }, 1000);
  };
  testingMQTT = (device, testingStation, user) => {
    Amplify.configure(AmplifyConfig);
    this.resetMQTTConnection();
    const pubsub = new PubSub({
      endpoint: PubSubConfig.aws_pubsub_endpoint,
      region: PubSubConfig.aws_pubsub_region,
      clientId: `${testingStation.deviceId}`,
    })
    this.iotProvider = pubsub;
    let s;
    s = pubsub
      .subscribe({ topics: [`${MQTTEvents.CONNECTED}/${testingStation.switch_pin_tester}`] })
      .subscribe({
        next: () => {
          this.onSwitchPinConnectionUpdate({ connected: true });
          this.mqttPublish(
            `${MQTTEvents.SWITCHPIN_TESTER_WIFI_DETAILS}/${testingStation.switch_pin_tester}`,
            {}
          );
        },
      });
    this.subscriptions.push(s);
    s = pubsub
      .subscribe({ topics: [`${MQTTEvents.DISCONNECTED}/${testingStation.switch_pin_tester}`] })
      .subscribe({
        next: () => {
          this.onSwitchPinConnectionUpdate({ connected: false });
        },
      });

    this.subscriptions.push(s);
    s = pubsub
      .subscribe({ topics: [`${MQTTEvents.SWITCHPIN_TESTER_CURRENT_WIFI}/${testingStation.switch_pin_tester}`] })
      .subscribe({
        next: (data) => {
          this.onSwitchPinTesterEvent({
            event: "current_wifi",
            payload: data,
          });
        },
      });

    this.subscriptions.push(s);
    s = pubsub
      .subscribe({topics: [`${MQTTEvents.CONNECTED}/${device.deviceId}`]})
      .subscribe({
        next: (data) => {
          this.onSyncConnected(null, {
            socketId: data.sessionIdentifier,
          });
        },
      });

    this.subscriptions.push(s);
    s = pubsub
        .subscribe({topics: [`${MQTTEvents.DISCONNECTED}/${device.deviceId}`]})
        .subscribe({
          next: (data) => {
            this.onSyncDisconnected();
          },
        });


    this.subscriptions.push(s);
    s = pubsub
      .subscribe({ topics: [`${MQTTEvents.DEVICE_MESSAGE}/${device.deviceId}`] })
      .subscribe({
        next: (data) => {
          this.onSyncEvent({
            event: "message",
            payload: {
              switchId: data.sid,
              state: data.s,
              id: data.id,
              changed: data.c,
            },
          });
        },
      });

    this.subscriptions.push(s);
    s = pubsub
      .subscribe({ topics: [`${MQTTEvents.DEVICE_CURRENT_WIFI}/${device.deviceId}`] })
      .subscribe({
        next: (data) => {
          this.onSyncEvent({ event: "current_wifi", payload: data });
        },
      });

    this.subscriptions.push(s);
    s = pubsub
      .subscribe({topics: [`${MQTTEvents.DEVICE_SYNC}/${device.deviceId}`]})
      .subscribe({
        next: (data) => {
          // restructure payload according to old sync event
          this.onSyncEvent({ event: "mqtt_sync", payload: data });
        },
      });

    this.subscriptions.push(s);
    s = pubsub
     .subscribe({topics: [`${MQTTEvents.STATUS_SYNC}/${testingStation.deviceId}`]})
     .subscribe({
        next: (data) => {
          this.onStatusUpdate(null, {
            online: data.devices.map((d) => ({
              deviceId: d.deviceId,
              socketId: d.sessionId,
            })),
          });
          data.devices.forEach((device) => {
            this.mqttPublish(
              `${MQTTEvents.DEVICE_WIFI_DETAILS}/${device.deviceId}`,
              {}
            );
            this.mqttPublish(
              `${MQTTEvents.DEVICE_STATUS_UPDATE}/${device.deviceId}`,
              {}
            );
          });
          if (data.switchPinTester) {
            this.onSwitchPinConnectionUpdate({ connected: true });
            this.mqttPublish(
              `${MQTTEvents.SWITCHPIN_TESTER_WIFI_DETAILS}/${device.deviceId}`,
              {}
            );
          }
        },
      });
  };

  onRegistrationSuccessful = () => {
    const { user, currentSyncTest } = this.props;
    const { device, testingStation } = currentSyncTest;
    if (!device || !testingStation) {
      return;
    }
    if (testingStation.isMQTT) {
      this.testingMQTT(device, testingStation, user);
    } else {
      this.testingWebSockets(device, testingStation, user);
    }
  };

  onSwitchPinConnectionUpdate = (message) => {
    this.setState({
      switchPinConnectionStatus: {
        ...this.state.switchPinConnectionStatus,
        connected: message.connected,
      },
    });
  };

  sendSyncMessage = () => {
    const { testingStation, device } = this.props.currentSyncTest;
    if (this.socketClient && this.socketClient.connected) {
      this.socketClient.emit(TestingStationEvents.SYNC, {
        deviceId: testingStation.deviceId,
      });
      this.socketClient.emit(TestingStationEvents.EVENT, {
        event: "sync",
        deviceId: testingStation.deviceId,
      });
    }
    if (testingStation.isMQTT) {
      if (this.state.deviceConnectionStatus.connected === true) {
        this.mqttPublish(
          `${MQTTEvents.STATUS_UPDATE}/${testingStation.deviceId}`,
          {
            deviceIds: [device.deviceId],
            switchPinTester: testingStation.switch_pin_tester,
            fanModuleTester: testingStation.fan_module_tester,
          }
        );
      }
    }
  };

  canSendSocketMessage = () => {
    return this.socketClient && this.socketClient.connected;
  };

  mqttPublish = (event, payload) => {
    const lastIndex = event.lastIndexOf('/');
    const eventName = event.substring(0, lastIndex);
    const deviceId = event.substring(lastIndex + 1)
    if (typeof payload === typeof {}) {
      // if payload if of type object, we send via the pubsub connection
      if (this.iotProvider) {
        return this.iotProvider?.publish({ topics: [event], message: payload })
      } else {
        return APIs.publishMQTTMessage({ deviceId, event: eventName, payload: JSON.stringify(payload) });
      }
    } else {
      return APIs.publishMQTTMessage({ deviceId, event: eventName, payload: String(payload) });
    }
  };

  sendControlCommand = (switchId, command) => {
    const { testingStation } = this.props.currentSyncTest;
    let id = `${new Date().getTime()}`.slice(5, 13);
    if (testingStation.isMQTT) {
      this.mqttPublish(
        `${MQTTEvents.DEVICE_CONTROL}/${this.props.currentSyncTest.device.deviceId}`,
        `${switchId},${command},${id}`
      );
    } else {
      if (this.canSendSocketMessage()) {
        this.socketClient.emit(TestingStationEvents.EVENT, {
          event: "chat message",
          deviceId: testingStation.deviceId,
          payload: `${switchId},${command},${id}`,
        });
      }
    }
  };

  sendSwitchCommand = (switchId, command) => {
    const { testingStation } = this.props.currentSyncTest;
    if (testingStation.isMQTT) {
      this.mqttPublish(
        `${MQTTEvents.SWITCHPIN_TESTER_CONTROL}/${testingStation.switch_pin_tester}`,
        `${switchId},${Number(command) === 0 ? 0 : 1}`
      );
    } else {
      if (this.canSendSocketMessage()) {
        this.socketClient.emit(TestingStationEvents.SEND_SWITCH_PIN_COMMAND, {
          deviceId: testingStation.deviceId,
          switchId,
          command,
        });
      }
    }
  };

  onSwitchPinTesterEvent = (message) => {
    const { testingStation } = this.props.currentSyncTest;
    const { event, payload } = message;
    if (event === "current_wifi") {
      const wifiIndex = testingStation.wifi_configs.findIndex(
        (wifi) => wifi.ws === payload.ws && wifi.wp === payload.wp
      );
      let wifiHealth = WifiHealthStatus.Unknown;
      if (wifiIndex !== -1 && payload.ws === payload.ssid) {
        const wifi = testingStation.wifi_configs[wifiIndex];
        let expectedStrength = wifi.expected_esp32_strength;
        if (Math.abs(payload.strength - expectedStrength) < 5) {
          wifiHealth = WifiHealthStatus.Good;
        } else {
          wifiHealth = WifiHealthStatus.Bad;
        }
      }
      this.setState({
        switchPinConnectionStatus: {
          ...this.state.switchPinConnectionStatus,
          deviceId: testingStation.switch_pin_tester,
          ws: payload.ws,
          wp: payload.wp,
          strength: payload.strength,
          ssid: payload.ssid,
          wifiHealth,
        },
      });
    }
  };

  setStep = (step) => {
    this.setState({ step });
  };
}

const mapStateToProps = (state) => ({
  currentSyncTest: state.currentSyncTest,
  user: state.user.profile,
});

export default connect(mapStateToProps)(ControlSyncScreen);
