
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import LoginPage from './screens/Auth/Login';
import {useSelector} from 'react-redux';
import Dashboard from './screens/Dashboard';
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

function App() {
  const accessToken = useSelector((state) => state.user.accessToken);
  if (!accessToken) {
    return <LoginPage />
  }

  return (
    <div>
      <BrowserRouter>
        <ReactNotifications />
        <Dashboard />
      </BrowserRouter>
    </div>
  );
}

export default App;
