import React, { useState } from "react";
import { Badge, Button, Form, Table } from "react-bootstrap";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";
import { EnergISyncFailedReasonsText } from "../../config/constants";
import { EnergISyncTestLogs } from "../../components/EnergISyncTestLogs";



const Summary = ({ devices, testingStatus, onSubmit, sentMessages }) => {
  const [message, setMessage] = useState('');
  const [expandedDevice, setExpandedDevice] = useState('');
  return (
    <div>
      <TestStepsHeader
        icon={Images.EnergISyncHardwareTesting.Summary}
        title={"Summary"}
      />
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>Device ID</th>
            <th>Testing Station</th>
            <th>Master Meter</th>
            <th>Testing Status</th>
            <th>Failed Reason</th>
          </tr>
        </thead>
        <tbody>
          {
            Object.keys(testingStatus).map((deviceId, index) => {
              const device = devices.find(d => d.deviceId === deviceId) || {};
              const { passed, failedReason } = testingStatus[deviceId] || {};
              return (
                <>
                  <tr key={device.deviceId} onClick={() => {
                    if (expandedDevice !== device.deviceId) {
                      setExpandedDevice(device.deviceId)
                    } else {
                      setExpandedDevice('');
                    }
                  }}>
                    <td>{index + 1}</td>
                    <td>{device.deviceId}</td>
                    <td>{device?.testingStation?.name}</td>
                    <td>{device?.testingStation?.energisync_tester}</td>
                    <td>
                      <h5>
                        <Badge bg={passed ? "success" : "danger"}>
                          {passed ? "Passed" : "Failed"}
                        </Badge>
                      </h5>
                    </td>
                    <td>
                      {EnergISyncFailedReasonsText[failedReason] || "NA"}
                    </td>
                  </tr>
                </>
              )
            })
          }
        </tbody>
      </Table>
      {expandedDevice !== '' && (
        <EnergISyncTestLogs logs={sentMessages.filter(msg => msg.deviceId === expandedDevice)} deviceId={expandedDevice} />
      )}
      <br />
      <div>
        <Form.Group>
          <Form.Label>Message reason for failed</Form.Label>
          <Form.Control
            as={"textarea"}
            rows={5}
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            placeholder={"Message for test logs"}
          />
        </Form.Group>
        <br />
        <Button
          onClick={() => {
            onSubmit(message)
          }}
        >
          &nbsp;Submit&nbsp;
        </Button>
      </div>
    </div>
  );
};

export default Summary;
