import React, { Component } from "react";
import { connect } from "react-redux";
import { DeviceProblemCard } from "./DeviceProblemsList";
import { MovementCard } from "./MovementsList";
const LogTypes = {
	Testing: 0,
	Movement: 1,
}
class History extends Component {

	render () {
		const {deviceId, histories} = this.props;
		const history = histories[deviceId];
		if (!history) {
			return (
				<div>
					<h1>Could not find any data</h1>
				</div>
			)
		}
		let mergedData = [];
		for (const log of history?.testing?.device?.testing_history || []) {
			mergedData = mergedData.concat({
				...log,
				logType: LogTypes.Testing,
			})
		}
		for (const movement of history?.movements?.transactions || []) {
			mergedData =  mergedData.concat({
				...movement,
				logType: LogTypes.Movement,
			})
		}
		mergedData.sort((l1, l2) => new Date(l1.date).getTime() - new Date(l2.date).getTime())
		return (
			<div style={{ maxHeight: "90vh", overflowY: "scroll" }} className={'invisibleScrollbar'}>
				{
					mergedData.map((log, index) => {
						if (log.logType === LogTypes.Testing) {
							return (
								<DeviceProblemCard log={log} onResolve={() => {}} index={index} />
							)
						} else if (log.logType === LogTypes.Movement) {
							return (<MovementCard transaction={log} />)
						} else {
							return null;
						}
					})
					
				}
			</div>
		)
	}
}

const mapStateToProps = state => ({
	histories: state.histories,
});

export default connect(mapStateToProps)(History);