import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import DeviceProblemsList from "../components/DeviceProblemsList";
import TestStepsHeader from "../components/TestStepsHeader";
import { NewRepairResons, ReapirReasons, SMPSParts, TestingType } from "../config/constants";
import Images from "../config/images";
import TestingActions from "../redux/actions/testing";
import {
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from "../utils/helpers";
import Multiselect from "multiselect-react-dropdown";
class RepairLogScreen extends Component {
  state = {
    deviceId: "",
    reason: '',
    message: "",
    device: {},
    part: [],
    discarded: false,
  };
  processing = false;
  repairStartTime;
  render() {
    const { deviceId, message, device, part, reason } = this.state;
    return (
      <Row className={"g-0"}>
        <Col lg={3}>
          <div className="section_container">
            <TestStepsHeader
              icon={Images.RepairLog.EnterDeviceId}
              title={"Enter Device ID"}
            />
            <Form.Group>
              <Form.Label>Enter Device ID</Form.Label>
              <Form.Control
                value={deviceId}
                onChange={this.handleDeviceIDChanged}
                placeholder={"Device ID"}
              />
            </Form.Group>
            <br />
            <Button onClick={this.handleGetDeviceDetails}>Get Details</Button>
            <br />
          </div>
        </Col>
        <Col
          lg={5}
          style={{
            borderLeft: "0.5px solid black",
            borderRight: "0.5px solid black",
          }}
        >
          <div className="section_container">
            <TestStepsHeader
              icon={Images.MenuImages.RepairLog}
              title={"Log Repair"}
            />
            <Form.Group>
              <Form.Label>Select a reason</Form.Label>
              <Form.Select aria-label="Select reason for processing" onChange={this.handleReasonChanged}>
                <option>Choose One</option>
                {NewRepairResons.map((reason, index) =>
                  <option value={`${index}`}>{reason}</option>
                )}
              </Form.Select>
            </Form.Group>
            <br />
            {
              reason !== '0' ?
                <>
                  <Form.Group>
                    <Form.Label>Parts</Form.Label>
                    <Multiselect
                      displayValue="value"
                      selectedValues={part}
                      onRemove={this.setSelectedValue}
                      onSelect={this.setSelectedValue}
                      options={SMPSParts}
                      style={{
                        width: "100px !important",
                        multiselectContainer: {
                          marginTop: "5px",
                          backgroundColor: "#ffffff"
                        },
                      }}
                    />
                  </Form.Group>
                  <br />
                </>:null
            }

            <Form.Group>
              <Form.Label>Add a message for logs:</Form.Label>
              <Form.Control
                as={"textarea"}
                rows={5}
                value={message}
                placeholder={"Tester message"}
                onChange={this.handleMessageChanged}
              />
            </Form.Group>
            <br />
            <Form.Check
              id={"chkDeviceDiscarded"}
              defaultChecked={false}
              type="checkbox"
              label={"Is the device permenantly damaged i.e., discarded?"}
              onChange={(e) => {
                this.setDiscarded(e.target.checked);
              }}
            />
            <br />
            <Button onClick={this.handleSubmit}>Submit</Button>
            <br />
          </div>
        </Col>
        <Col lg={4}>
          <div className="section_container">
            <DeviceProblemsList device={device} onResolve={() => { }} />
          </div>
        </Col>
      </Row>
    );
  }

  handleReasonChanged = (e) => {
    this.setState({ reason: e.target.value });
  };

  setSelectedValue = (e) => {
    this.setState({ part: e });
  }

  handleSubmit = async () => {
    if (this.processing) return;
    this.processing = true;
    const { deviceId, reason, message, discarded, part, } = this.state;
    if (reason==="") {
      this.processing = false;
      showErrorMessage('Select reason', 'Select a reason to continue');
      return;
    }
    let parts = part.map((e => {
      return e.value
    }))
    const { success, message: rmessage } = await TestingActions.logRepair({
      deviceId,
      reason: ReapirReasons[reason],
      message,
      type: TestingType.Repair,
      meta_data: {
        repair_start_time: this.repairStartTime,
        repair_end_time: new Date(),
        reasonIndex: reason,
        parts: parts,
      },
      discarded,
      testing_type: "",
    });
    if (success) {
      showSuccessMessage("Data saved", rmessage);
    } else {
      showErrorMessage("Data save failed", rmessage);
    }
    this.setState({
      deviceId: "",
      reason: '0',
      message: "",
      device: {},
      discarded: false,
    });
    this.processing = false;
  };

  setDiscarded = (discarded) => {
    this.setState({ discarded });
  };

  handleGetDeviceDetails = async () => {
    const { success, data, message } = await TestingActions.details(
      this.state.deviceId
    );
    if (!success) {
      showErrorMessage("Failed to get device details", message);
      return;
    }
    showInfoMessage("Got device details", message);
    this.setState({ device: data.device });
    this.repairStartTime = new Date();
  };

  handleMessageChanged = (e) => {
    this.setState({ message: e.target.value });
  };

  handleDeviceIDChanged = (e) => {
    this.setState({ deviceId: e.target.value.toUpperCase().replace(/[^0-9a-z]/gi, '') });
  };
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { resolveFailure: TestingActions.resolveFailure })(RepairLogScreen);
