import axios from "axios";
import { baseurl, weburl } from "../../config/constants";
import {
  accessTokenHeader,
  showErrorMessage,
  showInfoMessage,
  showSuccessMessage,
} from "../../utils/helpers";
import { CurrentSyncTestActions } from "../actionTypes";

export const updateDeviceWiFi = (deviceIds, ws, wp) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${weburl}/v2/admin/updateWifi/device`,
        {
          deviceIds,
          ws,
          wp,
        },
        {
          headers: {
            ...accessTokenHeader(),
          },
        }
      )
      .then(
        (result) => {
          const { success, message, data } = result.data;
          showSuccessMessage("Update wifi", message);
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};

const register = async(deviceId) => {
  return new Promise((resolve) => {
    axios
      .post(
        `${baseurl}/testing/register`,
        {
          deviceId,
        },
        {
          headers: {
            ...accessTokenHeader(),
          },
        }
      )
      .then(
        (result) => {
          const { success, message, data } = result.data;
          showSuccessMessage("Register Device", message);
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};

/**
 * Mark that device failed testing
 * @param {{deviceId: String, reason: String, message:  String, testing_station: String, testing_type: String, type: String, switch_pin_tester: String}} details  testing_type is the step of testing, and type is testing type hardware/ online offine.
 * @returns
 */
const failed =
  (
    details = {
      deviceId: "",
      reason: "",
      message: "",
      testing_station: "",
      testing_type: "",
      type: "",
      switch_pin_tester: "",
      meta_data: {},
    }
  ) =>
  (dispatch) => {
    return new Promise((resolve) => {
      axios
        .post(`${baseurl}/testing/failed`, details, {
          headers: {
            ...accessTokenHeader(),
          },
        })
        .then(
          (result) => {
            const { success, message, data } = result.data;
            dispatch({
              type: CurrentSyncTestActions.SET_DEVICE,
              payload: data.device,
            });
            showInfoMessage("Device Failed Test", message);
            resolve({
              success,
              message,
              data,
            });
          },
          (error) => {
            const message = error.response.data
              ? error.response.data.message
              : String(error);
            resolve({
              success: false,
              message,
            });
          }
        )
        .catch((error) => {
          resolve({
            success: false,
            message: String(error),
          });
        });
    });
  };

const resolveFailure =
  (
    details = {
      deviceId: "",
      index: 0,
      message: "",
    }
  ) =>
  (dispatch) => {
    return new Promise((resolve) => {
      axios
        .post(`${baseurl}/testing/resolveFailure`, details, {
          headers: {
            ...accessTokenHeader(),
          },
        })
        .then(
          (result) => {
            const { success, message, data } = result.data;
            dispatch({
              type: CurrentSyncTestActions.SET_DEVICE,
              payload: data.device,
            });
            showInfoMessage("Device Problem Resolved", message);
            resolve({
              success,
              message,
              data,
            });
          },
          (error) => {
            const message = error.response.data
              ? error.response.data.message
              : String(error);
            resolve({
              success: false,
              message,
            });
          }
        )
        .catch((error) => {
          resolve({
            success: false,
            message: String(error),
          });
        });
    });
  };

const logRepair = (
  details = {
    deviceId: "",
    reason: "",
    message: "",
    discarded: false,
    testing_type: "",
    type: "",
  }
) => {
  return new Promise((resolve) => {
    axios
      .post(`${baseurl}/testing/logRepair`, details, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, message, data } = result.data;
          showInfoMessage("Device Repair Logged Successfully", message);
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};

const basicTestPassed =
  (
    details = {
      deviceId: "",
      message: "",
      testing_station: "",
    }
  ) =>
  (dispatch) => {
    return new Promise((resolve) => {
      axios
        .post(`${baseurl}/testing/basicSuccess`, details, {
          headers: {
            ...accessTokenHeader(),
          },
        })
        .then(
          (result) => {
            const { success, message, data } = result.data;
            
            dispatch({
              type: CurrentSyncTestActions.SET_DEVICE,
              payload: data.device,
            });
            showInfoMessage("Device Passed Basic Test", message);
            resolve({
              success,
              message,
              data,
            });
          },
          (error) => {
            const message = error.response.data
              ? error.response.data.message
              : String(error);
            resolve({
              success: false,
              message,
            });
          }
        )
        .catch((error) => {
          resolve({
            success: false,
            message: String(error),
          });
        });
    });
  };

const hardwareTestPassed =
  (
    details = {
      deviceId: "",
      message: "",
      testing_station: "",
      switch_pin_tester: "",
    }
  ) =>
  (dispatch) => {
    return new Promise((resolve) => {
      axios
        .post(`${baseurl}/testing/hardwareSuccess`, details, {
          headers: {
            ...accessTokenHeader(),
          },
        })
        .then(
          (result) => {
            const { success, message, data } = result.data;
            dispatch({
              type: CurrentSyncTestActions.SET_DEVICE,
              payload: data.device,
            });
            showInfoMessage("Device Passed Hardware Test", message);
            resolve({
              success,
              message,
              data,
            });
          },
          (error) => {
            const message = error.response.data
              ? error.response.data.message
              : String(error);
            resolve({
              success: false,
              message,
            });
          }
        )
        .catch((error) => {
          resolve({
            success: false,
            message: String(error),
          });
        });
    });
  };

const onlineTestPassed =
  (
    details = {
      deviceId: "",
      message: "",
      testing_station: "",
    }
  ) =>
  (dispatch) => {
    return new Promise((resolve) => {
      axios
        .post(`${baseurl}/testing/onlineSuccess`, details, {
          headers: {
            ...accessTokenHeader(),
          },
        })
        .then(
          (result) => {
            const { success, message, data } = result.data;
            dispatch({
              type: CurrentSyncTestActions.SET_DEVICE,
              payload: data.device,
            });
            showInfoMessage(
              `Device ${data.device.deviceId} Passed Online Test`,
              message
            );
            resolve({
              success,
              message,
              data,
            });
          },
          (error) => {
            const message = error.response.data
              ? error.response.data.message
              : String(error);
            showErrorMessage(`Device Update Failed`, message);
            resolve({
              success: false,
              message,
            });
          }
        )
        .catch((error) => {
          showErrorMessage(`Device Update Failed`, String(error));
          resolve({
            success: false,
            message: String(error),
          });
        });
    });
  };

const details = (deviceId) => {
  return new Promise((resolve) => {
    axios
      .get(`${baseurl}/testing/details/${deviceId}`, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, message, data } = result.data;
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
            data: {},
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
          data: {}
        });
      });
  });
};

const testingStatus = (deviceId) => {
  return new Promise((resolve) => {
    axios
      .get(`${baseurl}/testing/testingStatus/${deviceId}`, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, message, data } = result.data;
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};

const deviceHouseDetails = (deviceId) => {
  return new Promise((resolve) => {
    axios
      .get(`${baseurl}/testing/houseDetails/${deviceId}`, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, message, data } = result.data;
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};

const discarded = (
  details = {
    deviceId: "",
    discarding_reason: "",
    discarding_message: "",
    testing_station: "",
  }
) => {
  return new Promise((resolve) => {
    axios
      .post(`${baseurl}/testing/discard`, details, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, message, data } = result.data;
          showInfoMessage("Device Discarded", message);
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};

const byPass = (
  details = {
    deviceIds: [],
  }
) => {
  return new Promise((resolve) => {
    axios
      .post(`${baseurl}/testing/discard`, details, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, message, data } = result.data;
          showInfoMessage("Device Discarded", message);
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};


const TestingActions = {
  register,
  failed,
  resolveFailure,
  logRepair,
  basicTestPassed,
  hardwareTestPassed,
  onlineTestPassed,
  details,
  testingStatus,
  deviceHouseDetails,
  discarded,
}

export default TestingActions;