import axios from "axios";
import { baseurl } from "../../config/constants";
import { accessTokenHeader } from "../../utils/helpers";
import { showErrorMessage, showSuccessMessage } from "../../utils/helpers";
import { PcbTypeAction } from "../actionTypes";
import store from "../source";

const loadPcbData = (data) => {
  return {
    type: PcbTypeAction.LOAD,
    payload: data,
  };
};
const updatePcbData = (data) => {
  return {
    type: PcbTypeAction.UPDATE,
    payload: data,
  };
};
const removePcbData = (data) => {
  return {
    type: PcbTypeAction.REMOVE,
    payload: data,
  };
};

export const add = async (details, setSubmitting, setShow) => {
  await axios
    .post(`${baseurl}/pcbType/create`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        getPcb();
        setShow(false);
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const update = async (details, setSubmitting, setShow) => {
  await axios
    .post(`${baseurl}/pcbType/update`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        store.dispatch(updatePcbData(res.data.data.pcb));
        setShow(false);
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const remove = async (details, setSubmitting, setShow) => {
  await axios
    .post(`${baseurl}/pcbType/delete`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        setShow(false);
        store.dispatch(removePcbData(res.data.data.pcb));
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const getPcb = (Notification = true) => {
  axios
    .get(`${baseurl}/pcbType/get`, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        Notification && showSuccessMessage("", res.data.message, 2000);
        store.dispatch(loadPcbData(res.data.data.pcb));
      } else {
        Notification && showErrorMessage("", res.data.message, 2000);
      }
    })
    .catch(function (err) {
      Notification && showErrorMessage("", err.response.data.message, 2000);
    });
};
