import React from "react";
import { Image, Row } from "react-bootstrap";
const StepCard = ({ label, icon, step, setStep, selected }) => (
  <Row
    style={{
			margin: "5px 5px 5px 0px",
			padding: "5px",
			alignItems: "center",
			cursor: 'grab'
		}}
    className={`${selected ? 'blue_elevated': 'elevated'} rounded`}
    onClick={() => {
      setStep(step);
    }}
  >
    <Image src={icon} style={{ width: "40px", padding: "5px" }} />
    &nbsp;&nbsp;
    {label}
  </Row>
);

export default StepCard;
