import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { userListRolesUpdate } from '../../config/constants';
import { useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { updateEmployeeDetails } from '../../redux/actions/UserList';
import moment from 'moment'

export default function UpdateUserDetail({ user }) {
  const [selectedValue, setSelectedValue] = useState([])
  const [modelShow, setModelShow] = useState(false)
  let [userDetail, setUserDetail] = useState(user)
  useEffect(() => {
    setUserDetail(user)
    let role = []
    role = userDetail.roles.map((e) => {
      return { value: e }
    })
    setSelectedValue(role)
  }, [user, modelShow, userDetail.roles])
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    let roles = selectedValue.map(e => {
      return e.value
    })
    let value = {
      ...userDetail,
      roles: roles,
      emails: userDetail.emails.includes(",") ? userDetail.emails.split(",") : [userDetail.emails.toString()],
      phoneNumbers: userDetail.phoneNumbers.includes(",") ? userDetail.phoneNumbers.split(",") : [userDetail.phoneNumbers.toString()],
      userId: userDetail._id
    }
    setSelectedValue([])
    updateEmployeeDetails(value, user)
  };
  const handleChange = (event) => {
    setUserDetail({ ...userDetail, [event.target.name]: event.target.value })
  }
  return (
    <>
      <Button type='sm' onClick={() => setModelShow(true)}>Update</Button>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modelShow} onHide={() => {
          setModelShow(false)
          setUserDetail(user)
          setSelectedValue([])
        }} >
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type={"default"}
                    name='first_name'
                    value={userDetail.first_name}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Enter first name"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type={"default"}
                    name='last_name'
                    value={userDetail.last_name}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Enter last name"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Phone numbers</Form.Label>
                  <Form.Control
                    type={"default"}
                    name="phoneNumbers"
                    value={userDetail.phoneNumbers}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Comma separeted phone numbers"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Emails</Form.Label>
                  <Form.Control
                    type={"default"}
                    name="emails"
                    value={userDetail.emails}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Comma separated emails"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    type={"date"}
                    name='dob'
                    value={moment(userDetail.dob).format("YYYY-MM-DD")}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Comma separeted phone numbers"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Joining Date</Form.Label>
                  <Form.Control
                    type={"date"}
                    name='joiningDate'
                    value={moment(userDetail.joiningDate).format("YYYY-MM-DD")}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Comma separated emails"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />

            <br />
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Gender</Form.Label>
                  <Form.Control
                    type={"default"}
                    name='gender'
                    value={userDetail.gender}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Gender"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type={"default"}
                    name='username'
                    value={userDetail.username}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Username"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Aadhar Card</Form.Label>
                  <Form.Control
                    type={"default"}
                    name='aadharNumber'
                    value={userDetail?.aadharNumber}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Enter Aadhar Number"}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Pan Card</Form.Label>
                  <Form.Control
                    type={"default"}
                    name='pancardNumber'
                    value={userDetail?.pancardNumber}
                    onChange={(e) => handleChange(e)}
                    placeholder={"Enter Pan Number"}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Roles</Form.Label>
                  <Multiselect
                    displayValue="value"
                    selectedValues={selectedValue}
                    onRemove={setSelectedValue}
                    onSelect={setSelectedValue}
                    options={userListRolesUpdate}
                    style={{
                      width: "100px !important",
                      multiselectContainer: {
                        marginTop: "5px",
                        backgroundColor: "#ffffff"
                      },
                    }}
                  />


                </Form.Group>
              </Col>
             
            </Row>
            <br />
            <Row>

              <Col>
                <Button
                  type="button"
                  className="float-end m-2"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
                <Button
                  variant="success"
                  className="float-end m-2"
                  onClick={() => setModelShow(false)}
                >No</Button>

              </Col>
            </Row>
          </Form>
        </Modal.Body>

      </Modal>
    </>
  );
}
