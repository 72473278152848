import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import {
    accessTokenHeader,
    showErrorMessage,
    showSuccessMessage,
} from "../../utils/helpers";
import { useForm } from "react-hook-form";
import { apiRequest } from "../../utils/utils";
import { baseurl } from "../../config/constants";

function TestingStationModal({ fetchData, data = {}, text }) {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);

    let defaultValues = {
        deviceId: "",
        name: "",
        active: true,
        switch_pin_tester: "",
        fan_module_tester: "",
        energisync_tester: "",
        maintenance_log: [],
        wifi_configs: [],
        isMQTT: true,
    };

    if (Object.keys(data).length > 1) {
        Object.keys(defaultValues).map(
            (key) => (defaultValues[key] = data[key])
        );
    }

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: defaultValues,
    });

    async function onSubmit(data) {
        setLoading(true);

        const response = await apiRequest({
            url: `${baseurl}/testingStation/register`,
            method: "POST",
            data: data,
            headers: { ...accessTokenHeader() },
        });

        if (!response.success) {
            showErrorMessage(response.message);
            setLoading(false);
            return;
        }

        setLoading(false);
        showSuccessMessage(response.message);
        fetchData();
        setShow(false);
    }

    async function onUpdate(formData) {
        const finalData = formData;
        finalData.testingStationId = data._id;
        setLoading(true);
        const response = await apiRequest({
            url: `${baseurl}/testingStation/update`,
            method: "POST",
            data: finalData,
            headers: { ...accessTokenHeader() },
        });

        if (!response.success) {
            showErrorMessage(response.message);
            setLoading(false);
            return;
        }

        setLoading(false);
        showSuccessMessage(response.message);
        fetchData();
        setShow(false);
    }
    return (
        <>
            <>
                <Button
                    variant="primary"
                    size="sm"
                    // style={{ marginRight: "33px" }}
                    onClick={() => setShow(true)}
                >
                    {/* <Plus style={{ fontSize: "16px" }} /> */}
                    {text === "Update" ? "Update" : "Register New"}
                </Button>
            </>
            <Modal
                show={show}
                onHide={() => {
                    setShow(false);
                    // setInitialValues(data);
                }}
                size="lg"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {text === "Update"
                            ? "Update Testing Station"
                            : "Register Testing Station"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        className="p-2 pl-5 pb-4"
                        onSubmit={
                            text === "Update"
                                ? handleSubmit(onUpdate)
                                : handleSubmit(onSubmit)
                        }
                    >
                        <Row>
                            <Col md={6}>
                                {/* Name */}
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter name"
                                        {...register("name", {
                                            required: "Name is required",
                                        })}
                                        isInvalid={!!errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col>
                                {/* Device Id */}
                                <Form.Group
                                    controlId="deviceId"
                                    className="mb-3"
                                >
                                    <Form.Label>Device ID</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeHolder="Enter Device ID"
                                        {...register("deviceId", {
                                            required: "Device ID is required",
                                        })}
                                        readOnly={text === "Update"}
                                        isInvalid={!!errors.deviceId}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.deviceId?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {/* Active Select */}
                                <Form.Group controlId="active" className="mb-3">
                                    <Form.Label>Active</Form.Label>
                                    <Form.Control
                                        as="select"
                                        {...register("active")}
                                    >
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col>
                                {/* MQTT Select */}
                                <Form.Group controlId="isMQTT" className="mb-3">
                                    <Form.Label>Is MQTT</Form.Label>
                                    <Form.Control
                                        as="select"
                                        {...register("isMQTT")}
                                    >
                                        <option value="true">True</option>
                                        <option value="false">False</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {/* Energisync Tester Field */}
                                <Form.Group
                                    controlId="energisync_tester"
                                    className="mb-3"
                                >
                                    <Form.Label>Energisync Tester</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Energisync Tester"
                                        {...register("energisync_tester")}
                                    />
                                </Form.Group>
                            </Col>

                            <Col>
                                {/* Fan Module Tester Field */}
                                <Form.Group
                                    controlId="fan_module_tester"
                                    className="mb-3"
                                >
                                    <Form.Label>Fan Module Tester</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Fan Module Tester"
                                        {...register("fan_module_tester")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                {/* Switch Pin Tester Field */}
                                <Form.Group
                                    controlId="switch_pin_tester"
                                    className="mb-3"
                                >
                                    <Form.Label>Switch Pin Tester</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Switch Pin Tester"
                                        {...register("switch_pin_tester")}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <div className="mt-4">
                            {loading ? (
                                <Button
                                    variant="primary"
                                    style={{ width: "100%" }}
                                >
                                    <Spinner
                                        animation="border"
                                        variant="light"
                                        size="sm"
                                    />
                                </Button>
                            ) : (
                                <Button
                                    variant="primary"
                                    type="submit"
                                    style={{ width: "100%" }}
                                >
                                    Submit
                                </Button>
                            )}
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default TestingStationModal;
