import { TestingStationActions, UserActions } from "../actionTypes";

const initialState = {
	list: [],
	lastUsed: 0,
	loading: false,
}
const testingStations = (state = initialState, action) => {
	const {type, payload} = action;
	switch (type) {
		case TestingStationActions.USED:
			return {
				...state,
				lastUsed: payload,
			};

		case TestingStationActions.LOAD:
			return {
				...state,
				list: payload,
				loading: false,
			}

		case TestingStationActions.LOADING:
			return {
				...state,
				loading: payload,
			}

		case UserActions.LOGOUT:
			return initialState;

		default:
			return {
				...state,
			}
	}
};

export default testingStations;
