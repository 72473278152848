import React, { useEffect, useState } from "react";
import { Row, Col, Table, Stack } from "react-bootstrap";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";
import AddNewDeviceType from "../../components/AddNewDeviceType";
import { getDeviceTypesList } from "../../redux/actions/deviceTypesTrack";

export default function DeviceType() {
  const [deviceTypes, setDeviceTypes] = useState([]);

  const [refreshData, setRefereshData] = useState(true);

  useEffect(() => {
    getDeviceTypesList().then((res) => {
      if (res.success) {
        let types = res?.data?.deviceTypes;
        types.sort((t1, t2) => {
          if (t1.active !== t2.active) {
            // one is active other is not
            if (t1.active) return -1;
            return 1;
          } else {
            return String(t1.name).localeCompare(t2.name);
          }
        })
        setDeviceTypes(res?.data?.deviceTypes);
      }
    });
  }, [refreshData]);
  return (
    <Row>
      <Col md={10} className="rounded elevated">
        <Row className="mt-2 mb-2">
          <Col md={10}>
            <TestStepsHeader
              icon={Images.MenuImages.PcbType}
              title={"Device Types"}
            />
          </Col>
          <Col
            md={2}
            className="d-flex justify-content-end align-items-center"
            style={{ borderBottom: "0.2px solid black", marginBottom: "15px" }}
          >
            <AddNewDeviceType
              data={{}}
              text="Add New"
              updateDevicesList={setDeviceTypes}
              refreshData={refreshData}
              setRefereshData={setRefereshData}
            />
          </Col>
        </Row>
        <Row className="m-0">
          <Col
            md={12}
            className="customScrollbar"
            style={{ height: "70vh", overflow: "scroll" }}
          >
            <Table striped bordered hover variant="light">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Device Code</th>
                  <th>Status</th>
                  <th>Last Id</th>
                  <th>Description</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {deviceTypes &&
                  deviceTypes[0] !== null &&
                  deviceTypes.sort((a, b) => {
                    if (a.active === b.active) {
                      //time based sorting
                      if (new Date(a.createdAt) < new Date(b.createdAt)) {
                      return 1
                    }
                    if (new Date(a.createdAt) > new Date(b.createdAt)) {
                      return -1;
                    }
                    }
                    if (a.active === true) return -1;
                    return 1;
                  }).map((device, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{device?.name}</td>
                        <td>{device?.deviceCode}</td>
                        <td>{device?.active ? "active" : "OFF"}</td>
                        <td>{device?.lastAssignedId}</td>
                        <td>{device?.description}</td>
                        <td>
                          <Stack gap={3} direction="horizontal">
                            <AddNewDeviceType
                              data={device}
                              text="Update"
                              updateDevicesList={setDeviceTypes}
                              refreshData={refreshData}
                              setRefereshData={setRefereshData}
                            />
                          </Stack>
                        </td>
                      </tr>
                    );
                    
                  })
                  }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
