import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { transferfSelectedProduct } from '../../redux/actions/affilatedProduct';
import {
    MovementTransactionsType,
    MovementsType,
    StockStores,
} from "../../config/constants";
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { showErrorMessage } from '../../utils/helpers';

function TransferStock({ selectedProductId }) {
    const installationUsers = useSelector(state => state.usersLists.installationUsers)
    const selectedProductDetails = useSelector(state => state.selectedProductDetails)
    const [show, setShow] = useState(false);
    const [sure, setSure] = useState(false)
    const [type, setType] = useState(`${Object.values(MovementsType)[0]}`)
    const [transfeBtn, setTransfeBtn] = useState(false)
    const [from, setFrom] = useState("")
    const [to, setTO] = useState('')
    const [travelStock, setTravelStock] = useState(0)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseSure = () => setSure(false);
    const handleShowSure = () => setSure(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Transfer Stock
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Transfer Stock</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <br />
                    <div>
                        <Form.Group >
                            <Form.Label>Select From</Form.Label>
                            <Form.Select
                                aria-label="Select movement type"
                                onChange={(e) => {
                                    if (e.target.value !== "Select From") {
                                        setFrom(e.target.value)
                                    }
                                }}
                            >
                                <option>Select From</option>
                                {selectedProductDetails && selectedProductDetails.map((ds, index) => {
                                    let key = Object.keys(ds)
                                    let d = Object.keys(ds)
                                    if (isNaN(key[0]) === false) {
                                        let f = installationUsers.filter(e => e.employeeId === key[0])
                                        if (f.length !== 0) {
                                            d = [f[0].first_name]
                                        }
                                    }
                                    return (
                                        <option value={key[0]}>{d[0]}</option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>
                        <br />
                        <Form.Group>
                            <Form.Label>Select type</Form.Label>
                            <Form.Select
                                aria-label="Select movement type"
                                onChange={(e) => {
                                    if (e.target.value !== "Select type") {
                                        let typee = MovementsType[e.target.value]
                                        setType(Number(typee))

                                    }
                                }}
                            >
                                <option>Select type</option>
                                {Object.keys(MovementsType).map((key, index) => (
                                    <option value={key}>{key}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </div>
                    <br />
                    {[
                        MovementTransactionsType.Electrician,

                    ].includes(Number(type)) && (
                            <div>
                                <Form.Group>
                                    <Form.Label>Select Electrician</Form.Label>
                                    <Form.Select
                                        aria-label="Select movement type"
                                        onChange={(e) => {
                                            if (e.target.value !== "Select Electrician") {
                                                setTO(e.target.value)
                                            }
                                        }}
                                    >
                                        <option>Select Electrician</option>
                                        {installationUsers.map((user) => (
                                            <option value={`${user.employeeId}`}>
                                                {user.employeeId} - {user.first_name}  {user.last_name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <br />
                            </div>
                        )}
                    {![MovementsType.Electrician, MovementsType.Stock, MovementsType.Restocked, MovementsType.Returned].includes(
                        Number(type)
                    ) && (
                            <div>
                                <Form.Group>
                                    <Form.Label>Enter name</Form.Label>
                                    <Form.Control
                                        value={to}
                                        onChange={(e) => setTO(e.target.value)}
                                        placeholder={"Site name / Other name"}
                                    />
                                </Form.Group>
                                <br />
                            </div>
                        )}
                    {Number(type) === Number(MovementsType.Stock) && (
                        <div>
                            <Form.Group>
                                <Form.Label>Select Name</Form.Label>
                                <Form.Select
                                    aria-label="Select movement type"
                                    onChange={(e) => setTO(e.target.value)}
                                >
                                    {Object.keys(StockStores).map(store => <option value={StockStores[store]}>{StockStores[store]}</option>)}

                                </Form.Select>
                            </Form.Group>
                            <br />
                        </div>
                    )}

                    <div>
                        <Form.Group>
                            <Form.Label>Stock Count</Form.Label>
                            <Form.Control
                                value={travelStock}
                                onChange={(e) => setTravelStock(Number(e.target.value))}
                                placeholder={"Stock Count"}
                                type="number"
                            />
                        </Form.Group>
                        <br />
                        <Button

                            disabled={transfeBtn}
                            onClick={() => {
                                if (type === MovementsType.Restocked) {
                                    setTO("ReStocked")
                                }
                                if (type === MovementsType.Returned) {
                                    setTO("Returned")
                                }
                                if (selectedProductId === undefined || selectedProductId === "") {
                                    showErrorMessage('', "Product Not Selected", 2000)
                                    return
                                }
                                if (travelStock === undefined || travelStock === 0) {
                                    showErrorMessage('', "Stock Count not added", 2000)
                                    return
                                }
                                if (to === "" || to === undefined || from === "" || from === undefined) {
                                    showErrorMessage('', "Transfer Details not added correctly", 2000)
                                    return
                                }
                                handleShowSure()

                            }}>Transfer</Button>
                    </div>

                </Modal.Body>

            </Modal>
            <Modal show={sure} onHide={handleCloseSure}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            From :- {from}
                        </Col>
                        <Col>
                            to :- {to}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Transfer Stock :- {travelStock}
                        </Col>

                    </Row>
                </Modal.Body>


                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            handleCloseSure()
                        }}
                    >Close</Button>
                    <Button
                        variant="primary"
                        disabled={transfeBtn}
                        onClick={() => {
                            setTransfeBtn(true)
                            transferfSelectedProduct(selectedProductId, travelStock, to, from, type).then(() => setTransfeBtn(false)).catch(() => setTransfeBtn(false))
                            handleCloseSure()
                            handleClose()
                        }}
                    >Save changes</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default TransferStock;