import React from "react";
import { Badge, Button } from "react-bootstrap";
import { TestingType, WarningReparirsLimit } from "../config/constants";
import Images from "../config/images";
import TestStepsHeader from "./TestStepsHeader";

export const DeviceProblemCard = ({ log, onResolve, index }) => {
  let type =
    Object.keys(TestingType).find(
      (key) => Number(TestingType[key]) === Number(log.type)
    ) || "";
  return (
    <div
      className="rounded elevated"
      style={{ padding: "0px", overflow: "hidden" }}
    >
      <div
        style={{
          fontSize: "16px",
          borderBottom: "0.5px solid black",
          padding: "15px",
        }}
      >
        <strong>
          {" "}
          {log.reason} - {type}{" "}
        </strong>
      </div>
      <div style={{ padding: "15px", borderBottom: "0.5px solid black" }}>
        {new Date(log.date).toLocaleString()} <br />
        {log.message}
      </div>
      <div style={{ padding: "0px 0px 0px" }}>
        <Button
          style={{ width: "100%", borderRadius: 0 }}
          variant={log.resolved ? "success" : "primary"}
          onClick={() => {
            onResolve(log, index);
          }}
        >
          {log.resolved || log.passed ? "Resolved" : "Mark as Resolved"}
        </Button>
      </div>
    </div>
  );
};

const DeviceProblemsList = ({ device, onResolve, type }) => {
  const numberOfRepairs = device?.testing_history
    ? device.testing_history.filter((th) => th.type === TestingType.Repair)
        .length
    : 0;
  return (
    <div
      style={{ maxHeight: "90vh", overflowY: "scroll" }}
      className={"invisibleScrollbar"}
    >
      <TestStepsHeader
        icon={Images.HardwareTesting.Problems}
        title={"Problems"}
      />
      {numberOfRepairs >= WarningReparirsLimit && (
        <>
          <br />
          <h2>
            <center>
              <Badge bg="danger">
                Repaired {numberOfRepairs} times already
              </Badge>
            </center>
          </h2>
          <br />
        </>
      )}
      {device &&
        device.testing_history &&
        device.testing_history
          .map((log, index) => ({ ...log, logIndex: index }))
          .filter(
            (log) =>
              true ||
              !type ||
              (log && log.type === type && log.passed === false)
          )
          .sort((log1, log2) => {
            if (log1.resolved === false && log2.resolved === true) {
              return -1;
            } else if (log1.resolved === true && log2.resolved === false) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((log, index) => (
            <DeviceProblemCard
              key={`${index}.${log._id}`}
              index={log.logIndex}
              log={log}
              onResolve={onResolve}
            />
          ))}
    </div>
  );
};

export default DeviceProblemsList;
