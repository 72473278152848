import moment from 'moment'
import React from 'react'
import { Stack } from 'react-bootstrap'
import ChangePassword from '../screens/Users/ChangePassword'
import UpdateUserDetail from '../screens/Users/UpdateUserDetail'
import UserDelete from '../screens/Users/UserDelete'
import TestStepsHeader from './TestStepsHeader'
import Images from '../config/images'

function UserDetailsShow({ user }) {
  return (
    <div className="rounded elevated p-2 overflow-hidden">
      <TestStepsHeader
							icon={Images.MenuImages.Users}
							title={"User Detail"}
						/>
      <Stack direction='horizontal' gap="3" className='align-items-start'>
        <div className="p-2 w-25">Username</div>
        <div className="p-2">{user.username}</div>
      </Stack>
      <Stack direction='horizontal' gap="3" className='align-items-start'>
        <div className="p-2 w-25">D.O.B</div>
        <div className="p-2">{moment(user.dob).format('DD-MMM-YYYY')}</div>
      </Stack>
      <Stack direction='horizontal' gap="3" className='align-items-start'>
        <div className="p-2 w-25">Joining Date</div>
        <div className="p-2">{moment(user.joiningDate).format('DD-MMM-YYYY')}</div>
      </Stack>
      <Stack direction='horizontal' gap="3" className='align-items-start'>
        <div className="p-2 w-25">Phone</div>
        <div className="p-2">
          <ul>
            {
              user.phoneNumbers && user.phoneNumbers.map((phone) => (
                <li>{phone}</li>
              ))
            }
          </ul>
        </div>
      </Stack>
      <Stack direction='horizontal' gap="3" className='align-items-start'>
        <div className="p-2 w-25">Emails</div>
        <div className="p-2">
          <ul>
            {
              user.emails && user.emails.map((email) => (
                <li>{email}</li>
              ))
            }
          </ul>
        </div>
      </Stack>
      <Stack direction='horizontal' gap="3" className='align-items-start'>
        <div className="p-2 w-25">Roles</div>
        <div className="p-2">
          <ul>
            {
              user.roles && user.roles.map((role) => (
                <li>{role}</li>
              ))
            }
          </ul>
        </div>
      </Stack>
      <Stack direction='horizontal' gap="3" className='align-items-start'>
        <ChangePassword userDetail={user} />
        <UpdateUserDetail user={user} />
        <UserDelete userDetail={user} />
      </Stack>
    </div>
  )
}

export default UserDetailsShow