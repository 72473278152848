import React, { useEffect, useState } from "react";
import { getUserList } from "../../redux/actions/UserList";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Table } from "react-bootstrap";

import UserDetailsShow from "../../components/UserDetailsShow";
import Images from "../../config/images";
import TestStepsHeader from "../../components/TestStepsHeader";

export default function UserListShow() {
  const userList = useSelector((state) => state.userList);
  const [userData, setUserData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    getUserList(dispatch);
  }, [dispatch]);
  return (
    <div>
      <Row>
        <Col md={4} className="rounded elevated">
          <TestStepsHeader
            icon={Images.MenuImages.Users}
            title={"Users List"}
          />
          {userList.success === true && userList.data.users.length !== 0 ? (
            <div
              className="customScrollbar"
              style={{ height: "70vh", overflow: "scroll" }}
            >
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Employee Id</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  {userList.data.users
                    .sort((a, b) => (a.first_name > b.first_name ? 1 : -1))
                    .map((user, index) => {
                      return (
                        <tr onClick={() => setUserData(user)}>
                          <td>{index + 1}</td>
                          <td>{user.employeeId}</td>
                          <td>
                            {user.first_name} {user.last_name}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          ) : null}
        </Col>
        <Col md={6}>
          {Object.keys(userData).length !== 0 ? (
            <UserDetailsShow user={userData} />
          ) : null}
        </Col>
      </Row>
    </div>
  );
}
