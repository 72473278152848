import React from "react";
import { Badge, Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import DeviceConnectionCard from "../../components/DeviceConnectionCard";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";

const Details = ({
  onStepComplete,
  switchpinConnectionStatus,
  deviceConnectionStatus,
  device,
  testingStation,
}) => {
  return (
    <div>
      <TestStepsHeader
        icon={Images.HardwareTesting.Details}
        title={"Device Details"}
      />
      <Row>
        <Col>
          <DeviceConnectionCard details={deviceConnectionStatus} />
        </Col>
        <Col>
          <DeviceConnectionCard details={switchpinConnectionStatus} />
        </Col>
      </Row>
      <br />
      {device && device.discarded === true && (
        <center>
          <h4>
            <Badge bg={"danger"}>Device has been marked as discarded</Badge>
          </h4>
        </center>
      )}
      {device && device.hardware_tested === true && (
        <center>
          <h4>
            <Badge bg={"success"}>Has already passed hardware test</Badge>
          </h4>
        </center>
      )}
      {device && device.basic_tested === false && (
        <center>
          <h4>
            <Badge bg={"danger"}>Has not passed basic test</Badge>
          </h4>
        </center>
      )}
      <div style={{ float: "right", marginRight: 15 }}>
        <Button title="Start Testing" onClick={onStepComplete}>
          Start Testing
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  device: state.currentSyncTest.device,
  testingStation: state.currentSyncTest.testingStation,
});

export default connect(mapStateToProps)(Details);
