import axios from "axios";
import { baseurl } from "../../config/constants";
import { accessTokenHeader } from "../../utils/helpers";
import { HistoryActions } from "../actionTypes";

const load = (
  payload = { deviceId: "", history: { movements: [], testing: {} } }
) => ({
  type: HistoryActions.LOAD,
  payload,
});

export const loadHistory = (deviceId) => (dispatch) => {
  return axios
    .get(`${baseurl}/testing/history/${deviceId}`, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(
      (response) => {
        const { data } = response.data;
        dispatch(load({ deviceId, history: data }));
        return response.data;
      },
      (error) => {
        const message = error.response.data
          ? error.response.data.message
          : String(error);

        return {
          success: false,
          message,
          data: error?.response?.data?.data,
        };
      }
    )
    .catch((error) => {
      return {
        success: false,
        message: String(error),
      };
    });
};
