import { useState } from 'react';

import { userDeleteApi } from '../../redux/actions/UserList';

import { Button, Modal } from 'react-bootstrap';

export default function UserDelete({ userDetail }) {
    const [show, setShow] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false)


    const userDelete = async () => {
        await userDeleteApi(userDetail, setShow)
        setSubmitting(false)
    }
    return (
        <>
            <Button
                variant='danger'
                onClick={() => {
                    setShow(true)
                }}
            >Delete</Button>
            <Modal
                show={show}
                onHide={() => {
                    setShow(false)
                    setSubmitting(false)
                }}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Delete User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {`Are you sure you want to delete "${userDetail.first_name}" ?`}

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="success"
                        size="sm"
                        onClick={() => setShow(false)}
                    >No</Button>
                    <Button
                        variant="danger"
                        size="sm"
                        onClick={() => {
                            userDelete()
                            setSubmitting(true)
                        }}
                        disabled={isSubmitting}
                    >Yes</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
