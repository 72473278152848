import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import TestStepsHeader from '../../components/TestStepsHeader'
import Images from '../../config/images'
import axios from 'axios';
import { saveAs } from 'file-saver';
import { showErrorMessage } from '../../utils/helpers';

export default function DeviceIdPdf() {
  const [series, setSeries] = useState('');
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [mode, setMode] = useState('series')
  const [custom, setCustom] = useState('');
  const [loading, setLoading ] = useState(false);

  const handleSubmit = async () => {
    let deviceIds;

    if (mode === 'series') {
    if (series === "" ) {
      showErrorMessage("Enter appropriate data")
      return;
    }

    if (start.length > 4 || end.length > 4) {
      showErrorMessage("Not a valid length");
      return;
    }

    if (start > end) {
      showErrorMessage("Start number must be less than end number");
      return;
    }

    deviceIds = generateArray();

  } else {
    deviceIds = custom.split(',');
  }
  setLoading(true);
  axios.post(`https://subscriptioncloud.alistetechnologies.com/v3/pdf/deviceIdsPdf`, {deviceIds}, { responseType: 'blob',
    headers: {
      Accept: 'application/pdf',
    },}).then((res) => {
      let blob = new Blob([res.data], {
      type: 'application/pdf',
    });
    saveAs(blob, 'deviceId.pdf')
    setLoading(false);
  }).catch(() => {setLoading(false)});
  }

  
  function generateArray () {
    const arr = [];
    for (let i=start; i<=end; i++) {
      arr.push(`${series}${String(i).padStart(4, '0')}`)
    }
    return arr;
  }

  return (
    <Row className={"g-0 workAreadDiv invisibleScrollbar"}>
      <Col lg={6}>
        <div className="section_container rounded elevated">
          <TestStepsHeader
							icon={Images.MenuImages.DeviceIdsPdf}
							title={"DeviceIds PDF"}
						/>
            <Form.Group>
              <Row>
                <Col>
                  <Form.Text>Choose Print Type</Form.Text>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Select aria-label="Choose Type" onChange={(e) => setMode(e.target.value)}
                  defaultValue={mode}
                  >
                    <option value={"custom"}>Custom</option>
                    <option value={"series"}>Series</option>
                  </Form.Select>
                </Col>
              </Row>
              <br />
            {mode === "series" && 
            <>
              <Row>
                <Col>
                  <Form.Text>Series</Form.Text>
                </Col>
                <Col>
                  <Form.Text>Start Id</Form.Text>
                </Col>
                <Col>
                  <Form.Text>End Id</Form.Text>
                </Col>
              </Row>
            
              <Row>
                <Col>
									<Form.Control
										value={series}
										type={'text'}
										onChange={(e) => setSeries(e.target.value)}
										placeholder={"Series"}
                    required
									/>
								</Col>
								<Col>
									<Form.Control
										value={start}
										type={'number'}
										onChange={(e) => setStart(e.target.value)}
										placeholder={"Start ID"}
                    required
									/>
								</Col>
								<Col>
									<Form.Control
										value={end}
										type={'number'}
										onChange={(e) => setEnd(e.target.value)}
										placeholder={"End ID"}
                    required
									/>
								</Col>
							</Row>
              </>
}
          {mode === 'custom' && (
            <>
            <Row>
              <Col>
              <Form.Label>Add a message for logs:</Form.Label>
              <Form.Control
                as={"textarea"}
                rows={5}
                placeholder={"Comma Seperated Device Ids"}
                onChange={(e) => {
                  setCustom(e.target.value);
                }}
              />
              </Col>
              </Row>
            </>
          )}
            </Form.Group>
            <br />
						<Button onClick={handleSubmit}  disabled={loading}>Download PDF</Button>
            <br />
        </div>
      </Col>
    </Row>
  )
}
