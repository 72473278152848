import "../../styles/Login.css";
import React, { useState } from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import logo from "../../assets/logo.png";

import { Col, Container, Row } from "react-bootstrap";
import Strings from "../../globals/strings";
import { login } from "../../redux/actions/user";
const LoginPage = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const handleSubmit = async () => {
  //  setLoading(true);  
		const response = await props.login(username, password);
    if (!response.success) {
      setMessage(response.message);
    }
  //  setLoading(false);
	};

  return (
    <div style={{ backgroundColor: "black", height: "100vh" }}>
      <Container fluid>
        <Row>
          <Col></Col>
          <Col>
            <div className="login-form">
              <Form onSubmit={handleSubmit}>
                <div style={{ textAlign: "center", marginBottom: "5%" }}>
                  <img src={logo} width="20%" style={{ marginBottom: "2%" }} alt={'Aliste Logo'} />
                  <h3>{Strings.PortalTitle}</h3>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "10%",
                    fontSize: "15px",
                  }}
                >
                  Centralized Testing
                  <br />
                  <div style={{ whiteSpace: "nowrap", overflow: "hidden" }}>
                    Seamless, Smart, Efficient
                  </div>
                </div>
                <Form.Group className="input-wrapper" controlId="username">
                  <input
                    size="lg"
                    className="input"
                    onChange={(e) => setUsername(e.target.value)}
                    type="username"
                    placeholder="Username"
                  />
                </Form.Group>
                <Form.Group
                  className="input-wrapper"
                  controlId="formBasicPassword"
                >
                  <input
                    size="lg"
                    className="input"
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    type="password"
                    placeholder="Password"
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        handleSubmit();
                      }
                    }}
                  />
                </Form.Group>
              </Form>
              <button onClick={handleSubmit} className="button">
                LOGIN
              </button>
              {message && <div style={{ color: "red" }}>{message}</div>}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps, {login})(LoginPage);
