import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { logout } from "../redux/actions/user";
import { getProfilePicUrl } from "../utils/helpers";

const Header = ({ user, logout }) => {
  const today = new Date();
  const dob = new Date(user.dob);
  const birthday = today.getDate() === dob.getDate() && today.getMonth() === dob.getMonth();
  return (
    <div
      style={{
        padding: '15px',
        borderBottom: "0.5px solid black",
        maxHeight: '10vh',
        height: '10vh',
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Row>
        <Col>
          <span style={{ fontSize: 24, fontWeight: 500 }}>
            Aliste Technologies Pvt. Ltd
          </span>
        </Col>
        <Col>
          <div style={{ float: "right", marginRight: '20px' }}>
            <Image src={getProfilePicUrl(user.profile_pic)} roundedCircle={true} thumbnail={true} style={{ width: 45, height: 45, marginRight: '15px' }} />
            <span style={{ marginRight: 15 }}>
              {birthday ? "Happy Birthday" : "Welcome"}, <strong> {user.first_name} ! </strong>
            </span>
            <Button size="sm" variant={"primary"} onClick={logout}>Logout</Button>
            <span style={{ fontSize: 14, fontWeight: 400 }}>
              &nbsp;&nbsp;&nbsp;v{process.env.REACT_APP_VERSION}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.profile,
});

export default connect(mapStateToProps, { logout })(Header);
