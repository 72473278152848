import axios from "axios";
import { baseurl } from "../../config/constants";
import { accessTokenHeader } from "../../utils/helpers";
import { showErrorMessage, showSuccessMessage } from "../../utils/helpers";
import { AssemblerAction } from "../actionTypes";
import store from "../source";

const loadAssemblerData = (data) => {
  return {
    type: AssemblerAction.LOAD,
    payload: data,
  };
};
const updateAssemblerData = (data) => {
  return {
    type: AssemblerAction.UPDATE,
    payload: data,
  };
};
const removeAssemblerData = (data) => {
  return {
    type: AssemblerAction.REMOVE,
    payload: data,
  };
};

export const add = async (details, setSubmitting, setShow) => {
  await axios
    .post(`${baseurl}/assembler/create`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        getAssembler();
        setShow(false);
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const update = async (details, setSubmitting, setShow) => {
  await axios
    .post(`${baseurl}/assembler/update`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        store.dispatch(updateAssemblerData(res.data.data.assembler));
        setShow(false);
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const remove = async (details, setSubmitting, setShow) => {
  await axios
    .post(`${baseurl}/assembler/delete`, details, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message, 2000);
        setShow(false);
        store.dispatch(removeAssemblerData(res.data.data.assembler));
      } else {
        showErrorMessage("", res.data.message, 2000);
      }
      setSubmitting(false);
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message, 2000);
      setSubmitting(false);
    });
};
export const getAssembler = (Notification = true) => {
  axios
    .get(`${baseurl}/assembler/get`, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success === true) {
        Notification && showSuccessMessage("", res.data.message, 2000);
        store.dispatch(loadAssemblerData(res.data.data.assembler));
      } else {
        Notification && showErrorMessage("", res.data.message, 2000);
      }
    })
    .catch(function (err) {
      Notification && showErrorMessage("", err.response.data.message, 2000);
    });
};
