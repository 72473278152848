import { CurrentFanModuleTestActions, UserActions } from "../actionTypes";

const initalState = {
	device: undefined,
	testingStation: undefined,
};
const currentFanModuleTest = (
	state = initalState,
	action
) => {
	const {type, payload} = action;
	switch (type) {

		case CurrentFanModuleTestActions.SET_TESTING_STATION:
			return {
				...state,
				testingStation: payload
			}

		case UserActions.LOGOUT:
			return initalState;

		default:
			return state;
	}
}

export default currentFanModuleTest;
