import React from 'react';
import { Badge, Col, Row } from "react-bootstrap"

const TestingStatusBox = ({ tested, tester, passed_date, passing_message, test_name }) => {
	return (
		<div className={'rounded elevated'} style={{margin: '0px'}}>
			<h6>{test_name}</h6>
			<hr />
			<Row>
				<Col>
					Passed:
				</Col>
				<Col>
					<h5>
						<Badge bg={tested ? 'success' : 'danger'}>
							{tested ? "Yes" : "No"}
						</Badge>
					</h5>
				</Col>
			</Row>
			<Row>
				<Col>
					Tested By:
				</Col>
				<Col>
					{tested ? `${tester?.first_name} ${tester?.last_name}` : "Not passed yet"}
				</Col>
			</Row>
			<Row>
				<Col>
					Passed on:
				</Col>
				<Col>
					{tested ? new Date(passed_date).toLocaleDateString() : ""}
				</Col>
			</Row>
			<Row>
				<Col>
					Message:
				</Col>
				<Col>
					{tested ? passing_message : ""}
				</Col>
			</Row>
		</div>
	)
}

export default TestingStatusBox;
