import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DeviceProblemsList from "../components/DeviceProblemsList";
import TestingStatusBox from "../components/TestingStatusBox";
import TestStepsHeader from "../components/TestStepsHeader";
import Images from "../config/images";
import TestingActions from "../redux/actions/testing";
import { showErrorMessage } from "../utils/helpers";

class DeviceTestingStatusPage extends Component {
	state = {
		status: {
			"_id": "636517ca4d64f7241b1b6ccc",
			"deviceId": "",
			"smps_tested": true,
			"smps_tester": {
				"_id": "62fb55568dafe52f2700005f",
				"first_name": "Shreyansh",
				"last_name": "Jain",
				"employeeId": "A04"
			},
			"smps_passing_message": "by passed",
			"basic_tested": true,
			"basic_tester": {
				"_id": "62fb55568dafe52f2700005f",
				"first_name": "Shreyansh",
				"last_name": "Jain",
				"employeeId": "A04"
			},
			"basic_passing_message": "by passed",
			"hardware_tested": true,
			"hardware_tester": {
				"_id": "62fb55568dafe52f2700005f",
				"first_name": "Shreyansh",
				"last_name": "Jain",
				"employeeId": "A04"
			},
			"hardware_passing_message": "by passed",
			"online_tested": true,
			"online_testing_station": "62fbb42e8dafe52f2700007c",
			"online_passing_message": "by passed",
			"registration_date": "2022-11-04T13:46:50.978Z",
			"registered_by": "62fb55568dafe52f2700005f",
			"discarded": false,
			"discarding_reason": "",
			"discarded_by": "",
			"discarding_message": "",
			"discarded_date": null,
			"createdAt": "2022-11-04T13:46:50.980Z",
			"updatedAt": "2022-11-05T06:55:01.286Z",
			"__v": 0,
			"basic_passed_date": "2022-11-05T06:55:01.271Z",
			"basic_testing_station": "62fbb42e8dafe52f2700007c",
			"hardware_passed_date": "2022-11-05T06:55:01.271Z",
			"hardware_testing_station": "62fbb42e8dafe52f2700007c",
			"online_passed_date": "2022-11-05T06:55:01.271Z",
			"online_tester": {
				"_id": "62fb55568dafe52f2700005f",
				"first_name": "Shreyansh",
				"last_name": "Jain",
				"employeeId": "A04"
			},
			"smps_passed_date": "2022-11-05T06:55:01.271Z",
			"smps_testing_station": "62fbb42e8dafe52f2700007c"
		},
		fetching: false,
		deviceId: '',
		houseDetails: {},
	}


	render() {
		const { deviceId, status } = this.state;
		let testingStatusDetails = [
			{
				testing_name: 'SMPS Testing',
				tested: status.smps_tested,
				tester: status.smps_tester,
				passed_date: status.smps_passed_date,
				passing_message: status.smps_passing_message,
			},
			{
				testing_name: 'Basic Testing',
				tested: status.basic_tested,
				tester: status.basic_tester,
				passed_date: status.basic_passed_date,
				passing_message: status.basic_passing_message,
			},
			{
				testing_name: 'Hardware Testing',
				tested: status.hardware_tested,
				tester: status.hardware_tester,
				passed_date: status.hardware_passed_date,
				passing_message: status.hardware_passing_message,
			},
			{
				testing_name: 'Online Offline Testing',
				tested: status.online_tested,
				tester: status.online_tester,
				passed_date: status.online_passed_date,
				passing_message: status.online_passing_message,
			}
		]
		return (
			<Row className={'g-0'}>
				<Col lg={3}>
					<div className='section_container'>
						<TestStepsHeader
							icon={Images.TestingStatus.EnterDeviceId}
							title={"Enter Device ID"}
						/>
						<Form.Group>
							<Form.Control
								value={deviceId}
								onChange={this.handleDeviceIDChanged}
								placeholder={'Device ID'}
							/>
						</Form.Group>
						<br />
						<Button onClick={this.handleGetDeviceDetails}>
							Get Details
						</Button>
						<br />
					</div>
				</Col>
				<Col 
					lg={6} 
					style={{
						borderLeft: "0.5px solid black",
						borderRight: "0.5px solid black",
					}}
					>
					<div className="section_container workAreaDiv invisibleScroll">
						<TestStepsHeader
							icon={Images.MenuImages.TestingStatus}
							title={"Testing status"}
						/>
						<Row>
							{testingStatusDetails.map(details => {
								return (
									<Col key={details.testing_name} lg={6}>
										<TestingStatusBox
											test_name={details.testing_name}
											tested={details.tested}
											tester={details.tester}
											passed_date={details.passed_date}
											passing_message={details.passing_message}
										/>
									</Col>
								)
							})}
						</Row>
					</div>
				</Col>
				<Col lg={3}>
					<div className='section_container'>
						<DeviceProblemsList
							device={status}
							onResolve={() => { }}
						/>
					</div>
				</Col>

			</Row>
		)
	}

	handleGetDeviceDetails = async () => {
		this.setState({ fetching: true })
		const { success, message, data } = await TestingActions.testingStatus(this.state.deviceId);
		if (!success) {
			return showErrorMessage("Could not get device testing status", message);
		}
		this.setState({ status: data.device });
	}

	handleDeviceIDChanged = (e) => {
		this.setState({ deviceId: e.target.value.toUpperCase().replace(/[^0-9a-z]/gi, '') })
	}
}

export default DeviceTestingStatusPage;
