import { OnlineCountActions } from "../actionTypes";

const onlineCount = (state = {}, action) => {
	const {type, payload} = action;
	switch (type) {
		case OnlineCountActions.UPDATE:
			return payload;
		default:
			return state;
	}
}

export default onlineCount;
