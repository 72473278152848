import React from "react";
import { Image, Row } from "react-bootstrap";

const TestStepsHeader = ({ icon, title }) => {
  return (
    <Row style={{borderBottom: '0.2px solid black', alignItems: 'center', marginBottom: '15px', padding: '15px 0px', fontSize: '26px'}}>
        <Image src={icon} style={{ width: "60px" }} />
        	&nbsp;&nbsp;&nbsp;{title}
    </Row>
  );
};

export default TestStepsHeader;
