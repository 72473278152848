import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";

function DeletePcb({ data, fun, text = "" }) {
  const [show, setShow] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  return (
    <>
      <>
        <Button
          variant="primary"
          size="sm"
          style={{ marginRight: "33px" }}
          onClick={() => setShow(true)}
        >
          Delete
        </Button>
      </>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setSubmitting(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {/* {data.name !== undefined ? "Delete Pcb" : "Delete Pcb Version"} */}
            {text}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`Are you sure you want yo delete "${
            data.name === undefined ? data.versionName : data.name
          }" ?`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" size="sm" onClick={() => setShow(false)}>
            No
          </Button>
          <Button
            variant="danger"
            size="sm"
            onClick={() => {
              fun(data, setSubmitting, setShow);
              setSubmitting(true);
            }}
            disabled={isSubmitting}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeletePcb;
