import React from "react";
import { Component } from "react";
import { Badge, Button, Col, Row } from "react-bootstrap";
import LoadController from "../../components/LoadController";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";

class Control extends Component {

	render() {
		const { loadStates, setLoad, setSwitch, sendSyncMessage, deviceConnectionStatus,switchPinConnectionStatus } = this.props;
		return (
			<div>
				<TestStepsHeader
					icon={Images.ControlSync.Control}
					title={"Control Sync"}
				/>

				<Row>
					<Col>
						<Button onClick={sendSyncMessage}>Sync</Button>
					</Col>
					<Col>
						<h4>
							<Badge pill bg={deviceConnectionStatus.connected ? "success" : "danger"}>
								{deviceConnectionStatus.deviceId} : {deviceConnectionStatus.connected ? "Online" : "Offline"}
							</Badge>
						</h4>
					</Col>
					<Col>
						<h4>
							<Badge pill bg={switchPinConnectionStatus.connected ? "success" : "danger"}>
								{switchPinConnectionStatus.deviceId} : {switchPinConnectionStatus.connected ? "Online" : "Offline"}
							</Badge>
						</h4>
					</Col>
				</Row>
				<Row>
					{Object.keys(loadStates).map((loadIndex) => {
						return (
							<Col key={String(loadIndex)} lg={Number(loadIndex) === 0 ? 12 : 6}>
								<LoadController
									name={`Load ${loadIndex}`}
									on={Number(loadStates[loadIndex]) !== 0}
									setLoad={(command) => {
										setLoad(loadIndex, command)
									}}
									setSwitch={(command) => {
										setSwitch(loadIndex, command)
									}}
									isFanLoad={Number(loadIndex) === 0}
								/>
							</Col>
						)
					})}
				</Row>
			</div>
		)
	}
}

export default Control;
