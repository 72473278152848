import { PcbVersionAction } from "../actionTypes";

const initialState = []

export const pcbVersion = (state=initialState,action)=>{
    switch (action.type) {
        case PcbVersionAction.LOAD:
            return action.payload
        case PcbVersionAction.UPDATE:
            let newData = state.map((ele)=>{
                 if(ele._id===action.payload._id){
                    return action.payload
                 }else{
                    return ele
                 }
            })
             return newData
        case PcbVersionAction.REMOVE:
            let filterData = [...state]
             filterData = state.filter((ele) => ele._id !== action.payload._id)
            return filterData
        default:
            return state
    }
}