import axios from "axios";
import { baseurl } from "../../config/constants";
import { UserAllList } from "../actionTypes";
import {
  accessTokenHeader,
  showErrorMessage,
  showSuccessMessage,
} from "../../utils/helpers";
import store from "../source";

export const saveUserList = (data) => {
  return {
    type: UserAllList.USER_ALL_LIST,
    payload: data,
  };
};

export const getUserList = () => {
  axios
    .get(
      `${baseurl}/users/list?invokerType=webapp&invokerId=internal_webapp&invokerUser=${
        store.getState().user.accessToken
      }`,
      {
        headers: {
          ...accessTokenHeader(),
        }
      }
    )
    .then(function (res) {
      store.dispatch(saveUserList(res.data));
    })
    .catch(function (err) {
      store.dispatch(
        saveUserList({
          success: false,
          message: "Server Error",
          data: {
            users: [],
          },
        })
      );
    });
};

export const changePassword = (user, userDetail, newPassword, setModelShow) => {
  axios
    .post(
      `${baseurl}/users/resetPassword`,
      {
        employeeId: userDetail.employeeId,
        newPassword,
      },
      {
        headers: {
          ...accessTokenHeader(),
        },
      }
    )
    .then(function (res) {
      if (res.data.success === true) {
        showSuccessMessage("", res.data.message);
        setModelShow(false);
      } else {
        showErrorMessage("", res.data.message);
      }
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message);
    });
};

export const userDeleteApi = (userDetail, setShow) => {
  axios
    .post(
      `${baseurl}/users/delete`,
      {
        userId: userDetail._id,
      },
      {
        headers: {
          ...accessTokenHeader(),
        },
      }
    )
    .then(function (res) {
      if (res.data.success) {
        setShow(false);
        showSuccessMessage("", res.data.message);
        getUserList();
      } else {
        showErrorMessage("", res.data.message);
      }
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message);
    });
};

export const updateEmployeeDetails = (user) => {
  axios
    .post(`${baseurl}/users/editDetails`, user, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(function (res) {
      if (res.data.success) {
        showSuccessMessage("", res.data.message);
        getUserList();
      } else {
        showErrorMessage("", res.data.message);
      }
    })
    .catch(function (err) {
      showErrorMessage("", err.response.data.message);
    });
};
