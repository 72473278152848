import React, { Component } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import History from "../../components/History";
import { loadHistory } from "../../redux/actions/history";
import { loadReturnedDevices } from "../../redux/actions/returnedDevices";

class ReturnedDevices extends Component {
  state = {
    selectedDeviceId: "",
  };

  componentDidMount() {
    this.props.loadReturnedDevices();
  }

  refresh = () => {
    this.props.loadReturnedDevices();
  };
  render() {
    const { selectedDeviceId } = this.state;
    const { returnedDevices } = this.props;
    return (
      <div>
        <Row>
          <Col lg={4}>
            <div style={{ maxHeight: "90vh", overflowY: "scroll" }}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Device Id</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {returnedDevices.sort((d1, d2) => 
                    new Date(d1.last_transaction.date).getTime() - new Date(d2.last_transaction.date).getTime()
                  ).map((device, index) => {
                    const monitored = this.props.histories[device.deviceId]?.testing?.device?.monitored;
                    return (
                      <tr
                        key={device.deviceId}
                        onClick={this.handleDeviceSelected.bind(
                          this,
                          device.deviceId
                        )}
                        style={monitored === true ? {border: '2px solid red'} : selectedDeviceId === device.deviceId ? {border: '2px solid blue'} : {}}
                      >
                        <td>{index + 1}</td>
                        <td>{device.deviceId}</td>
                        <td>
                          {new Date(
                            device.last_transaction.date
                          ).toLocaleDateString(['en-IN'], {day: '2-digit', year: 'numeric', month: 'short'})}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
          <Col lg={4}>
            <History deviceId={selectedDeviceId} />
          </Col>
        </Row>
      </div>
    );
  }

  handleDeviceSelected = (deviceId) => {
    if (deviceId === this.state.selectedDeviceId) return;
    this.props.loadHistory(deviceId);
    this.setState({ selectedDeviceId: deviceId });
  };
}

const mapStateToProps = (state) => ({
  returnedDevices: state.returnedDevices.list,
  histories: state.histories,
});

export default connect(mapStateToProps, { loadReturnedDevices, loadHistory })(
  ReturnedDevices
);
