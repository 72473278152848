import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { showErrorMessage } from '../../utils/helpers';
import { addSockOfSelectedProduct } from '../../redux/actions/affilatedProduct';
import { Col, Form, Row } from 'react-bootstrap';

function AddStock({ selectedProductId }) {
  const [show, setShow] = useState(false);
  const [sure,setSure] = useState(false)
  const [stockCount, setStockCount] = useState(0)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseSure = () => setSure(false);
  const handleShowSure = () => setSure(true);

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Add Stock
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Stock</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-4">
            <Col>
              <Form.Control
                placeholder="Stock Number"
                aria-label="StockNumber"
                aria-describedby="basic-addon1"
                type="number"
                value={stockCount}
                onChange={(e) => {
                  setStockCount(Number(e.target.value))


                }}
              />
            </Col>
            <Col>
              <Button
                onClick={() => {
                  if (stockCount === 0) {
                    showErrorMessage('', "Stock Number not be equal to zero", 2000)
                    return
                  }
                  if (selectedProductId === "") {
                    showErrorMessage('', "Select Product First", 2000)
                    return
                  }
                  handleShowSure()
                  // addSockOfSelectedProduct(selectedProductId, stockCount)
                }}
              >Add Stock</Button>
            </Col>
          </Row>

        </Modal.Body>
    
      </Modal>
      <Modal show={sure} onHide={handleCloseSure}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Are you sure want to add more stock
        </Modal.Body>
        

        <Modal.Footer>
          <Button 
            variant="secondary"
            onClick={()=>handleCloseSure()}
          >Close</Button>
          <Button 
            variant="primary"
            onClick={()=> {
              addSockOfSelectedProduct(selectedProductId, stockCount)
              handleCloseSure()
              handleClose()
              }}
          >Save changes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddStock;