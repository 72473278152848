import React from "react";
import { Route, Routes } from "react-router-dom";
import Welcome from "../screens/Welcome";
import TestDevice from "../screens/TestDevice";
import OnlineOfflineTest from "../screens/OnlineOfflineTest";
import CreateUserPage from "../screens/Users/CreateUserPage";
import BasicTest from "../screens/BasicTest";
import ControlSync from "../screens/ControlSync";
import DeviceTestingStatusPage from "../screens/DeviceTestingStatusPage";
import SMPSTestingScreen from "../screens/SMPSTestingScreen";
import UpdateWifi from "../screens/UpdateWifi";
import RepairLogScreen from "../screens/RepairLogScreen";
import BulkOTAPage from "../screens/BulkOTAPage";
import DiscardDevice from "../screens/DiscardDevice";
import Movements from "../screens/MovementScreens/Movements";
import ReturnedDevices from "../screens/ReturnedDevicesScreens/ReturnedDevices";
import CurrentMovementDevices from "../screens/MovementScreens/CurrentMovementDevices";
import PcbType from "../screens/Pcb/PcbType";
import PcbVersion from "../screens/Pcb/PcbVersion";
import Assembler from "../screens/Pcb/Assembler";
import UserListShow from "../screens/Users/UserListShow";
import DeviceRegiser from "../screens/Device/DeviceRegiser";
import SearchHouse from "../screens/SearchDevices/SearchHouse";
import SearchDispatch from "../screens/SearchDevices/SearchDispatch";
import DeviceType from "../screens/DeviceType/DeviceType";
import AffilatedProductMovement from "../screens/MovementScreens/AffilatedProductMovement";
import DeviceIdPdf from "../screens/Device/DeviceIdPdf";
import TestEnergISync from "../screens/TestEnergISync";
import HardwareTest from "../screens/HardwareTest";
import { useSelector } from "react-redux";
import { RoutePaths, RoutesConfiguration } from "../config/constants";
import { showErrorMessage, userAllowedMenu } from "../utils/helpers";
import TestingStation from "../screens/TestingStation/TestingStation";
const DashboardRoutes = () => {
    const user = useSelector((state) => state.user);
    const routeComponents = {
        [RoutePaths["Bulk OTA"]]: <BulkOTAPage />,
        [RoutePaths.Home]: <Welcome />,
        [RoutePaths["EnergISync Testing"]]: <TestEnergISync />,
        [RoutePaths["Basic Test"]]: <BasicTest />,
        [RoutePaths["Hardware Test"]]: <HardwareTest />,
        [RoutePaths["Sync Hardware Test"]]: <TestDevice />,
        [RoutePaths["Online Test"]]: <OnlineOfflineTest />,
        [RoutePaths["Create User"]]: <CreateUserPage />,
        [RoutePaths["Control Sync"]]: <ControlSync />,
        [RoutePaths["Testing Status"]]: <DeviceTestingStatusPage />,
        [RoutePaths["SMPS Testing"]]: <SMPSTestingScreen />,
        [RoutePaths["Update WiFi"]]: <UpdateWifi />,
        [RoutePaths["Log Repair"]]: <RepairLogScreen />,
        [RoutePaths["Discard Device"]]: <DiscardDevice />,
        [RoutePaths["Device Movement"]]: <Movements />,
        [RoutePaths["Returned Devices"]]: <ReturnedDevices />,
        [RoutePaths["Current Movement Devices"]]: <CurrentMovementDevices />,
        [RoutePaths["PCB Type"]]: <PcbType />,
        [RoutePaths["PCB Version"]]: <PcbVersion />,
        [RoutePaths.Assembler]: <Assembler />,
        [RoutePaths.Users]: <UserListShow />,
        [RoutePaths["Device Register"]]: <DeviceRegiser />,
        [RoutePaths["Search House"]]: <SearchHouse />,
        [RoutePaths["Search Dispatch"]]: <SearchDispatch />,
        [RoutePaths["Device Type"]]: <DeviceType />,
        [RoutePaths["Stock Movement"]]: <AffilatedProductMovement />,
        [RoutePaths["Device Id Pdf"]]: <DeviceIdPdf />,
        [RoutePaths["Testing Station"]]: <TestingStation />,
    };
    return (
        <Routes>
            {Object.keys(routeComponents).map((route) => {
                const routeConfig = RoutesConfiguration.find(
                    (r) => r.linkTo === route
                );
                if (!routeConfig) {
                    showErrorMessage("Route  not configured", route);
                    return null;
                }
                if (
                    !userAllowedMenu(
                        routeConfig.availableFor,
                        user.profile.roles
                    )
                ) {
                    return null;
                }
                return (
                    <Route
                        key={route}
                        path={route}
                        element={routeComponents[route]}
                    />
                );
            })}
        </Routes>
    );
};

export default DashboardRoutes;
