import React, { useState } from "react";
import { Badge, Button, Form } from "react-bootstrap";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";
const Summary = ({ devicePassed, onPassed, onFailed }) => {
  const [message, setMessage] = useState("");
  return (
    <div>
      <TestStepsHeader
        icon={Images.HardwareTesting.Summary}
        title={"Summary"}
      />
      <Form.Label>Automated testing reusult:</Form.Label>
      <h4>
        <Badge bg={devicePassed ? "success" : "danger"}>
          {devicePassed === true ? "Passed" : "Failed"}
        </Badge>
      </h4>
      <Form.Group>
        <Form.Label>Add a message for logs:</Form.Label>
        <Form.Control
          as={"textarea"}
          rows={5}
          value={message}
          placeholder={"Tester message"}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        />
      </Form.Group>
      <div style={{ float: "right", marginTop: 10 }}>
        <Button
          variant={"danger"}
          onClick={() => {
            onFailed(message);
          }}
        >
          Failed
        </Button>
        {devicePassed === true && (
          <>
            <span style={{ marginRight: 15 }} />
            <Button
              variant={"primary"}
              onClick={() => {
                onPassed(message);
              }}
            >
              Passed
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Summary;
