import React from "react";
import { Col, Row, Stack, Table } from "react-bootstrap";
import AddNewPcbType from "../../components/AddNewPcbType";
import { useEffect } from "react";
import { add, getPcb, remove, update } from "../../redux/actions/pcbTypes";
import DeletePcb from "../../components/DeletePcb";
import { useSelector } from "react-redux";
import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";

function PcbType() {
  const pcbType = useSelector((state) => state.pcbType);
  useEffect(() => {
    getPcb();
  }, []);

  return (
    <Row>
      <Col md={8} className="rounded elevated">
        <Row className="mt-2 mb-2">
          <Col md={10}>
            <TestStepsHeader
              icon={Images.MenuImages.PcbType}
              title={"PCB Types"}
            />
          </Col>
          <Col
            md={2}
            className="d-flex justify-content-end align-items-center"
            style={{ borderBottom: "0.2px solid black", marginBottom: "15px" }}
          >
            <AddNewPcbType
              data={{
                name: "",
                code: "",
                description: "",
                type: "Base",
              }}
              text="Add New"
              fun={add}
            />
          </Col>
        </Row>
        <Row className="m-0">
          <Col
            md={12}
            className="customScrollbar"
            style={{ height: "70vh", overflow: "scroll" }}
          >
            <Table striped bordered hover variant="light">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {pcbType &&
                  pcbType[0] !== null &&
                  pcbType.map((pcb, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{pcb?.name}</td>
                        <td>{pcb?.code}</td>
                        <td>{pcb?.type}</td>
                        <td>{pcb?.description}</td>
                        <td>
                          <Stack gap={3} direction="horizontal">
                            <AddNewPcbType
                              data={pcb}
                              text="Update"
                              fun={update}
                            />
                            <DeletePcb
                              data={pcb}
                              fun={remove}
                              text="Delete PCB"
                            />
                          </Stack>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PcbType;
