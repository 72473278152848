import axios from "axios";
import {
    baseurl
} from "../../config/constants";
import {
    accessTokenHeader, showErrorMessage
} from "../../utils/helpers";


export const searchHouseData = async (searchValue) => {
    return await axios.get(`${baseurl}/movements/searchHouse/${searchValue}`, {
        headers: {
            ...accessTokenHeader(),
        },
    }).then(
        function (res) {
            if (res.data.success) {
                return res.data.data.devices
            } else {
                showErrorMessage("Search Failed", res.data.message)
                return []
            }
        }
    ).catch(function (err) {
        showErrorMessage("Search Failed", "Failed to Load Data")

        return []
    })
}

export const searchDispatch = async (searchValue) => {
    return await axios.get(`${baseurl}/movements/searchDispatched/${searchValue}`, {
        headers: {
            ...accessTokenHeader(),
        },
    }).then(
        function (res) {
            if (res.data.success) {
                return res.data.data.devices
            } else {
                showErrorMessage("Search Failed", res.data.message)
                return []
            }
        }
    ).catch(function (err) {
        showErrorMessage("Search Failed", "Failed to Load Data")

        return []
    })
}