import axios from "axios";
import { baseurl, otaurl } from "../../config/constants";
import { accessTokenHeader } from "../../utils/helpers";

export const getDeviceTypesList = () => {
  return new Promise((resolve) => {
    axios
      .get(`${baseurl}/deviceTypeTrack/list`, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, message, data } = result.data;
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};

export const registerNewDevice = (details, updateDevicesList) => {
  const payload = {
    ...details,
    lastAssignedId: 0,
    lastAssignedDate: new Date().getTime(),
  };
  return new Promise((resolve) => {
    axios
      .post(`${baseurl}/deviceTypeTrack/register`, payload, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, message, data } = result.data;
          if (success) {
            getDeviceTypesList().then((res) => {
              if (res.success) {
                updateDevicesList(res?.data?.deviceTypes);
              }
            });
          }
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};

export const updateDevice = (details, updateDevicesList) => {
  const payload = {
    deviceCode: details.deviceCode,
    description: details.description,
    name: details.name,
    ota_endpoint: details.ota_endpoint,
    binLoc: details.binLoc,
    lastAssignedId: Number(details.lastAssignedId),
    active: details.active,
  };
  return new Promise((resolve) => {
    axios
      .post(`${baseurl}/deviceTypeTrack/updateDetails`, payload, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, message, data } = result.data;

          if (success) {
            getDeviceTypesList().then((res) => {
              if (res.success) {
                updateDevicesList(res?.data?.deviceTypes);
              }
            });
          }

          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};

export const getOTAStatus = (
  details = {
    binLoc: "",
    deviceId: "",
  }
) => {
  return new Promise((resolve) => {
    axios
      .post(`${otaurl}/getStatus`, details)
      .then(
        (result) => {
          const { success, message, data } = result.data;
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};

export const generateBulkOTA = (
  details = {
    deviceCode: "",
    start: 0,
    end: 0,
  }
) => {
  return new Promise((resolve) => {
    axios
      .post(`${baseurl}/deviceTypeTrack/generateOTA`, details, {
        headers: {
          ...accessTokenHeader(),
        },
      })
      .then(
        (result) => {
          const { success, message, data } = result.data;
          resolve({
            success,
            message,
            data,
          });
        },
        (error) => {
          const message = error.response.data
            ? error.response.data.message
            : String(error);
          resolve({
            success: false,
            message,
          });
        }
      )
      .catch((error) => {
        resolve({
          success: false,
          message: String(error),
        });
      });
  });
};
