import React from "react";
import { MovementTransactionsType } from "../config/constants";
import Images from "../config/images";
import TestStepsHeader from "./TestStepsHeader";

export const MovementCard = ({ transaction }) => {
  let types = Object.keys(MovementTransactionsType);
  let typeIndex =
   types.findIndex(
      (key) =>
        Number(MovementTransactionsType[key]) === Number(transaction.type)
    );
  if (typeIndex === -1) {
    typeIndex = "";
  }
  const type = types[typeIndex]
  return (
    <div
      className="rounded elevated"
      style={{ padding: "0px", overflow: "hidden" }}
    >
      <div
        style={{
          fontSize: "16px",
          borderBottom: "0.5px solid black",
          padding: "15px",
        }}
      >
        <strong>
          {" "}
          {transaction.givenTo.name} - {type}{" "}
        </strong>
      </div>
      <div style={{ padding: "15px", borderBottom: "0.5px solid black" }}>
        {new Date(transaction.date).toLocaleString()} <br />
        {transaction?.reason ? <> {transaction.reason} <br /> </>: ''}
        {transaction.message} <br />
        {transaction?.returnedFrom?.name ?  <> Returned from {transaction?.returnedFrom?.name} <br /> </> : ''}
        {transaction?.deletedBy?.name ? <> Deleted by {transaction?.deletedBy?.name} <br /> </> : ''}
        {transaction?.deletedBy?.replacedBy ? <> Replaced by {transaction?.deletedBy?.replacedBy} <br /> </> : ''}
        
      </div>
    </div>
  );
};

const MovementsList = ({ movements }) => {
  return (
    <div style={{maxHeight: '90vh', overflowY: 'scroll'}} className={'invisibleScrollbar'}>
      <TestStepsHeader
        icon={Images.HardwareTesting.Problems}
        title={"Movements"}
      />
      {movements &&
        movements.transactions &&
        movements.transactions.map((transaction, index) => (
          <MovementCard
            key={`${index}.${transaction.date}`}
            transaction={transaction}
          />
        ))}
    </div>
  );
};

export default MovementsList;
