import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

function HistoryAffilatedProduct({installationUsers}) {
  const historySelectedProduct = useSelector(
    (state) => state.historySelectedProduct
  );

  const findName = (data) => {
    let user = installationUsers.find((u) => u._id === data.userId);
    return user?.first_name;
  };
  
  const findUserNameByEmployeeId = (data) => {
    if (isNaN(data) === false) {
      let user = installationUsers.find((u) => u.employeeId === data);
      return user?.first_name;
    } else {
      return data;
    }
  };
  return (
    <div
      className="p-2"
      style={{ maxHeight: "75svh", overflowY: "scroll", overflowX: "none" }}
    >
      {historySelectedProduct &&
        historySelectedProduct.map((h) => {
          if (h.logType === 0) {
            return (
              <Row
                className="p-2 mb-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.075)",
                  border: "1px solid black",
                }}
              >
                <Row>
                  <h4>Add Stock</h4>
                </Row>
                <Row>
                  <Col>Quantity :- {h.quantity}</Col>
                  <Col>Added By :- {findName(h)}</Col>
                </Row>
              </Row>
            );
          } else if (h.logType === 1) {
            return (
              <Row
                className="p-2 mb-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.075)",
                  border: "1px solid black",
                }}
              >
                <Row>
                  <h4>Transfer Stock</h4>
                </Row>
                <Row>
                  <Col>From :- {findUserNameByEmployeeId(h.from)}</Col>
                  <Col>To :- {findUserNameByEmployeeId(h.to)}</Col>
                </Row>
                <Row>
                  <Col>Transfer By :- {findName(h)}</Col>
                </Row>
              </Row>
            );
          } else {
            return <Row>Log Type Not Metch</Row>;
          }
        })}
    </div>
  );
}

export default HistoryAffilatedProduct;
