import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Plus } from "react-bootstrap-icons"
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import { showErrorMessage } from '../utils/helpers';
import { useSelector } from 'react-redux';

function PcbVersionForm({ data, text, fun }) {
    const [show, setShow] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false)
    const pcbType = useSelector(state => state.pcbType)
    let [initialValues, setInitialValues] = useState(data)
    useEffect(() => {
        setInitialValues(data)
    }, [data])
    const handleSubmit = () => {
        const { versionName, pcbTypeId, description, version } = initialValues
        if (!versionName || !pcbTypeId || !description || !version) {
            showErrorMessage("", "Fields are required", 2000)
        } else {
            setSubmitting(true)
            fun(initialValues, setSubmitting, setShow)
        }

    }


    const handleChange = (event) => {
        setInitialValues({ ...initialValues, [event.target.name]: event.target.value })
    }
    // versionName: "",
    // pcbTypeId: "",
    // description: "",
    // version: "",
    return (
        <>
            <>
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setShow(true)}
                >
                    {text === "Add New" ? <Plus style={{ fontSize: "16px" }} /> : null}
                    {text}
                </Button>
            </>
            <Modal
                show={show}
                onHide={() => {
                    setShow(false)
                    setInitialValues(data)
                    setSubmitting(false)
                }}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {text}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                        <Form.Group as={Col} md="6"  className="mb-3">
                                <Form.Label>Pcb Type</Form.Label>
                                <Form.Select
                                    defaultValue={initialValues.pcbTypeId}
                                    name="pcbTypeId"
                                    onChange={handleChange}
                                >
                                    <option>Choose One</option>
                                    {
                                        pcbType && pcbType.map((pcb) => {
                                            return (
                                                <option value={pcb._id}>{pcb.name}</option>
                                            )
                                        })
                                    }

                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik01"  className="mb-3">
                                <Form.Label>Version Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="versionName"
                                    value={initialValues.versionName}
                                    onChange={handleChange}
                                    placeholder='Version Name'
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" controlId="validationFormik02"  className="mb-3">
                                <Form.Label>Version code</Form.Label>
                                <InputGroup >
                                    <Form.Control
                                        type="number"
                                        name="version"
                                        value={initialValues.version}
                                        onChange={handleChange}
                                        placeholder='Version Number'
                                    />
                                </InputGroup>
                            </Form.Group>
                            {
                                text === "Update" ?
                                    <Form.Group as={Col} md="6"  className="mb-3">
                                        <Form.Label>Pcb Type</Form.Label>
                                        <Form.Select
                                            defaultValue={initialValues.active}
                                            name="active"
                                            onChange={handleChange}
                                        >
                                            <option value={true}>Active</option>
                                            <option value={false}>InActive</option>
                                        </Form.Select>
                                    </Form.Group> : null
                            }
                            <Form.Group as={Col} md="6"  className="mb-3">
                                <Form.Label>Description</Form.Label>
                            

                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        rows={3}
                                        placeholder="Description"
                                        aria-describedby="inputGroupPrepend"
                                        name="description"
                                        value={initialValues.description}
                                        onChange={handleChange}
                                    />
                                </InputGroup>
                            </Form.Group>
                           
                        </Row>
                        <div className='d-flex flex-row-reverse gap-3'>
                        <Button onClick={handleSubmit} disabled={isSubmitting} variant='success'>Submit</Button>
                        <Button onClick={()=>setShow(false)} variant='danger'>Cancel</Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PcbVersionForm