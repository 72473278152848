import axios from "axios";
import { baseurl } from "../../config/constants";
import { accessTokenHeader } from "../../utils/helpers";
import { ReturnedDevicesActions } from "../actionTypes";
const load = (payload = []) => ({
	type: ReturnedDevicesActions.LOAD,
	payload,
})

export const loadReturnedDevices = () => dispatch => {
  return axios
    .get(`${baseurl}/movements/returned`, {
      headers: {
        ...accessTokenHeader(),
      },
    })
    .then(
      (response) => {
				if (response.data?.success) {
					dispatch(load(response.data.data.devices));
				}
        return response.data;
      },
      (error) => {
        const message = error.response.data
          ? error.response.data.message
          : String(error);
				
        return {
          success: false,
          message,
          data: error?.response?.data?.data,
        };
      }
    )
    .catch((error) => {
      return {
        success: false,
        message: String(error),
      };
    });
};
