import { createStore, combineReducers, applyMiddleware } from "redux";
import user from "./reducers/user";
import testingStations from "./reducers/testingStations";
import thunk from "redux-thunk";
import currentSyncTest from "./reducers/currentSyncTest";
import currentFanModuleTest from "./reducers/currentFanModuleTest";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import usersLists from "./reducers/users";
import onlineCount from "./reducers/onlineCount";
import returnedDevices from "./reducers/returnedDevices";
import histories from "./reducers/histories";
import currentMovement from "./reducers/currentMovement";
import { pcbType } from "./reducers/pcbTypes";
import { pcbVersion } from "./reducers/pcbVersion";
import { assembler } from "./reducers/assembler";
import { userListSave } from "./reducers/userList";
import {
  affliatedProductReducer,
  selectedAffliatedProductHistoryReducer,
  selectedAffliatedProductReducer,
} from "./reducers/affilatedProducts";
import logger from "redux-logger";

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    user,
    testingStations,
    currentSyncTest,
    currentFanModuleTest,
    usersLists,
    onlineCount,
    returnedDevices,
    histories,
    currentMovement,
    pcbType,
    pcbVersion,
    assembler,
    userList: userListSave,
    productList: affliatedProductReducer,
    selectedProductDetails: selectedAffliatedProductReducer,
    historySelectedProduct: selectedAffliatedProductHistoryReducer,
  })
);

const store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);
export default store;
