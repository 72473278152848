import { CurrentSyncTestActions, UserActions } from "../actionTypes";

const initalState = {
	device: undefined,
	testingStation: undefined,
};
const currentSyncTest = (
	state = initalState,
	action
) => {
	const {type, payload} = action;
	switch (type) {
		case CurrentSyncTestActions.SET_DEVICE:
			return {
				...state,
				device: payload,
			}

		case CurrentSyncTestActions.SET_TESTING_STATION:
			return {
				...state,
				testingStation: payload
			}

		case UserActions.LOGOUT:
			return initalState;

		default:
			return state;
	}
}

export default currentSyncTest;
