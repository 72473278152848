import React from "react";
import { Badge, Form } from "react-bootstrap";
import { WifiHealthStatus } from "../config/constants";
const DeviceConnectionCard = ({ details }) => {
  if (!details) return null;
  else
    return (
      <div>
        <div className="rounded elevated">
					<div>
          <Form.Label>
            <strong> {details.deviceId || "Device ID"} </strong>
          </Form.Label>
					</div>
          <div>
          <Form.Label>
            <h5>
              <Badge bg={details.connected ? "success" : "danger"}>
                {details.connected ? "" : "Not "} Connected
              </Badge>
            </h5>
          </Form.Label>
					</div>
					<div>
					<Form.Label>
            <h5>
              <Badge
                bg={
                  details.wifiHealth === WifiHealthStatus.Unknown
                    ? "secondary"
                    : details.wifiHealth === WifiHealthStatus.Bad
                    ? "danger"
                    : "success"
                }
              >
                Wifi Health: {details.wifiHealth}
              </Badge>
            </h5>
          </Form.Label>
					</div>
					<div>
          <Form.Label>
            <h5>
              <Badge bg={"primary"}>Connectivity: {details.strength}</Badge>
            </h5>
          </Form.Label>
					</div>
        </div>
      </div>
    );
};
export default DeviceConnectionCard;
