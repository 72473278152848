import React, { Component } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import MovementsList from "../../components/MovementsList";
import TestStepsHeader from "../../components/TestStepsHeader";
import {
  DefaultMovementNames,
  MovementsType,
  MovementTransactionsType,
  StockStores,
} from "../../config/constants";
import Images from "../../config/images";
import { getMovementDetail, logMovement } from "../../redux/actions/movement";
import { getInstallationUsers } from "../../redux/actions/users";
import { showErrorMessage, showSuccessMessage } from "../../utils/helpers";

class Movements extends Component {
  componentDidMount() {
    this.props.getInstallationUsers();
  }

  initialState = {
    deviceId: "",
    type: `${Object.values(MovementsType)[0]}`,
    electricianId: "",
    electricianNumber:'',
    name: StockStores.Office,
    otherDetail: "",
    movements: {},
    message: "",
  };

  state = { ...this.initialState };

  processing = false;

  render() {
    const { deviceId, message, type, name, otherDetail, movements } =
      this.state;
    const { installationUsers } = this.props;
    return (
      <Row className={"g-0"}>
        <Col lg={3} className="section_container">
          <Form.Group>
            <Row>
              <Col>
                <Form.Label>Enter device Id</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Control
                  value={deviceId}
                  onChange={this.handleDeviceIdChanged}
                  placeholder={"Device Id"}
                />
              </Col>
            </Row>
            <br />
          </Form.Group>
          <Button onClick={this.handleGetDetails}>Get details</Button>
        </Col>
        <Col lg={5}>
          <div
            className="section_container workAreaDiv invisibleScrollbar"
            style={{
              borderLeft: "0.5px solid black",
              borderRight: "0.5px solid black",
            }}
          >
            <TestStepsHeader
              icon={Images.MenuImages.Movement}
              title={"Log Movement"}
            />
            <br />
            <div>
              <Form.Group>
                <Form.Label>Select type</Form.Label>
                <Form.Select
                  aria-label="Select movement type"
                  onChange={this.handleTransactionTypeChange}
                  value={this.state.type}
                >
                  {Object.keys(MovementsType).map((key, index) => (
                    <option value={`${MovementsType[key]}`}>{key}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <br />
            {[
              MovementTransactionsType.Electrician,
              MovementTransactionsType.Returned,
              MovementTransactionsType.Restocked,
              MovementTransactionsType.Discarded,
            ].includes(Number(type)) && (
                <div>
                  <Form.Group>
                    <Form.Label>Select Electrician</Form.Label>
                    <Form.Select
                      aria-label="Select movement type"
                      onChange={this.handleElectricianChanged}
                      value={`${this.state.electricianId}-${this.state.electricianNumber}`}
                    >
                      {installationUsers.map((user) => {
                        console.log(user);
                      return(
                         <>                        
                         <option value={`${user.employeeId}-${user.phoneNumbers[0]}`}>
                          {user.employeeId} - {user.first_name} {user.last_name} {user.phoneNumbers[0]}
                        </option>
                    
                         </>
                      )})}
                    </Form.Select>
                  </Form.Group>
                  <a href={`tel:+${this.state.electricianNumber===""?installationUsers[0].phoneNumbers[0]:this.state.electricianNumber}`}>Call: {this.state.electricianNumber===""?installationUsers[0].phoneNumbers[0]:this.state.electricianNumber}</a>

                  <br />
                </div>
              )}
            {![MovementsType.Electrician, MovementsType.Stock, MovementsType.Restocked].includes(
              Number(type)
            ) && (
                <div>
                  <Form.Group>
                    <Form.Label>Enter name</Form.Label>
                    <Form.Control
                      value={name}
                      onChange={this.handleNameChanged}
                      placeholder={"Site name / Other name"}
                    />
                  </Form.Group>
                  <br />
                </div>
              )}
            {[MovementsType.Stock, MovementsType.Restocked].includes(Number(type)) && (
              <div>
                <Form.Group>
                  <Form.Label>Select Name</Form.Label>
                  <Form.Select
                    aria-label="Select name"
                    onChange={this.handleNameChanged}
                  >
                    {Object.keys(StockStores).map(store => <option value={StockStores[store]}>{StockStores[store]}</option>)}
                  </Form.Select>
                </Form.Group>
                <br />
              </div>
            )}
            {[
              MovementsType.DispatchedToSite,
              MovementsType.Other,
              MovementsType.Returned,
              MovementsType.Stock,
              MovementsType.Restocked,
              MovementsType.Discarded,
            ].includes(Number(type)) && (
                <div>
                  <Form.Group>
                    <Form.Label>Enter other detail</Form.Label>
                    <Form.Control
                      value={otherDetail}
                      onChange={this.handleOtherDetailChanged}
                      placeholder={"Other detail like phone number"}
                    />
                  </Form.Group>
                  <br />
                </div>
              )}
            <div>
              <Form.Group>
                <Form.Label>Add a message for logs:</Form.Label>
                <Form.Control
                  as={"textarea"}
                  rows={5}
                  value={message}
                  placeholder={"Tester message"}
                  onChange={this.handleMessageChanged}
                />
              </Form.Group>
              <br />
              <Button onClick={this.handleLog}>Log</Button>
            </div>
          </div>
        </Col>
        <Col lg={4}>
          <div className="section_container">
            <MovementsList movements={movements} />
          </div>
        </Col>
      </Row>
    );
  }

  handleElectricianChanged = (e) => {
    let val = e.target.value.split('-')
    this.setState({ electricianId: val[0]});
    this.setState({ electricianNumber: val[1]});
  };

  handleDeviceIdChanged = (e) => {
    let deviceId = e.target.value.trim().toUpperCase();
    if (deviceId.startsWith('DEVICE')) {
      deviceId = deviceId.replace('DEVICE', 'device');
    }
    this.setState({ deviceId });
  };

  handleTransactionTypeChange = (e) => {
    this.setState({electricianNumber:''})
    this.setState({
      type: e.target.value,
      name: DefaultMovementNames[Number(e.target.value)],
      electricianId: this.props.installationUsers[0]?.employeeId,
    });
  };

  handleNameChanged = (e) => {
    this.setState({ name: e.target.value });
  };

  handleOtherDetailChanged = (e) => {
    this.setState({ otherDetail: e.target.value });
  };

  handleMessageChanged = (e) => {
    this.setState({ message: e.target.value });
  };

  handleGetDetails = async () => {
    if (this.processing) return;
    this.processing = true;
    const { success, data, message } = await getMovementDetail(
      this.state.deviceId
    );
    if (success) {
      showSuccessMessage("Details loaded", message);
      this.setState({ movements: data.movements });
    } else {
      showErrorMessage("Request failed", message);
    }
    this.processing = false;
  };

  handleLog = async () => {
    const { name, otherDetail, type, deviceId, electricianId, message } =
      this.state;
    const { installationUsers } = this.props;
    let payload = {
      deviceId,
      transaction: {
        type: Number(type),
        transactionDate: new Date(), // could select from the user in case making old entries,
        givenTo: {
          name,
          otherDetail,
        },
        message,
        transactedBy: this.props.user.profile._id,
      },
    };
    if ([MovementsType.Electrician].includes(Number(type))) {
      const electricianIndex = installationUsers.findIndex(
        (i) => String(i.employeeId) === String(electricianId)
      );

      if (electricianIndex === -1) {
        return showErrorMessage("Selection error", "Electrician not selected");
      }
      const electrician = installationUsers[electricianIndex];
      payload.transaction.givenTo.name =
        electrician.first_name + " " + electrician.last_name;
      payload.transaction.givenTo.employeeId = electrician.employeeId;
      payload.transaction.givenTo.meta_data = {
        electricianId: electrician._id,
      };
    } else if ([MovementsType.Returned, MovementsType.Restocked, MovementsType.Discarded].includes(Number(type))) {
      const electricianIndex = installationUsers.findIndex(
        (i) => String(i.employeeId) === String(electricianId)
      );
      if (electricianIndex === -1) {
        return showErrorMessage("Selection error", "Electrician not selected");
      }
      const electrician = installationUsers[electricianIndex];
      payload.transaction.returnedFrom = {
        name: electrician.first_name + " " + electrician.last_name,
        employeeId: electrician.employeeId,
        meta_data: {
          electricianId: electrician._id,
        },
      };
    }
    this.processing = true;
    if (payload.transaction.givenTo.name.length === 0) {
      return showErrorMessage("Name can not be empty", "Select/Enter name given to");
    }
    const response = await logMovement(payload);
    if (response.success) {
      showSuccessMessage("Request Processed", response.message);
      this.setState({
        deviceId: "",
        message: "",
        otherDetail: "",
        movements: {},
      });
    } else {
      showErrorMessage("Request failed", response.message);
    }
    this.processing = false;
  };
}

const mapStateToProps = (state) => ({
  user: state.user,
  installationUsers: state.usersLists.installationUsers,
});
export default connect(mapStateToProps, { getInstallationUsers })(Movements);
