import { CurrentSyncTestActions } from "../actionTypes"

export const setTestingSync = (device) => dispatch => {
	dispatch({
		type: CurrentSyncTestActions.SET_DEVICE,
		payload: device,
	})
};

export const setSyncTestingStation = testingStation => dispatch => {
	dispatch({
		type: CurrentSyncTestActions.SET_TESTING_STATION,
		payload: testingStation,
	})
}

