import React, { Component } from "react";
import { Badge, Button, Col, Form, Row } from "react-bootstrap";
import ReactTimeAgo from "react-time-ago";
import TestStepsHeader from "../components/TestStepsHeader";
import { SMPSParts, smpsReason } from "../config/constants";
import Images from "../config/images";
import { smpsTestingLog } from "../redux/actions/smpsTestingLog";
import { showErrorMessage, showInfoMessage } from "../utils/helpers";
import { connect } from "react-redux";
import Multiselect from "multiselect-react-dropdown";

class SMPSTestingScreen extends Component {
  state = {
    smps_test_passed: false,
    relay_test_passed: false,
    first_try_passed: true,
    message: "",
    started: false,
    deviceType: "",
    pcbTypeId: "",
    reason: "",
    part: [],
    pcbVersion: "",
    pcbOrderNumber: 0,
    assembler: "",
    selectedValue: [],
  };
  saving = false;
  testStartTime = new Date();

  render() {
    const { message, started, smps_test_passed, relay_test_passed, part } =
      this.state;
    const { pcbType, pcbVersion, assembler } = this.props;
    return (
      <Row className={"g-0 workAreaDiv invisibleScrollbar"}>
        <Col lg={6}>
          <div className="rounded elevated">
            <TestStepsHeader
              icon={Images.SMPSTesting.Test}
              title={"SMPS Testing Log"}
            />

            <Form.Group>
              <Form.Label>Device Type</Form.Label>
              <Form.Select onChange={this.handleDeviceTypeChanged}>
                <option value={""}>Select One</option>
                {pcbType.map((pcb) => (
                  <option value={`${JSON.stringify(pcb)}`}>
                    {pcb.name}-{pcb.type}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <br />

            <Form.Group className="mb-3">
              <Form.Label>Reason</Form.Label>
              <Form.Select onChange={this.handleReasonChanged}>
                <option value={""}>Select One</option>
                {smpsReason.map((r) => (
                  <option value={r}>{r}</option>
                ))}
              </Form.Select>
            </Form.Group>
            {this.state.reason === "Parts" ? (
              <>
                <Form.Group>
                  <Form.Label>Parts</Form.Label>
                  <Multiselect
                    displayValue="value"
                    selectedValues={part}
                    onRemove={this.setSelectedValue}
                    onSelect={this.setSelectedValue}
                    options={SMPSParts}
                    style={{
                      width: "100px !important",
                      multiselectContainer: {
                        marginTop: "5px",
                        backgroundColor: "#ffffff",
                      },
                    }}
                  />
                </Form.Group>
                <br />
              </>
            ) : null}

            <Form.Group>
              <Form.Label>Pcb Version</Form.Label>
              <Form.Select onChange={this.handlePcbVersionChanged}>
                <option value={""}>Select One</option>
                {pcbVersion
                  .filter(
                    (pcb) =>
                      pcb.pcbTypeId._id === this.state.pcbTypeId &&
                      pcb.active === true
                  )
                  .map((pcb) => {
                    return (
                      <option value={pcb._id}>
                        {pcb.versionName}-{pcb.version}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>PCB Order Number</Form.Label>
              <Form.Control
                type="number"
                placeholder="Order Number"
                onChange={this.handleOrderNumberChange}
              />
            </Form.Group>
            <br />
            <Form.Group>
              <Form.Label>Assembler</Form.Label>
              <Form.Select onChange={this.handleAssemblerChanged}>
                <option value={""}>Select One</option>
                {assembler.map((assem) => (
                  <option value={assem._id}>{assem.name}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <br />
            <Row>
              <Col>
                <Button variant={"primary"} onClick={this.onStart}>
                  Start
                </Button>
              </Col>
              <Col>
                {started === true && (
                  <h3>
                    <Badge bg={"success"}>
                      <ReactTimeAgo
                        date={this.testStartTime}
                        timeStyle={"twitter"}
                        locale="en-US"
                      />
                    </Badge>
                  </h3>
                )}
              </Col>
            </Row>
            <br />
            <Form.Group>
              <Form.Check
                id={"chkSMPSTest"}
                defaultChecked={false}
                type="checkbox"
                label={"SMPS Test Passed"}
                onChange={(e) => {
                  this.setSMPSTestPassed(e.target.checked);
                }}
              />
              <br />
              <Form.Check
                id={"chkRelayTest"}
                defaultChecked={false}
                type="checkbox"
                label={"Relay Test Passed"}
                onChange={(e) => {
                  this.setRelayTestPassed(e.target.checked);
                }}
              />
              <br />
              <Form.Check
                id={"chkFirstTry"}
                defaultChecked={true}
                type="checkbox"
                label={"Is the device being tested for first time"}
                onChange={(e) => {
                  this.setFirstTryPassed(e.target.checked);
                }}
              />
              <br />
              <Form.Label>Add a message for logs:</Form.Label>
              <Form.Control
                as={"textarea"}
                rows={5}
                value={message}
                placeholder={"Tester message"}
                onChange={(e) => {
                  this.setMessage(e.target.value);
                }}
              />
            </Form.Group>
            <div style={{ marginTop: 10 }}>
              <Button
                variant={"danger"}
                onClick={this.onFailed}
                active={started}
                disabled={!started}
              >
                Failed
              </Button>

              <span style={{ marginRight: 15 }} />
              <Button
                variant={"primary"}
                onClick={this.onPassed}
                active={started && smps_test_passed && relay_test_passed}
                disabled={!(started && smps_test_passed && relay_test_passed)}
              >
                Passed
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
  setSelectedValue = (e) => {
    this.setState({ part: e });
  };

  handlePcbVersionChanged = (e) => {
    this.setState({ pcbVersion: e.target.value });
  };
  handleAssemblerChanged = (e) => {
    this.setState({ assembler: e.target.value });
  };
  handleOrderNumberChange = (e) => {
    this.setState({ pcbOrderNumber: Number(e.target.value) });
  };
  onStart = () => {
    if (this.state.deviceType === "") {
      showErrorMessage("", "Plesase Select Device Type");
      return;
    }
    if (this.state.reason === "") {
      showErrorMessage("", "Plesase Select Reason");
      return;
    }
    if (this.state.pcbVersion === "") {
      showErrorMessage("", "Plesase Select Version");
      return;
    }
    if (this.state.pcbOrderNumber === 0) {
      showErrorMessage("", "Plesase enter order number");
      return;
    }

    if (this.state.assembler === "") {
      showErrorMessage("", "Plesase Select Assembler");
      return;
    }

    if (this.state.reason === "Parts" && this.state.part.length === 0) {
      showErrorMessage("", "Plesase Select Parts");
      return;
    }
    this.testStartTime = new Date();
    this.setState({ started: true });
  };

  setSMPSTestPassed = (smps_test_passed) => {
    this.setState({ smps_test_passed });
  };

  setRelayTestPassed = (relay_test_passed) => {
    this.setState({ relay_test_passed });
  };

  setFirstTryPassed = (first_try_passed) => {
    this.setState({ first_try_passed });
  };

  setMessage = (message) => {
    this.setState({ message });
  };

  handleReasonChanged = (e) => {
    this.setState({ reason: e.target.value });
  };

  onPassed = async () => {
    if (this.saving) return;
    this.saving = true;
    const {
      message,
      first_try_passed,
      smps_test_passed,
      relay_test_passed,
      deviceType,
      reason,
      pcbTypeId,
      part,
      pcbVersion,
      pcbOrderNumber,
      assembler,
    } = this.state;
    let parts = part.map((e) => {
      return e.value;
    });
    const payload = {
      message,
      reason,
      first_try_passed,
      testing_date: this.testStartTime,
      deviceType,
      meta_data: {
        test_start_time: this.testStartTime,
        test_end_time: new Date(),
        smps_test_passed,
        relay_test_passed,
      },
      passed: true,
      pcbTypeId,
      parts,
      pcbVersion,
      pcbOrderNumber,
      assembler,
    };

    const response = await smpsTestingLog(payload);
    this.onTestingDone();
    if (response.success) {
      return showInfoMessage("Logged successfully", response.message);
    }
    return showErrorMessage("Logging test failed", response.message);
  };

  onFailed = async () => {
    if (this.saving) return;
    this.saving = true;
    const {
      message,
      first_try_passed,
      smps_test_passed,
      relay_test_passed,
      deviceType,
      pcbTypeId,
      part,
      pcbVersion,
      pcbOrderNumber,
      assembler,
    } = this.state;
    if (first_try_passed === false) {
      return showInfoMessage(
        "Done",
        "A smps should only be recorded failed once"
      );
    }
    let parts = part.map((e) => {
      return e.value;
    });
    const payload = {
      message,
      first_try_passed,
      testing_date: this.testStartTime,
      deviceType,
      meta_data: {
        test_start_time: this.testStartTime,
        test_end_time: new Date(),
        smps_test_passed,
        relay_test_passed,
      },
      passed: false,
      pcbTypeId,
      parts,
      pcbVersion,
      pcbOrderNumber,
      assembler,
    };
    const response = await smpsTestingLog(payload);
    this.onTestingDone();
    if (response.success) {
      return showInfoMessage("Logged successfully", response.message);
    }
    return showErrorMessage("Logging test failed", response.message);
  };

  handleDeviceTypeChanged = (e) => {
    let pcb = JSON.parse(e.target.value);
    this.setState({ deviceType: pcb.name, pcbTypeId: pcb._id });
  };

  onTestingDone = () => {
    document.getElementById("chkSMPSTest").checked = false;
    document.getElementById("chkRelayTest").checked = false;
    document.getElementById("chkFirstTry").checked = true;
    this.setState({
      first_try_passed: true,
      smps_test_passed: false,
      relay_test_passed: false,
      started: false,
      message: "",
    });
    this.saving = false;
  };
}
const mapStateToProps = (state) => ({
  pcbType: state.pcbType,
  pcbVersion: state.pcbVersion,
  assembler: state.assembler,
});
export default connect(mapStateToProps, {})(SMPSTestingScreen);
