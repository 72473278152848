export const UserActions = {
	LOGIN: 'USER_ACTION_LOGIN',
	LOGOUT: 'USER_ACTION_LOGOUT',
	LOAD_CHARTS_TOKEN: 'USER_ACTION_LOAD_CHARTS_TOKEN',
	LOAD_PROFILE: 'USER_ACTION_LOAD_PROFILE',
	
}
export const UserAllList = {
	USER_ALL_LIST:"USER_ALL_LIST"
}
export const UserList = {
	USER_ROLE_LIST:'USER_ROLE_LISTS',
	USER_ROLE_LIST_REMOVE:'USER_ROLE_LISTS_REMOVE',
	INSTALLATION_USERS_LOAD: 'USER_ROLE_INSTALLATION_USERS_LOAD',
}

export const TestingStationActions = {
	LOAD: 'TESTING_STATIONS_ACTIONS_LOAD',
	LOADING: 'TESTING_STATIONS_ACTIONS_LOADING',
	USED: 'TESTTING_STATIONS_ACTIONS_USED',
};

export const CurrentSyncTestActions = {
	SET_DEVICE: 'CURRENT_SYNC_TEST_ACTIONS_SET_DEVICE',
	SET_TESTING_STATION: 'CURRENT_SYNC_TEST_ACTION_SET_TESTING_STATION',
}

export const CurrentFanModuleTestActions = {
	SET_TESTING_STATION: 'CURRENT_SYNC_TEST_ACTION_SET_TESTING_STATION',
}

export const OnlineCountActions = {
	UPDATE: 'ONLINE_COUNT_ACTIONS_UPDATE',
};

export const ReturnedDevicesActions = {
	LOAD: 'RETURNED_DEVICES_ACTIONS_LOAD',
}

export const HistoryActions = {
	LOAD: 'HISTORY_ACTIONS_LOAD'
}

export const CurrentMovementActions = {
	LOAD: 'CURRENT_MOVEMENT_ACTIONS_LOAD'
}

export const PcbTypeAction = {
	LOAD:'GET_ALL_PCB',
	UPDATE:'PCB_UPDATE',
	REMOVE:'PCB_DELETE'
}
export const PcbVersionAction = {
	LOAD:'GET_ALL_PCB_VERSION',
	UPDATE:'PCB_VERSION_UPDATE',
	REMOVE:'PCB_VERSION_DELETE',
	TOGGLE:'PCB_VERSION_TOGGLE'
}
export const AssemblerAction = {
	LOAD:'GET_ALL_ASSEMBLER',
	UPDATE:'ASSEMBLER_UPDATE',
	REMOVE:'ASSEMBLER_DELETE'
}
export const DeviceRegisterAction = {
	LOAD:'GET_ALL_DEVICES',
	UPDATE:'DEVICE_UPDATE',
	REMOVE:'DEVICE_DELETE'
}

export const AffilatedProductAction = {
	LOAD:'GET_PRODUCT_LIST',
	LOAD_SELECTED_PRODUCT:'LOAD_SELECTED_PRODUCT',
	REMOVE_SELECTED_PRODUCT:'REMOVE_SELECTED_PRODUCT',
	HISTORY_SELECTED_PRODUCT:'HISTORY_SELECTED_PRODUCT',
}