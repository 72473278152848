import { AssemblerAction } from "../actionTypes";

const initialState = [];

export const assembler = (state = initialState, action) => {
  switch (action.type) {
    case AssemblerAction.LOAD:
      return action.payload;
    case AssemblerAction.UPDATE:
      let newData = state.map((ele) => {
        if (ele._id === action.payload._id) {
          return action.payload;
        } else {
          return ele;
        }
      });
      return newData;
    case AssemblerAction.REMOVE:
      let filterData = [...state];
      filterData = state.filter((ele) => ele._id !== action.payload._id);
      return filterData;
    default:
      return state;
  }
};
