import { Component } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { getOnlineDetails } from "../redux/actions/onlineCount";

class OnlineCount extends Component {
  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    this.props.getOnlineDetails();
    setInterval(() => {
      this.props.getOnlineDetails();
    }, 5 * 60 * 1000);
  };

  render() {
    const { onlineCount } = this.props;
    let total = 0;
    for (const value of Object.values(onlineCount)) {
      total += value;
    }
    let types = Object.keys(onlineCount);
    types.sort();
    onlineCount['Total'] = total;
    types = types.concat('Total');
    let totalTypes = types.length;
    let countPerTable = totalTypes / 3;
    return (
      <div style={{ padding: 15, backgroundColor: '#f1f5f4' }}>
        <Row>
          <Col>
            <Table bordered hover responsive size={"sm"}>
              <thead>
                <tr>
                  <th>Device Type</th>
                  <th># Online</th>
                </tr>
              </thead>
              <tbody>
                {types
                  .slice(0, countPerTable)
                  .map((deviceCode) => (
                    <tr key={deviceCode}>
                      <td>{deviceCode}</td>
                      <td>{onlineCount[deviceCode]}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table bordered hover responsive size={"sm"}>
              <thead>
                <tr>
                  <th>Device Type</th>
                  <th># Online</th>
                </tr>
              </thead>
              <tbody>
                {types
                  .slice(countPerTable, countPerTable * 2)
                  .map((deviceCode) => (
                    <tr key={deviceCode}>
                      <td>{deviceCode}</td>
                      <td>{onlineCount[deviceCode]}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
          <Col>
            <Table bordered hover responsive size={"sm"}>
              <thead>
                <tr>
                  <th>Device Type</th>
                  <th># Online</th>
                </tr>
              </thead>
              <tbody>
                {types
                  .slice(countPerTable * 2, countPerTable * 3)
                  .map((deviceCode) => (
                    <tr key={deviceCode}>
                      <td>{deviceCode}</td>
                      <td>{onlineCount[deviceCode]}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  onlineCount: state.onlineCount,
});

export default connect(mapStateToProps, { getOnlineDetails })(OnlineCount);
