import React from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { Component } from "react";
import OnlineCount from "../components/OnlineCount";
import { getChartsToken } from "../redux/actions/user";
import { connect } from "react-redux";

const sdk = new ChartsEmbedSDK({
  baseUrl: "https://charts.mongodb.com/charts-aliste-internal-ehxie",
});

// render the chart into a container

class Welcome extends Component {
  refresh = () => {
    this.props.getChartsToken().then((result) => {
      const { success, data } = result;
      if (!success) return;
      // embed a dashboard
      const dashboard = sdk.createDashboard({
        getUserToken: () => data.mongoToken,
        dashboardId: "0c2b97fb-b6bb-4006-8058-d92939bdb6e6",
        widthMode: "scale",
        maxDataAge: 15 * 60,
      });
      dashboard.render(document.getElementById("dashboard")).catch(() => {});
    });
  };

  componentDidMount() {
    this.refresh();
  }

  render() {
    return (
        <div
          style={{
            backgroundColor: "white",
            overflowY: "scroll",
          }}
          className="workAreaDiv invisibleScrollbar"
        >
          <OnlineCount />
          <div
            id={"dashboard"}
            style={{
              minHeight: '1000vh',
              height: '1000vh'
            }}
          />
        </div>
    
    );
  }
}

const mapStateToProps = (state) => ({
  chartsToken: state.user.chartsToken,
});
export default connect(mapStateToProps, { getChartsToken })(Welcome);
