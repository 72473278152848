import React, { useEffect, useState } from "react";
import { Col, Row, Stack, Table } from "react-bootstrap";

import TestStepsHeader from "../../components/TestStepsHeader";
import Images from "../../config/images";
import TestingStationModal from "./TestingStationModal";
import { apiRequest } from "../../utils/utils";
import {
    accessTokenHeader,
    showErrorMessage,
    showSuccessMessage,
} from "../../utils/helpers";
import { baseurl } from "../../config/constants";

export default function TestingStation() {
    const [stations, setStations] = useState([]);

    const fetchStationData = async () => {
        try {
            const response = await apiRequest({
                url: `${baseurl}/testingStation/list`,
                method: "GET",
                headers: { ...accessTokenHeader() },
            });
            if (!response.success) {
                showErrorMessage(response.message);
                return;
            }
            showSuccessMessage("Successfully fetched the stations <3");
            setStations(response.data?.data?.testingStations);
        } catch (error) {
        } finally {

        }
    };
    useEffect(() => {
        fetchStationData();
    }, []);
    return (
        <Row>
            <Col md={10} className="rounded elevated">
                <Row className="mt-2 mb-2">
                    <Col md={10}>
                        <TestStepsHeader
                            icon={Images.MenuImages.SMPSTesting}
                            title={"Testing Station"}
                        />
                    </Col>
                    <Col
                        md={2}
                        className="d-flex justify-content-end align-items-center"
                        style={{
                            borderBottom: "0.2px solid black",
                            marginBottom: "15px",
                        }}
                    >
                        <TestingStationModal
                            fetchData={fetchStationData}
                            text="Register"
                        />
                    </Col>
                </Row>
                <Row className="m-0">
                    <Col
                        md={12}
                        className="customScrollbar"
                        style={{ height: "70vh", overflow: "scroll" }}
                    >
                        <Table striped bordered hover variant="light">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Device ID</th>
                                    <th>Name</th>
                                    <th>Active</th>
                                    <th>Switch Pin Tester</th>
                                    <th>Fan Module Tester</th>
                                    <th>Energisync Tester</th>
                                    <th>Is MQTT</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stations.map((station, i) => {
                                    return (
                                        <tr>
                                            <td>{i + 1}</td>
                                            <td>{station.deviceId}</td>
                                            <td>{station.name}</td>
                                            <td>
                                                {station.active
                                                    ? "True"
                                                    : "False"}
                                            </td>
                                            <td>{station.switch_pin_tester}</td>
                                            <td>{station.fan_module_tester}</td>
                                            <td>{station.energisync_tester}</td>
                                            <td>
                                                {station.isMQTT
                                                    ? "True"
                                                    : "False"}
                                            </td>
                                            <td>
                                                <Stack
                                                    gap={3}
                                                    direction="horizontal"
                                                >
                                                    <TestingStationModal
                                                        data={station}
                                                        fetchData={
                                                            fetchStationData
                                                        }
                                                        text="Update"
                                                    />
                                                </Stack>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
